import React, { useState, useEffect } from 'react';
import {audioPlayBubbleSound} from "../utils/audio";

export function useBubbleDelay(listeners, play) {
    const [bubbleVisibility, setBubbleVisibility] = useState( false );



    useEffect( () => {
        if(play === undefined) play = true;

        setBubbleVisibility( false );

        if(play) {
            setTimeout( () => {
                audioPlayBubbleSound();
                setBubbleVisibility( true );
            }, 500 )
        }

    },  listeners.concat([play]));

    return bubbleVisibility;
}

