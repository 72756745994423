import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useBubbleStepSound} from "../../../../../Hooks/useBubbleStepSound";
import {useTranslation} from "react-i18next";

export default function Dialog(props) {
    const bubbleDelayed = useBubbleDelay([props.marvin]);
    const {t, i18n} = useTranslation();
    useBubbleStepSound(props.step);

    return (
        <div className={"theory-container"}>
            <div className={"h--100 dialog"}>

                <div className="w--50 h--100 theory__table-right p--rel sm:w--100 sm:o--2 sm:theory__table-right">
                    <div className="dialog-container">
                        <img src={props.person_image} className={"dialog__img" + (props.person_small ? " s" : "")}
                             alt=""/>

                        {
                            i18n.exists(`theory_${props.module}_slide_${props.slide}_person_response`) && props.step > 0 && (
                                <div className="text-bubble-theory left">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_person_response`)}}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="w--50 p--rel sm:w--100 sm:o--1 sm:theory__table-left">
                    <div className="marvin-container">
                        <img src={props.marvin_image} className={"marvin__img"} alt=""/>

                        {
                            bubbleDelayed && (
                                <div className="text-bubble-theory right">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_marvin`)}}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
