import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useBubbleStepSound} from "../../../../../Hooks/useBubbleStepSound";
import {useTranslation} from "react-i18next";

export default function Rows2(props) {
    const bubbleDelayed = useBubbleDelay([props.marvin]);
    useBubbleStepSound(props.step);
    const {t, i18n} = useTranslation();

    return (

        <div className={"theory-container"}>

            <img src="img/bg-quiz_fun.png" className={"img--bg-full"} alt=""/>
            <div className="wrapper__content">
                <div className={"h--100 rows2"}>

                    <div className="w--50 h--100 p--rel sm:w--100 sm:o--2 mr--2">

                        <div className="row2-container">

                            <div className="row2__img-container d--flex mb--3">
                                <img src={props.pers1_image} className={"m--a"} alt=""/>
                            </div>

                            {
                                bubbleDelayed && (
                                    <div className="row2__text-container">
                                        <p className={"text--center"} dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_pers1`)}}/>
                                    </div>

                                )
                            }
                        </div>

                    </div>

                    <div className="w--50 h--100 p--rel sm:w--100 sm:o--1">

                        <div className="row2-container">

                            <div className="row2__img-container d--flex mb--3">
                                <img src={props.pers2_image} className={"m--a"} alt=""/>
                            </div>

                            {
                                props.step > 0 && (
                                    <div className="row2__text-container">
                                        <p className={"text--center"} dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_pers2`)}}/>
                                    </div>

                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}