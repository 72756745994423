import React from "react";
import Modal from "./Modal";
import {useTranslation} from "react-i18next";

export default function InsufficientCredit( props ) {
    const {t} = useTranslation() ;

    return (
        <Modal handleClose={props.handleClose}>

            <h2 className={"mb--1"}>{t( 'office_marketplace_insufficient_funds_heading' )}</h2>
            <p className={"mb--3"}>{t( 'office_marketplace_insufficient_funds_text' )}</p>

            <button
                className={"btn btn--m btn--danger"}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    props.handleClose()
                }}
            >
                {t( 'close' )}
            </button>

        </Modal>
    )


}