import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {gameModuleOpen} from "../../../../Store/actions/gameActions";
import store from "../../../../index";
import {localeList} from "../../../../i18n";

export default function Video( props ) {
    const {t, i18n} = useTranslation();

    const refVideo = useRef( null );

    const [modal, setModal] = useState( false );

    const videoEnded = e => {
        if ( refVideo.current.exitFullscreen ) {
            refVideo.current.exitFullscreen();
        } else if ( refVideo.current.mozCancelFullScreen ) { /* Firefox */
            refVideo.current.mozCancelFullScreen();
        } else if ( refVideo.current.webkitExitFullscreen ) { /* Chrome, Safari and Opera */
            refVideo.current.webkitExitFullscreen();
        } else if ( refVideo.current.msExitFullscreen ) { /* IE/Edge */
            refVideo.current.msExitFullscreen();
        }

        setModal( true );
    }

    useEffect( () => {
        if ( refVideo.current ) {
            refVideo.current.addEventListener( 'ended', videoEnded );
        }

        return () => {
            if ( refVideo.current ) {
                refVideo.current.removeEventListener( 'ended', videoEnded );
            }
        }
    }, [refVideo] );

    return (
        <>
            <div className="intro-wrapper video d--flex jc--c">
                <div className={"intro__video m--a0"}>
                    <video ref={refVideo} autoPlay={true} controls={true}>
                        <source src="/intro.mp4" type="video/mp4"/>
                        {
                            localeList.map( (locale) => (
                                <track label={locale} kind="subtitles" srcLang={locale}
                                       src={`/videos/${locale}/module_0_intro_${locale}.vtt`} default={i18n.language === locale} />
                            ) )
                        }
                        {/*<track label="English" kind="subtitles" srcLang="en" src={`/intro_en.vtt`} default/>*/}
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            {
                modal && (
                    <>
                        <div className="backdrop info"/>
                        <div className="modal-wrapper">
                            <div className="modal modal--l modal__intro blue">
                                <div className="modal__body h--100 d--flex">
                                    <div className="modal__left w--60">
                                        <div className="modal__inner">
                                            <h2 className={"mb--2 sm:mb--2"}>{t( 'gameintro_intro_heading' )}</h2>
                                            <p className={"mb--2"}>{t( 'gameintro_intro_text1' )}</p>
                                            <p className={"mb--2"}>{t( 'gameintro_intro_text2' )}</p>
                                            <p dangerouslySetInnerHTML={{__html: t( 'gameintro_intro_text3' )}}/>
                                            <button
                                                className={"btn btn--l btn--success mt--3 btn--shadow"}
                                                onClick={() => {
                                                    store.dispatch( gameModuleOpen( 0, 'intro' ) );
                                                }}
                                            >
                                                {t( 'gameintro_intro_begin' )}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="modal__right w--40 h--100">
                                        <img src="img/intro-modal_bubble1.png" className="intro__bubble" alt={""}/>
                                        <img src="img/marvin-superhero.svg" className={"modal__marvin"} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>

    )
}