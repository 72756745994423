import React, {useState, useEffect} from 'react';
import * as axios from "axios";
import {apiURL} from "../../../env";
import Loader from "../Game/Tasks/Loader";
import {connect} from "react-redux";
import {gameModulesLoad} from "../../../Store/actions/gameActions";
import {useTranslation} from "react-i18next";
import {authTeamLoaded} from "../../../Store/actions/authActions";
import {notificationsLoaded} from "../../../Store/actions/notificationsActions";

function Results(props) {
    const {t} = useTranslation();
    const [teams, setTeams] = useState(null);
    const [progress, setProgress] = useState(null);

    useEffect(() => {

        axios({
            method: 'get',
            url: apiURL + `/modules`,
        })
            .then((response) => {
                props.dispatch(gameModulesLoad(response.data));
            })
            .catch((error) => {
                console.error(error);
            });

        axios({
            method: 'get',
            url: apiURL + `/stats`,
        })
            .then((response) => {
                setTeams(response.data.teams);
                setProgress(response.data.progress);
            })
            .catch((error) => {
                console.error(error);
            });

        axios({
            method: "get",
            url: apiURL + '/modules',
        })
            .then((response) => {
                props.dispatch(gameModulesLoad(response.data.modules));
                props.dispatch(authTeamLoaded(response.data.team));
                props.dispatch(notificationsLoaded(response.data.team.notifications));
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    const modules = (new Array(6)).fill(null);


    return (
        <div className={"wrapper__content m--0a d--flex fd--c"}>
            <h1 className={"mt--3 heading--m"}>{t('results_heading')}</h1>

            {/*{*/}
            {/*    loading && (*/}
            {/*        <Loader/>*/}
            {/*    )*/}
            {/*}*/}

            {
                progress && (
                    <div>
                        <h2 className={"mb--2 mt--2"}>{t('results_progress')}</h2>
                        <div className="card-container">
                            <div className="card">

                                <div className="table-container">
                                    <table className={"table w--100"}>
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th key={-1}>{t(`level_section_intro`)}</th>
                                            {

                                                modules.map((a, i) => (
                                                    <th key={i}>{t(`game_modules_level_${i + 1}_name`)}</th>
                                                ))
                                            }
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr>
                                            <td><strong>{t('results_status')}</strong>
                                                <img src="img/racing-flag.svg" className={"ml--2 table__icon"} alt=""/>
                                            </td>
                                            <td key={-1} className={"text--center"}>
                                               -
                                            </td>
                                            {
                                                modules.map((a, i) => (
                                                    <td key={i} className={"text--center"}>
                                                        {t('level_section_status_' + props.game.moduleStatus[i + 1])}
                                                    </td>
                                                ))
                                            }

                                        </tr>
                                        <tr>
                                            <td><strong>{t('results_supporters_gained')}</strong>
                                                <img src="img/supporters.svg" className={"ml--2 table__icon"} alt=""/>
                                            </td>
                                            <td key={-1}
                                                className={"text--center"}>10</td>
                                            {
                                                modules.map((module, i) => (
                                                    <td key={i}
                                                        className={"text--center"}>{progress[i + 1]['supporters']}</td>
                                                ))
                                            }
                                        </tr>
                                        <tr>
                                            <td><strong>{t('results_coins')}</strong>
                                                <img src="img/coin.svg" className={"ml--2 table__icon"} alt=""/>
                                            </td>
                                            <td key={-1}
                                                className={"text--center"}>100</td>
                                            {
                                                modules.map((module, i) => (
                                                    <td key={i}
                                                        className={"text--center"}>{progress[i + 1]['coins']}</td>
                                                ))
                                            }
                                        </tr>
                                        <tr>
                                            <td><strong>{t('results_answers')}</strong><br/>
                                                <span
                                                    className={"text--s text--gray"}><strong>{t('quiz_modal_results_correct')}/{t('bonus')}</strong></span>
                                                <img src="img/check-outro.svg" className={"ml--2 table__icon"} alt=""/>
                                            </td>
                                            <td key={-1} className={"text--center"}>
                                                -
                                            </td>
                                            {
                                                modules.map((module, i) => (
                                                    <td key={i} className={"text--center"}>
                                                        {progress[i + 1]['quiz_success']} %
                                                        <br/>
                                                        {progress[i + 1]['quiz_bonus'] === null ? 0 : progress[i + 1]['quiz_bonus']}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                        <tr>
                                            <td><strong>{t('results_time')}</strong><br/>
                                                <span
                                                    className={"text--s text--gray"}><strong>{t('results_time_question')}</strong></span>
                                            </td>
                                            <td key={-1} className={"text--center"}>
                                                -
                                            </td>
                                            {
                                                modules.map((module, i) => (
                                                    <td key={i} className={"text--center"}>
                                                        {progress[i + 1]['quiz_duration_total']} / {progress[i + 1]['quiz_duration_avg']}
                                                    </td>
                                                ))
                                            }
                                        </tr>

                                        {
                                            // teams.map((team, i) => (
                                            //     <tr>
                                            //         <th>{team.game_name}</th>
                                            //         <td>{team.supporters}</td>
                                            //         <td>{team.coins}</td>
                                            //         <td>{team.purchased}</td>
                                            //     </tr>
                                            // ))
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                )
            }

            {
                teams && (
                    <div className={"mt--3"}>
                        <h2 className={"mb--2"}>{t('results_standings')}</h2>
                        <div className="card-container">
                            <div className="card">
                                <div className="table-container">
                                    <table className={"table table--results w--100"}>
                                        <thead>
                                        <tr>
                                            <th className={"text--left"}>{t('results_place')}</th>
                                            <th className={"text--left"}>{t('results_team')}</th>
                                            <th>{t('results_supporters')}
                                                <img src="img/supporters.svg" className={"ml--2 table__icon"} alt=""/>
                                            </th>
                                            <th>{t('results_hero')}
                                                <img src="img/coin.svg" className={"ml--2 table__icon"} alt=""/>
                                            </th>
                                            <th>{t('results_office_items')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            teams.map((team, i) => (
                                                <tr key={i}
                                                    className={team.id === props.auth.team.id ? 'table--featured' : null}>
                                                    <td>{i + 1}.</td>
                                                    <td><strong>{team.game_name}</strong>
                                                    </td>
                                                    <td className={"text--center"}>{team.supporters}</td>
                                                    <td className={"text--center"}>{team.coins}</td>
                                                    <td className={"text--center"}>{team.purchased}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


        </div>
    )
}

function mapStateToProps(state) {
    const {auth, game} = state;

    return {auth, game}
}

export default connect(
    mapStateToProps
)(Results);