import React, {useEffect, useState} from 'react';
import {sectionStatusLabel} from "../../../../utils/modules";
import {useTranslation} from "react-i18next";

export default function (props) {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);

    const sectionNames = {
        'intro': 'Intro',
        'theory': 'Theory',
        'quiz': 'Quiz',
        'scenarios': 'Scenarios',
        'case_studies': 'Case Studies',
        'tasks': 'Tasks'
    };


    return (
        <div className="modules-item card">

            {props.status === 'locked' && (
                <div className="module__disabled d--flex">
                    <img src="img/lock-white.svg" className="m--a" alt=""/>
                </div>
            )}

            {/*{props.status === 'completed' && (
                <div className="module__finished">
                    <img src="img/check-outro.svg" className="m--a" alt=""/>
                </div>
            )}*/}

            <div className="d--flex jc--sb">
                <div>
                    <h2>{t(`game_modules_level_${props.level}_name`)}</h2>
                    <p className="mt--2">{t(`game_modules_level_${props.level}_description`)}</p>
                </div>
            </div>

            <div className={"d--flex mt--3 m:fw--w"}>
                {
                    Object.keys(props.sections).map((section) => {
                        // console.log( {section, status: maxUnlockedLevel: props.maxUnlockedLevel, level: props.level} )
                        return (
                            <div
                                key={section}
                                className={"module__section d--flex ai--c "
                                + (props.status === 'locked' ? "locked" : ( props.student ?
                                        (section === 'intro' || props.sections[section][props.student.id] === null ? 'open' : ((props.sections[section][props.student.id] === "in-progress" || props.sections[section][props.student.id] === "open") && props.maxUnlockedLevel > props.level ? "incompleted" : props.sections[section][props.student.id]))
                                        :
                                        (section === 'intro' || props.sections[section] === null ? 'open' : ((props.sections[section] === "in-progress" || props.sections[section] === "open") && props.maxUnlockedLevel > props.level ? "incompleted" : props.sections[section]))
                                    )
                                )}
                                onClick={() => {
                                    if (props.student && (section === 'intro' || props.sections[section][props.student.id] !== 'locked')) props.handleOpenSection(section);
                                    if (!props.student && (section === 'intro' || props.sections[section] !== 'locked')) props.handleOpenSection(section);
                                }}
                            >


                                {
                                    (section !== 'intro'
                                        && props.student)
                                    && (
                                        props.sections[section][props.student.id] ?
                                            (
                                                <div className="module__label text--center">
                                                    {t('level_section_status_' + ((props.sections[section][props.student.id] === "in-progress" || props.sections[section][props.student.id] === "open") && props.maxUnlockedLevel > props.level ? "incompleted" : props.sections[section][props.student.id]))}
                                                </div>
                                            )
                                            : (
                                                <div className="module__label text--center">
                                                    {t('level_section_status_locked')}
                                                </div>
                                            )
                                    )
                                }


                                {
                                    (section !== 'intro'
                                    && !props.student)
                                    && (
                                        props.sections[section] ? (
                                        <div className="module__label text--center">
                                            {t('level_section_status_' + ((props.sections[section] === "in-progress" || props.sections[section] === "open") && props.maxUnlockedLevel > props.level ? "incompleted" : props.sections[section]))}
                                        </div>
                                        ) : (
                                            <div className="module__label text--center">
                                                {t('level_section_status_locked')}
                                            </div>
                                        )
                                    )
                                }
                                <span>
                                    {t('level_section_' + (section === 'theory' && props.level === 6 ? 'intro' : section))}
                                </span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}