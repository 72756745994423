import React, {useState, useEffect} from 'react';
import {gameModuleClose, gameSectionUnlockNext} from "../../../../Store/actions/gameActions";
import SectionFinishedContent from "../SectionFinished/SectionFinishedContent";
import {useTranslation} from "react-i18next";

export default function Countdown( props ) {

    const [count, setCount] = useState( 3 );

    const {t} = useTranslation();

    let interval = null;

    useEffect( () => {
        if ( interval === null ) {
            interval = setInterval( () => {
                if ( count > 0 ) {
                    setCount( count - 1 );
                } else {
                    props.handleNext();
                }

            }, 1000 );
        }

        return () => {
            if ( interval !== null ) clearInterval( interval );
        }
    });

    return (
        <div className={"d--flex fd--c ai--fs question-container"}>
            <div className="m--a text--center">
                <h1 className={"question__quiz"}>{t( 'quiz_time' )}</h1>
                <div style={{fontSize: '10rem'}}>{count}</div>
            </div>

            <img src="/img/bg-quiz.png" className={"img--bg-full"} alt=""/>
        </div>
    )
}