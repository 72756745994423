import React, {useState, useEffect} from "react";
import Marketplace from "./Marketplace/Marketplace";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {creditSet} from "../../../Store/actions/creditActions";
import {connect} from "react-redux";
import store from "../../../index";
import {flashOpen} from "../../../Store/actions/flashActions";
import {useTranslation} from "react-i18next";
import {
    authTeamBadgeClose,
    authTeamBadgeOpen, authTeamCanvasAndFilesClose, authTeamCanvasAndFilesOpen,
    authTeamDiplomaClose,
    authTeamDiplomaOpen
} from "../../../Store/actions/authActions";

import {gameModuleClose} from "../../../Store/actions/gameActions";
import Badge from "../Game/Badge";
import Diploma from "../Game/Diploma";
import CanvasModal from "./CanvasModal";
import InfoPanel from "../../InfoPanel";

function Office(props) {
    const {t} = useTranslation();

    const [loader, setLoader] = useState(true);

    const [products, setProducts] = useState({
        items: null,
        purchased: null,
        unlocked: null,
        used: null
    });

    const [diplomas, setDiplomas] = useState(null);
    const [badges, setBadges]     = useState(null);

    const [info, setInfo]     = useState(false);

    const categoriesOrder = ['wall', 'lamp', 'table', 'computer', 'flower', 'chair'];

    const debug = false;

    const applyResponseToStatus = (response) => {
        setProducts({
            items: response.data.products,
            purchased: response.data.purchased,
            unlocked: response.data.available,
            used: response.data.used,
        });

        if (response.data.diplomas) setDiplomas(response.data.diplomas);
        if (response.data.badges) setBadges(response.data.badges);

        props.dispatch(creditSet(response.data.coins));
    }

    const openBadge   = (props.team.badges && props.team.badges.length > 0) ? props.team.badges[0] : null;
    const openDiploma = (props.team.diplomas && props.team.diplomas.length > 0) ? props.team.diplomas[0] : null;
    const openCanvasAndFiles = (props.team.canvas_and_files) ? props.team.canvas_and_files : null;

    useEffect(() => {
        axios({
            method: 'get',
            url: apiURL + `/office`
        })
            .then((response) => {
                setInfo(!response.data.unlocked);
                applyResponseToStatus(response);
                setLoader(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    // if ( loader ) {
    //     return (
    //         <h1>Loader</h1>
    //     )
    // }

    console.log({openDiploma});
    console.log({diplomas, team: props.team});


    return (
        <>

            {
                openBadge !== null ? (
                    <Badge
                        {...openBadge}
                        handleClose={() => {
                            props.dispatch(authTeamBadgeClose(openBadge));
                        }}
                    />
                ) : (
                    openDiploma !== null ? (
                        <Diploma
                            {...openDiploma}
                            handleClose={() => {
                                props.dispatch(authTeamDiplomaClose(openDiploma));
                            }}
                        />
                    ) : (
                        openCanvasAndFiles !== null ? (
                            <CanvasModal
                                handleClose={() => {
                                    props.dispatch(authTeamCanvasAndFilesClose());
                                }}
                            />
                        ) : null
                    )
                )
            }

            {
                products.items && (
                    <Marketplace
                        products={products.items}
                        purchased={products.purchased}
                        handleBuy={(item) => {
                            setLoader(true);
                            axios({
                                method: 'post',
                                url: apiURL + `/office/purchase`,
                                data: {product: item.id}
                            })
                                .then((response) => {
                                    applyResponseToStatus(response);
                                    store.dispatch(flashOpen({
                                        status: 'success',
                                        text: t('office_marketplace_purchase_success')
                                    }));
                                    setLoader(false);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }}
                        unlocked={products.unlocked}
                        used={products.used}
                        handleUse={(item) => {
                            setLoader(true);
                            axios({
                                method: 'post',
                                url: apiURL + `/office/use`,
                                data: {product: item.id}
                            })
                                .then((response) => {
                                    applyResponseToStatus(response);
                                    store.dispatch(flashOpen({
                                        status: 'success',
                                        text: t('office_marketplace_use_success')
                                    }));
                                    setLoader(false);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }}
                    />
                )
            }


            <div className="img-bg-container">

                <div className={"office-container"}>

                    {
                        info && (
                            <InfoPanel text={t( 'info_office' )} style={{
                                position: 'absolute',
                                top: '60px',
                                zIndex:'10',
                                left: '70px'
                            }}/>
                        )
                    }


                    {
                         props.team.canvas_available && (
                            <button
                                className={'sbmc-download'}
                                onClick={() => {
                                    props.dispatch(authTeamCanvasAndFilesOpen());
                                }}
                            >
                                <img src="img/sbmc-download.png" alt=""/>
                            </button>
                        )
                    }

                    {
                        diplomas !== null && (
                            <div className="office-diplomas d--flex fw--w">
                                {
                                    diplomas.map((diploma, i) => (
                                        <img key={i} src={`img/office/diploma/${diploma}.png`} alt=""
                                             className={"diploma " + diploma}
                                             onClick={() => {
                                                 props.dispatch(authTeamDiplomaOpen({type: diploma, close: true}));
                                             }}
                                        />
                                    ))
                                }
                            </div>
                        )
                    }


                    {
                        badges !== null && (
                            <div className="office-badges d--flex fw--w">
                                {
                                    badges.filter((badge) => (badge > 0)).map((badge, i) => (
                                        <img key={i} src={`img/office/badges/InnoSchool-badge-${badge}.png`} alt=""
                                             className={"badge " + badge}
                                             onClick={() => {
                                                 props.dispatch(authTeamBadgeOpen({type: badge, close: true}));
                                             }}
                                        />
                                    ))
                                }
                            </div>
                        )
                    }


                    {
                        products.items && products.used && products.items
                            .filter((p, i) => products.used.indexOf(p.id) > -1)
                            .sort((a, b) => (categoriesOrder.indexOf(a.category) > categoriesOrder.indexOf(b.category) ? 1 : -1))
                            .map((p, i) => (
                                <img key={i} src={"/img/office/" + p.bg_image}
                                     className={p.category === 'wall' ? "office--img wall" : "office--img-absolute"}
                                     category={p.category}/>
                            ))
                    }
                </div>

            </div>
        </>
    )
}

function mapStateToProps(state) {
    const {game, auth} = state;


    return {game, team: auth.team}
}

export default connect(
    mapStateToProps
)(Office);