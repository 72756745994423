import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useBubbleStepSound} from "../../../../../Hooks/useBubbleStepSound";
import {useTranslation} from "react-i18next";

export default function DialogTable(props) {
    const bubbleDelayed = useBubbleDelay([props.marvin]);
    useBubbleStepSound(props.step);
    const {t} = useTranslation();

    return (
        <div className={"theory-container"}>
            <div className={"h--100 marvin-dialog-table"}>

                <div
                    className="w--33 h--100 p--rel theory__table-right sm:w--100 sm:o--3 d--flex jc--fe mr--3 sm:mr--0 sm:theory__table-right">
                    <div className="dialog-container">
                        <img src={props.person_image} className={"dialog__img"} alt=""/>

                        {
                            props.step > 0 && (
                                <div className="text-bubble-theory right">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_person_response`)}}/>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>

                <div className="w--33 p--rel sm:w--100 sm:o--2 sm:theory__table-fixed">
                    <div className="theory__table d--flex">
                        <div className="w--100 table__top d--flex jc--fe">
                            <img src="img/innoschool-logo.png" alt=""/>
                        </div>

                        <img src={props.table_image} className={"table__img"} alt=""/>

                        <div className="w--100 table__bottom">
                            <p>{t( 'theory_table_disclaimer' )}</p>
                        </div>
                    </div>
                </div>

                <div className="w--33 p--rel sm:w--100 sm:o--1 d--flex jc--fs ml--3 sm:ml--0 sm:theory__table-left">
                    <div className="marvin-container">
                        <img src={props.marvin_image} className={"marvin__img"} alt=""/>
                        {
                            bubbleDelayed && (
                                <div className="text-bubble-theory left">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_marvin`)}}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}