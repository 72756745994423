import React from 'react';
import {useTranslation} from "react-i18next";

export default function QuizIntro(props) {

    const {t} = useTranslation();

    return (
        <>
            <div className="backdrop info"></div>
            <div className="modal-wrapper">
                <div className="modal modal--l modal__intro">

                    <div className="modal__body d--flex">
                        <div className="modal__left bg--featured w--60 h--100">
                            <div className="modal__inner h--100">
                                <h2 className="mb--3 sm:mb--2">{t( 'quizff_modal_heading' )}</h2>
                                {props.answered ? (
                                    <p className="mb--1">This quiz for fun has been already finished by your teammate.<br/>You can see answers.</p>
                                ) : (
                                    <>
                                    <p className="mb--1">{t( 'quizff_modal_text1' )}</p>
                                    <p className="mb--1">{t( 'quizff_modal_text2' )}</p>
                                    <p className="mb--1">{t( 'quizff_modal_text3' )}</p>
                                    <p className="mb--1">{t( 'quizff_modal_text4' )}</p>
                                    </>
                                )}


                                <button className="btn btn--m btn--white btn--shadow mt--3 sm:mt--2"
                                        onClick={() => props.handleClick()}>{t( 'quizff_modal_btn_gotit' )}
                                </button>
                            </div>
                        </div>

                        <div className="modal__right w--40 h--100">
                            <div className="modal__inner d--flex h--100">
                                <img src="img/marvin-asking.svg" className="h--100 w--100 m--a" alt=""/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}