import React, {useState, useCallback, useEffect} from 'react';
import QuizCloseButton from "../../QuizCloseButton";
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import WordsTop from "./WordsTop";
import WordPlaceholder from "./WordPlaceholder";
import {useInterval} from "../../../../../../Hooks/useInterval";
import {useTranslation} from "react-i18next";

function ConnectQuestion( props ) {
    const [connected, setConnected] = useState(
        props.answers ? props.right.map( ( r ) => ( props.answers.left[ props.answers.right.indexOf( r.id ) ] ) ) : ( new Array( props.left.length ) ).fill( null )
    );
    const [left, setLeft] = useState( props.left.filter( ( l ) => connected.indexOf( l ) === -1 ) );
    const [right, setRight] = useState( props.right );

    const [opened, setOpened] = useState( new Array( props.left.length ).fill( false ) );

    const [duration, setDuration] = useState( props.time );

    useEffect( () => {
        const tempConnected = props.answers ? props.right.map( ( r ) => ( props.answers.left[ props.answers.right.indexOf( r.id ) ] ) ) : ( new Array( props.left.length ) ).fill( null );

        setConnected( tempConnected );
        setLeft( props.left.filter( ( l ) => tempConnected.indexOf( l ) === -1 ) );
        setRight( props.right );
        setDuration( props.time );
        setOpened( new Array( props.left.length ).fill( false ) );
    }, [props.left, props.right, props.answers, props.time] )

    useInterval( () => {
        setDuration( duration + 1 );
    }, 1000 );


    const handleTopDrop = useCallback(
        ( item ) => {
            if ( item.type === 'word-connected' ) {
                setConnected( connected.map( ( c, j ) => ( item.index === j ? null : c ) ) );
                setLeft( left.concat( item.word ) );
            }
        },
        [left, connected]
    );


    const handleDrop = useCallback(
        ( index, item ) => {

            if ( item.type === 'word' ) {
                let leftTmp = left;
                if ( connected[ index ] !== null ) leftTmp = leftTmp.concat( connected[ index ] );
                setLeft( leftTmp.filter( ( l ) => l !== item.word ) );

                setConnected( connected.map( ( c, j ) => {
                    if ( index === j ) {
                        return item.word;
                    }
                    return c;
                } ) );
            } else if ( item.type === 'word-connected' ) {

                if ( connected[ index ] !== null ) setLeft( left.concat( connected[ index ] ) );

                setConnected( connected.map( ( c, j ) => {
                    if ( item.index === j && index !== item.index ) {
                        return null;
                    } else if ( index === j ) {
                        return item.word;
                    }
                    return c;
                } ) );
            }
        },
        [left, connected]
    );

    const timeRemaining = Math.max(0, props.maxTime - duration);
    const timeProgress = Math.round( ( timeRemaining / props.maxTime ) * 100 );

    const {t} = useTranslation();

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                className={"d--iflex fd--c ai--fs question-container" + ( props.className ? ( ' ' + props.className ) : '' ) + ( props.background ? ( ' bg-' + props.background ) : ' bg-default' )}>

                <QuizCloseButton handeClick={() => props.handleClose()}/>

                <div className="question-header-container mb--3 sm:mb--1 w--100">
                    <div className="d--flex jc--sb mb--3 ai--c sm:fw--w sm:mb--1">
                        <h1 className={"question__quiz"}>{t( 'quiz_time_heading' )}</h1>
                    </div>

                    <h2 className={"question__heading d--iblock mb--2"}>{props.question}</h2>
                    <span className={"question__subheading mt--2 d--block"}>{props.text}</span>
                </div>

                <div className="d--flex fd--c connect-item-container ai--fs w--100">
                    <WordsTop
                        words={left}
                        handleWordClick={( word, i ) => {
                            setLeft( left.filter( ( l, j ) => j !== i ) );

                            let filled = false;
                            setConnected( connected.map( ( c, j ) => {
                                if ( c === null && !filled ) {
                                    filled = true;
                                    return word
                                }
                                return c;
                            } ) );
                        }}
                        onDrop={( item ) => handleTopDrop( item )}
                    />

                    <div className="connect-item__bottom d--flex fd--c jc--fs mt--3 sm:mt--2">
                        <div className="connect-item__inner">
                            {
                                right.map( ( e, i ) => {
                                    return (
                                        <div key={i} className="connect-item__row d--flex ai--fs mb--2 sm:mb--1">
                                            <WordPlaceholder
                                                index={i}
                                                word={connected[ i ]}
                                                onDrop={( item ) => handleDrop( i, item )}
                                                handleWordClick={( word, i ) => {
                                                    setLeft( left.concat( word ) );
                                                    setConnected( connected.map( ( c, j ) => ( j === i ? null : c ) ) );
                                                }}
                                            />

                                            <div className="connect-item__column right">
                                                <div
                                                    className={"connect__item" + ( opened[ i ] ? '' : ' shrinked' )}>
                                                    <span>{e.text}</span>
                                                    {
                                                        e.text.length > 100 && (
                                                            opened[ i ] ? (
                                                                <img
                                                                    src="/img/chevron-down.svg"
                                                                    className={"connect__item-more"}
                                                                    style={{transform: 'rotate(180deg)'}}
                                                                    onClick={() => {
                                                                        setOpened( opened.map( ( o, j ) => ( i === j ? false : o ) ) );
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/img/chevron-down.svg"
                                                                    className={"connect__item-more"}
                                                                    onClick={() => {
                                                                        setOpened( opened.map( ( o, j ) => ( i === j ? true : o ) ) );
                                                                    }}
                                                                />
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } )
                            }
                        </div>
                    </div>
                </div>

                <div className="btn-container quiz d--flex jc--fe sm:jc--fs">
                    {
                        props.invalid && (
                            <div className={"invalid--answer"}>
                                <span className="mdi mdi-alert mr--1"></span>
                                {t( 'quiz_invalid_answer' )}
                            </div>
                        )
                    }
                    <button className={"btn btn--danger btn--m mr--2 btn--shadow"} onClick={( e ) => {
                        props.handlePrev({
                            duration
                        })
                    }}>
                        {t( 'back' )}
                    </button>
                    <button className={"btn btn--success btn--m btn--shadow"} onClick={( e ) => {
                        props.handleNext( {
                            left:     connected,
                            right:    connected.map( ( c, i ) => right[ i ].id ),
                            duration
                        } );
                    }}>
                        {t( 'next' )}
                    </button>
                </div>

                {
                    props.maxTime && (
                        <div className="quiz-slider">
                            <div className="slider__top slider__time text--center d--flex">
                                <div
                                    className="time__container m--a">{timeRemaining}<br/><span>{t( 'quiz_second_short' )}</span>
                                </div>
                            </div>
                            <div className="slider__center">

                                <div
                                    className={"slider__progress vertical " + ( timeRemaining > 10 ? 'success' : 'danger' )}>
                                    <div
                                        className="progress__fill"
                                        style={{
                                            height: `${timeProgress}%`
                                        }}/>
                                </div>

                                {
                                    timeProgress > 0 && (
                                        <div
                                            className="slider__points d--flex"
                                            style={{
                                                top: `${100 - timeProgress}%`
                                            }}
                                        >
                                            <span className={"mr--1"}>{props.bonus}</span>
                                            <img src="img/coin.svg" alt=""/>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="slider__bottom slider__time text--center d--flex">
                                <div className="time__container m--a">0<br/><span>{t( 'quiz_second_short' )}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </DndProvider>
    )
}

export default ConnectQuestion;