import React, {useState} from 'react';
import {connect} from "react-redux";
import * as axios from "axios";
import {apiURL} from "../../../env";
import store from "../../../index";
import {authTeamLoaded} from "../../../Store/actions/authActions";
import {flashOpen} from "../../../Store/actions/flashActions";
import {useTranslation} from "react-i18next";

function Email(props) {
    const {t} = useTranslation();

    const [email, setEmail] = useState(props.auth.student.email);
    const [error, setError] = useState(null);

    return (
        <div className="settings-item card">
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    axios.post(apiURL + '/settings/email', {email})
                        .then((response) => {
                            console.log(response);
                            setError(null);
                            // TODO - notifications: false parameter added 10.09. to fix #709 - 7) by Marcel
                            store.dispatch(authTeamLoaded(response.data.team));
                            props.dispatch(flashOpen({status: 'success', text: t('settings_email_flash_success')}));
                        })
                        .catch((error) => {
                            setError(null);
                            if (error.response.status === 422) {
                                setError(error.response.data);
                            } else {
                                props.dispatch(flashOpen({status: 'danger', text: t('settings_flash_fail')}));
                            }
                        })
                }}
            >
                <h2 className={"mb--3"}>{t('settings_email')}</h2>

                <div className="input-container mb--3">
                    <input
                        className="input__item m w--100"
                        placeholder={"Email"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                {
                    error !== null && (
                        <div className="alert alert--danger">
                            {error.message}
                            <ul>
                                {Object.keys(error.errors).map((input, i) => (
                                    <li key={i}>{error.errors[input]}</li>
                                ))}
                            </ul>
                        </div>
                    )
                }

                <button type="submit"
                        className={"btn btn--m btn--secondary w--100 mt--3"}>{t('settings_email_btn_submit')}</button>
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    const {auth} = state;

    return {auth}
}

export default connect(
    mapStateToProps
)(Email);