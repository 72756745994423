import React from 'react';
import {useTranslation} from "react-i18next";

export default function FullScreenModal(props) {
    const {t} = useTranslation();


    return (
        <div className="modal-wrapper">
            <div className="modal modal--l">

                <div className={"modal__body"}>

                    <div className="modal__inner">
                        <h2 className={"mb--3"}>{t('full_screen')}</h2>


                        <h3 className={"mb--1 mt--2"}>{t('full_screen_chrome_heading')}</h3>
                        <p>{t('full_screen_chrome_text')}</p>

                        <h3 className={"mb--1 mt--2"}>{t('full_screen_firefox_heading')}</h3>
                        <p>{t('full_screen_firefox_text')}</p>

                        <h3 className={"mb--1 mt--2"}>{t('full_screen_edge_heading')}</h3>
                        <p>{t('full_screen_edge_text')}</p>

                        <h3 className={"mb--1 mt--2"}>{t('full_screen_ie_heading')}</h3>
                        <p>{t('full_screen_ie_text')}</p>


                        <button
                            className={"btn btn--m btn--secondary mt--3"}
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            {t('close')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}