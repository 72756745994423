import React, {useState} from 'react';
import Dropzone from "./Dropzone";
import * as axios from "axios";
import {flashOpen} from "../../Store/actions/flashActions";
import store from "../../index";
import {useTranslation} from "react-i18next";

export default function Upload( props ) {
    const {t} = useTranslation();

    // const [files, setFiles] = useState( props.files ? props.files : [] );

    return (
        <div className={"mb--2"}>
            <div>{props.label}</div>
            <Dropzone
                url={props.url}
                handleFilesAdd={( response ) => {
                    props.handleUpdatedTimestamp(response.updated);
                    props.setFiles( props.files.concat( response.files ) );
                    response.files.forEach((file) => {
                        //todo preklad
                        store.dispatch(flashOpen({status: 'success', text: t('task-file-uploaded-flash', { file: file.file })}));
                    })
                    // props.handleFilesChange(newFiles);
                }}
            />

            {
                props.files.length > 0 && (
                    <div className={"card-container"}>
                        <div className="card p--0">
                            <table className={"table w--100"}>
                                <tbody>
                                {
                                    props.files.map( ( file, i ) => (
                                        <tr key={i}>
                                            <td>
                                                {file.file}
                                            </td>

                                            <td className={'text--right'}>
                                                <button className={'btn btn--0 btn--text danger'}
                                                    onClick={() => {
                                                        axios.delete( props.url + '/' + file.id )
                                                            .then( () => {
                                                                props.setFiles( props.files.filter( ( f, i ) => ( file.id !== f.id ) ) );
                                                                //todo preklad
                                                                store.dispatch(flashOpen({status: 'danger', text: t('task-file-deleted-flash', { file: file.file })}));
                                                            } )
                                                    }}
                                                >
                                                    <span className="mdi mdi-close"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    ) )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        </div>
    )
}