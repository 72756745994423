import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useBubbleStepSound} from "../../../../../Hooks/useBubbleStepSound";
import {useTranslation} from "react-i18next";

export default function Sbmc(props) {
    const {t, i18n} = useTranslation();
    const bubbleDelayed = useBubbleDelay([props.marvin]);

    const cells = [
        {
            id:    'resources',
            img:   '/img/bmc/link.svg'
        },
        {
            id:    'stakeholders',
            img:   '/img/bmc/stakeholders.svg'
        },
        {
            id:    'activities',
            img:   '/img/bmc/key.svg'
        },
        {
            id:    'intervention',
            img:   '/img/bmc/intervention.svg'
        },
        {
            id:    'channels',
            img:   '/img/bmc/delivery-truck.svg'
        },
        {
            id:    'segments',
            img:   '/img/bmc/smile.svg',
        },
        {
            id:    'propositions',
            img:   '/img/bmc/propositions.svg',
        },
        {
            id:    'cost',
            img:   '/img/bmc/discount.svg'
        },
        {
            id:    'surplus',
            img:   '/img/bmc/money-bag.svg'
        },
        {
            id:    'revenue',
            img:   '/img/bmc/money.svg'
        }
    ];

    console.log(props.active);

    return (

        <div className={"theory-container"}>

            <div className={"marvin-bmc h--100"}>
                <div className="w--100 h--100 p--rel d--flex jc--c sm:o--1">
                    <div className="marvin-container">
                        <img src={props.marvin_image} className={"marvin__img"} alt=""/>
                        {
                            bubbleDelayed && (
                                <div className="text-bubble-theory left">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_marvin`)}}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="sbmc-container">

                {
                    cells.map( ( cell, i ) => (
                        <div key={i} className={"sbmc__item d--flex " + cell.id + (cell.id == props.active ? ' active' : '')} >
                            <div className={"sbmc__name"}><h2>{t('theory_sbmc_' + cell.id)}</h2></div>
                            <div className={"sbmc__icon m--a"}>
                                <img src={cell.img} alt=""/>
                            </div>


                            {
                                i18n.exists(`theory_sbmc_${cell.id}_text`) && (
                                    <div className="sbmc__desc" dangerouslySetInnerHTML={{__html: t(`theory_sbmc_${cell.id}_text`)}}/>
                                )
                            }

                        </div>
                    ) )
                }


            </div>

        </div>
    )
}