import React, {useState, cloneElement, useEffect} from 'react';

import {
    gameModuleClose,
    gameModulesLoad,
    gameSectionNext,
    gameSectionUnlockNext
} from "../../../../Store/actions/gameActions";
import {connect} from "react-redux";

import slides from './data';
import Img from "./Slides/Img";
import Book from "./Slides/Book";
import BookEmpty from "./Slides/BookEmpty";
import Marvin from "./Slides/Marvin";
import Dialog from "./Slides/Dialog";
import Rows2 from "./Slides/2rows";
import Swot from "./Slides/Swot";
import BMC from "./Slides/Bmc";
import SBMC from "./Slides/Sbmc";
import MarvinTable from "./Slides/MarvinTable";
import MarvinTableColumns from "./Slides/MarvinTableColumns";
import Imgleft from "./Slides/imgLeft";
import Imgright from "./Slides/imgRight";

import DialogTable from "./Slides/DialogTable";

import SectionFinished from "../SectionFinished/SectionFinished";
import MultipleChoiceQuestion from "../Quiz/Questions/MultipleChoice/MultipleChoiceQuestion";
import FillQuestion from "../Quiz/Questions/Fill/FillQuestion";

import QuizIntro from "../Quiz/QuizIntro";
import AnswerExplanationModal from "./AnswerExplanationModal";
import ScreensNavigation from "../ScreensNavigation";
import {useKeysNavigation} from "../../../../Hooks/useKeysNavigation";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import {creditSet} from "../../../../Store/actions/creditActions";
import Loader from "../Tasks/Loader";
import TheorySources from "./TheorySources";

function Theory( props ) {
    const [slide, setSlide] = useState( 0 );
    const [questions, setQuestions] = useState( null );
    const [answers, setAnswers] = useState( null );
    const [questionsLoading, setQuestionsLoading] = useState( false );
    const [step, setStep] = useState( 0 );
    const [quizForFunIntro, setQuizForFunIntro] = useState( true );
    const [answerExplanationModal, setAnswerExplanationModal] = useState( null );


    const slideTypes = {
        book:                    <Book/>,
        book_empty:              <BookEmpty/>,
        img:                     <Img/>,
        marvin:                  <Marvin/>,
        dialog:                  <Dialog/>,
        dialog_without_response: <Dialog/>,
        dialog_table:            <DialogTable/>,
        rows2:                   <Rows2/>,
        swot:                    <Swot/>,
        bmc:                     <BMC/>,
        sbmc:                    <SBMC/>,
        marvin_table:            <MarvinTable/>,
        marvin_table_columns:    <MarvinTableColumns/>,
        imgLeft:                 <Imgleft/>,
        imgRight:                <Imgright/>
    };

    const slideSteps = {
        book:                    1,
        book_empty:              1,
        img:                     1,
        marvin:                  1,
        dialog:                  2,
        dialog_table:            2,
        dialog_without_response: 1,
        rows2:                   2,
        swot:                    1,
        marvin_table:            1,
        marvin_table_columns:    1
    };

    const backgrounds = [
        '',
        '/img/bg-old.png',
        '/img/bg-brick.png',
        '/img/bg-brick_green.png',
        '/img/bg-brick_green_light.png',
        '/img/bg-brick_green_light.png',
        '/img/bg-open_task.png'
    ];


    const handlePrev = () => {
        if ( step > 0 ) {
            setStep( step - 1 );
        } else {
            if ( slide > 0 ) {
                setStep( slideSteps[ slides[ props.game.module ].length && slides[ props.game.module ][ slide ].layout ] - 1 );
                setSlide( slide > 0 ? slide - 1 : null );

            }
        }
    };

    const handleNext = () => {


        if ( slides[ props.game.module ][ slide ].layout === 'question' && quizForFunIntro ) {
            setQuizForFunIntro( false );
        }

        // if (slides[props.game.module][slide].layout === 'question' && answerExplanationModal !== null) {
        //     setAnswerExplanationModal(null);
        // }

        // if (slides[props.game.module][slide].layout === 'question' && !quizForFunIntro && answerExplanationModal === null) {
        //     return;
        // }


        if ( step < slideSteps[ slides[ props.game.module ].length && slides[ props.game.module ][ slide ].layout ] - 1 ) {
            setStep( step + 1 );
        } else {
            setStep( 0 );
            setSlide( slide < slides[ props.game.module ].length ? slide + 1 : slide )
        }
    };

    useKeysNavigation( handlePrev, handleNext );

    useEffect( () => {
        setQuestionsLoading( true );

        axios( {
            method: 'get',
            url:    apiURL + `/scenario/${1}/module/${props.game.module}/theory`,
        } )
            .then( ( response ) => {
                setQuestionsLoading( false );
                setQuestions( response.data );
                setAnswers( response.data.map( ( q ) => q.answer ) );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }, [] );


    useEffect( () => {

        //when question is in data.json but not exists in variable questions
        if (slide < slides[ props.game.module ].length && slides[ props.game.module ][ slide ] && slides[ props.game.module ][ slide ].layout === 'question' && !questions[ slides[ props.game.module ][ slide ].question - 1 ]){
            setSlide( slide + 1)
        }

        if ( slide === slides[ props.game.module ].length ) {
            setQuestionsLoading( true );
            axios( {
                method: "get",
                url:    apiURL + `/module/${props.game.module}/section/theory/complete`,
            } )
                .then( ( response ) => {
                    setQuestionsLoading( false );
                    props.dispatch( gameModulesLoad( response.data.modules ) );
                } )
                .catch( ( error ) => {
                    console.error( error );
                } );
        }
    }, [slide] )


    if ( questionsLoading ) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            {
                slide < slides[ props.game.module ].length
                && slides[ props.game.module ][ slide ].layout === 'question'
                && quizForFunIntro
                && !questions[ slides[ props.game.module ][ slide ].question - 1 ]
                && (
                    <QuizIntro handleClick={() => {
                        setQuizForFunIntro( false )
                    }}/>
                )
            }

            {
                slide < slides[ props.game.module ].length
                && slides[ props.game.module ][ slide ].layout === 'question'
                && quizForFunIntro
                && questions[ slides[ props.game.module ][ slide ].question - 1 ]
                && questions[ slides[ props.game.module ][ slide ].question - 1 ].answer
                && (
                    <QuizIntro answered={true} handleClick={() => {
                        setQuizForFunIntro( false )
                    }}/>
                )
            }

            {
                answerExplanationModal && (
                    <AnswerExplanationModal
                        {...answerExplanationModal}
                        handleClose={() => {

                            setAnswerExplanationModal( null );
                            handleNext();
                            // setSlide( slide < slides[ props.game.module ].length ? slide + 1 : slide )
                        }}
                    />
                )
            }

            {
                slide < slides[ props.game.module ].length && (
                    <div>

                        {
                            slides[ props.game.module ][ slide ].layout !== 'question' && (
                                <img src={backgrounds[ props.game.module ]} className={"img--bg-full theory"} alt=""/>
                            )
                        }

                        {
                            slides[ props.game.module ][ slide ].layout === 'question' ? (
                                ( () => {
                                    const question = questions[ slides[ props.game.module ][ slide ].question - 1 ];

                                    if ( question && question.type === 'MultipleChoiceQuestion' ) {
                                        return (
                                            <MultipleChoiceQuestion
                                                {...question}
                                                answers={answers[ slides[ props.game.module ][ slide ].question - 1 ]}
                                                className="theory"
                                                background="green"
                                                closeButton={false}

                                                handlePrev={() => {
                                                    handlePrev();
                                                }}
                                                handleNext={( data ) => {
                                                        setAnswers( answers.map( ( a, i ) => {
                                                            if ( i === slides[ props.game.module ][ slide ].question - 1 ) return data;
                                                            return a;
                                                        } ) );

                                                        axios( {
                                                            method: 'post',
                                                            url:    apiURL + `/question/${question.id}/answer`,
                                                            data:   data
                                                        } )
                                                            .then( ( response ) => {

                                                                    if ( response.data.explanationModal ) {
                                                                        setAnswerExplanationModal( response.data.explanationModal );
                                                                        props.dispatch( creditSet( response.data.coins ) );
                                                                    } else {
                                                                        console.log( 'close' );
                                                                        handleNext();
                                                                    }
                                                                    console.log( 'ferko' );
                                                                    console.log( response );
                                                                }
                                                            )
                                                            .catch( ( error ) => {
                                                                console.error( error );
                                                            } );
                                                }}
                                            />
                                        )
                                    } else if ( question && question.type === 'FillQuestion' ) {
                                        return (
                                            <FillQuestion
                                                {...question}
                                                className="theory"
                                                background="green"
                                                closeButton={false}
                                                handlePrev={() => {
                                                    handlePrev();
                                                }}
                                                handleNext={() => {
                                                    handleNext();
                                                }}
                                            />
                                        )
                                    }
                                } )()
                            ) : (
                                <>
                                    {
                                        cloneElement( slideTypes[ slides[ props.game.module ][ slide ].layout ], {
                                            ...slides[ props.game.module ][ slide ],
                                            module:     props.game.module,
                                            slide:      slide + 1,
                                            step:       step,
                                            handlePrev: handlePrev,
                                            handleNext: handleNext
                                            // handleFinished: ( slide === slides[ props.game.module ].length  ) ? ( () => props.handleFinished() ) : null
                                        } )
                                    }

                                    <TheorySources sources={slides[ props.game.module ][ slide ].sources}/>

                                    <ScreensNavigation
                                        handlePrev={() => {
                                            handlePrev();
                                        }}
                                        handleNext={() => {
                                            handleNext();
                                        }}
                                    />
                                </>
                            )
                        }
                    </div>
                )
            }




            {
                slide === slides[ props.game.module ].length && (
                    <>
                        <img src={backgrounds[ props.game.module ]} className={"img--bg-full theory"} alt=""/>
                        <SectionFinished
                            handleBackToMenu={() => {
                                props.dispatch( gameModuleClose() );
                            }}
                            handleContinue={() => {
                                props.dispatch( gameSectionNext() );
                            }}
                        />
                    </>
                )
            }
        </>
    )
}

function mapStateToProps( state ) {
    const {game} = state;

    return {game}
}

export default connect(
    mapStateToProps
)( Theory );