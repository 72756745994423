import React, {useState, cloneElement, useEffect} from 'react';

import slides from './data';

import Intro from "./Intro";
import Outro from "./Outro";
import ImgRight from "./Slides/ImgRight";
import Person1 from "./Slides/Person1";
import Persons2 from "./Slides/Persons2";
import ImgLeft from "./Slides/ImgLeft";
import {connect} from "react-redux";
import SelectScenario from "./SelectScenario";
import {
    gameModuleClose,
    gameModulesLoad,
    gameSectionFinished,
    gameSectionNext
} from "../../../../Store/actions/gameActions";
import {useKeysNavigation} from "../../../../Hooks/useKeysNavigation";
import scenarios from "../ScenariosSelect/data";

import * as axios from "axios";
import {apiURL} from "../../../../env";
import {useTranslation} from "react-i18next";
import CaseStudiesSources from "./CaseStudiesSources";


function CaseStudy(props) {
    const selectedScenario                                        = props.auth.team.scenario ? scenarios[[props.auth.team.scenario - 1]].code : null;
    const [scenario, setScenario]                                 = useState(props.game.sectionStatus[props.game.module]['case_studies'] === 'completed' ? null : selectedScenario);
    const [finishedSelectedScenario, setFinishedSelectedScenario] = useState(false);
    const [slide, setSlide]                                       = useState(0);
    const [step, setStep]                                         = useState(1);

    const {t, i18n} = useTranslation();

    const slideTypes = {
        pers_1: <Person1/>,
        pers_2: <Persons2/>,
        img_right: <ImgRight/>,
        img_left: <ImgLeft/>
    };

    const slideSteps = {
        pers_1: 1,
        pers_2: 2,
        img_right: 1,
        img_left: 1
    };

    useEffect(() => {
        axios({
            method: "get",
            url: apiURL + `/module/${props.game.module}/section/case_studies/start`,
        })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handlePrev = () => {
        if (step > 1) {
            setStep(step - 1);
        } else if (slide !== 0) {
            setStep(slideSteps[slides[props.game.module][scenario].slides[slide - 1].layout]);
            setSlide(slide - 1);
        }
    };

    const handleNext = () => {
        const layout = slides[props.game.module][scenario].slides[slide].layout;

        if ((layout === 'pers_2'
            && i18n.exists(`case_studies_${scenario}_slides_${slide + 1}_pers1_text`)
            && step < slideSteps[slides[props.game.module][scenario].slides[slide].layout])
            || (layout !== 'pers_2' && step < slideSteps[slides[props.game.module][scenario].slides[slide].layout])) {
            setStep(step + 1);
        } else {
            if (finishedSelectedScenario || scenario !== selectedScenario) {
                if (slide < slides[props.game.module][scenario].slides.length - 1) {
                    setSlide(slide + 1)
                    setStep(1);
                } else {
                    setSlide(0);
                    setStep(1);
                    setScenario(null);
                }
            } else {
                if (slide < slides[props.game.module][scenario].slides.length) {
                    setSlide(slide + 1)
                    setStep(1);
                }
            }
        }
    };

    useKeysNavigation(handlePrev, handleNext);

    console.log("scneraio");

    console.log(scenario);
    if (scenario === null) {
        return (
            <SelectScenario
                selectedScenario={selectedScenario}
                handleSelect={(scenario) => setScenario(scenario)}
                handleBackToMenu={() => props.dispatch(gameModuleClose())}
            />
        );
    }


    if (slide === null) {
        return (
            <div className={"m--0a d--flex fd--c"}>
                <Intro
                    handleNext={() => {
                        if (slide < slides[props.game.module][scenario].length) {
                            setSlide(0);
                        } else {
                            setSlide(0);
                            console.log('koniec intra');
                            setScenario(null);
                        }
                    }}

                />
            </div>
        )
    }

    if (!finishedSelectedScenario && scenario === selectedScenario && slide === slides[props.game.module][scenario].slides.length) {

        return (
            <Outro
                handleClose={() => {
                    axios({
                        method: "get",
                        url: apiURL + `/module/${props.game.module}/section/case_studies/complete`,
                    })
                        .then((response) => {
                            props.dispatch(gameModulesLoad(response.data.modules));
                            setScenario(null);
                            setSlide(0);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
                handleBackToMenu={() => {
                    axios({
                        method: "get",
                        url: apiURL + `/module/${props.game.module}/section/case_studies/complete`,
                    })
                        .then((response) => {
                            props.dispatch(gameModulesLoad(response.data.modules));
                            props.dispatch(gameModuleClose());
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
                handleKeepPlaying={() => {
                    axios({
                        method: "get",
                        url: apiURL + `/module/${props.game.module}/section/case_studies/complete`,
                    })
                        .then((response) => {
                            props.dispatch(gameModulesLoad(response.data.modules));
                            props.dispatch(gameSectionNext());
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                }}
            />

        )
    }

    return (
        <>
            {
                cloneElement(slideTypes[slides[props.game.module][scenario].slides[slide].layout], {
                    backgroundColor: slides[props.game.module][scenario].background_color,
                    backgroundImage: slides[props.game.module][scenario].background_image,
                    step,
                    scenario,
                    slide,
                    ...slides[props.game.module][scenario].slides[slide]
                })
            }


            <CaseStudiesSources sources={slides[props.game.module][scenario].slides[slide].sources}/>


            {
                (step > 1 || slide > 0) && (
                    <button className="btn btn--white nb btn--prev d--flex jc--c ai--c"
                            onClick={() => {
                                handlePrev();
                            }}
                    >
                        <div className={"d--flex fd--c jc--c ai--c"}>
                            <span className="mdi mdi-arrow-left"></span>
                            <span className="text">{t('prev')}</span>
                        </div>
                    </button>
                )
            }


            <button className="btn btn--secondary nb btn--next d--flex jc--c ai--c"
                    onClick={() => {
                        handleNext();
                    }}
            >
                <div className={"d--flex fd--c jc--c ai--c"}>
                    <span className="mdi mdi-arrow-right"></span>
                    <span className="text">{t('next')}</span>
                </div>
            </button>


        </>
    )
}

function mapStateToProps(state) {
    const {game, auth} = state;

    return {game, auth}
}

export default connect(
    mapStateToProps
)(CaseStudy);