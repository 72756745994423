import {combineReducers} from "redux";

import {AuthReducer} from "./AuthReducer";
import {GameReducer} from "./GameReducer";
import {CreditReducer} from "./CreditReducer";
import {FlashReducer} from "./FlashReducer";
import {NotificationsReducer} from "./NotificationsReducer";
import {SoundReducer} from "./SoundReducer";
import {ChatReducer} from "./ChatReducer";

export default combineReducers( {
    auth:          AuthReducer,
    game:          GameReducer,
    credit:        CreditReducer,
    flashes:       FlashReducer,
    notifications: NotificationsReducer,
    sound:         SoundReducer,
    chat:          ChatReducer,
} )