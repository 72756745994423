import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/app.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import {createStore, applyMiddleware, compose} from "redux";
import thunkMiddleware from 'redux-thunk'
import reducers from "./Store/reducers";

            import {audioLoadBubbleSound} from "./utils/audio";

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunkMiddleware),
        ...(window.__REDUX_DEVTOOLS_EXTENSION__ ? [window.__REDUX_DEVTOOLS_EXTENSION__()] : [])
    )
);

export default store;

// store.dispatch( gameModuleUnlock( {module: 0} ) );
// store.dispatch( gameSectionUnlock( {module: 0, section: 'intro'} ) );

axios.interceptors.request.use( function ( config ) {
    const auth = store.getState().auth;

    if ( auth.token != null ) {
        config.headers.Authorization = `Bearer ${auth.token}`;
    }

    if (auth.provider != null){
        config.headers.AuthGroundProvider = auth.provider;
    }

    return config;
}, function ( error ) {
    return Promise.reject( error );
} );

audioLoadBubbleSound();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
