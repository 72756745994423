import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function Loader( props ) {

    const {t} = useTranslation();

    return (
        <div className="wrapper__content m--0a d--flex fd--c">
            <h1 className="mt--3 mb--3 heading--m">{t( 'loader_loading' )}</h1>
        </div>
    )
}