import React, {useRef, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import useOutsideClick from "../Hooks/useOutsideClick";
import {gameModuleClose} from "../Store/actions/gameActions";
import {connect} from "react-redux";

function Sidebar( props ) {
    const [open, setOpen] = useState(true);
    const {t} = useTranslation();


    const ref = useRef(null);

    useOutsideClick( ref, () => {
        if(!props.sticky && props.open) props.handleClose( false );
    } );

    const links = [
        {
            url:  '/',
            img:  'home.svg',
            text: t( "sidebar_home" )
        },
        {
            url:  '/office',
            img:  'office.svg',
            text: t( "sidebar_office" )
        },
        {
            url:  '/results',
            img:  'results.svg',
            text: t( "sidebar_results" )
        },
        // {
        //     url:  '/files',
        //     img:  'files.svg',
        //     text: t( "sidebar_files" )
        // },
        {
            url:  '/settings',
            img:  'settings.svg',
            text: t( "sidebar_settings" )
        }
    ];

    return (
        <div ref={ref} className={"sidebar-container" + ( props.sticky || props.open ? ' active' : '')}>

            {
                links.map( ( link, i ) => (
                    <NavLink
                        key={i}
                        exact={true}
                        to={link.url}
                        className="sidebar__item d--flex fd--c jc--c ai--c"
                        onClick={() => {
                            if(link.url === '/') props.dispatch(gameModuleClose());
                            props.handleClose( false );
                        }}
                    >
                        <img src={"img/sidebar/" + link.img} alt={link.text} className={"mb--1"}/>
                        <span>{link.text}</span>
                    </NavLink>
                ) )
            }

        </div>
    )
}


export default connect(
    () => ({})
)( Sidebar );