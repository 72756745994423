import React from 'react';

export default function icon ( props ) {
    return ( <svg xmlns="http://www.w3.org/2000/svg" width="62.066" height="103.489" viewBox="0 0 62.066 103.489">
            <path
                d="M54.024,43.225c-1.521.264-3.033.491-4.516.689l-1.322-13.8c8.868-5.67,11.5-15.622,12.2-22.437a6.639,6.639,0,0,0-2.362-5.766A8.169,8.169,0,0,0,50.635.287a87.516,87.516,0,0,1-19.52,2.761A87.51,87.51,0,0,1,11.6.287a8.159,8.159,0,0,0-7.39,1.628A6.637,6.637,0,0,0,1.838,7.674c.7,6.814,3.332,16.765,12.2,22.434L12.723,43.916c-1.483-.2-3-.428-4.516-.69a6.909,6.909,0,0,0-6.835,2.759,6.646,6.646,0,0,0-.507,7.054,20.893,20.893,0,0,0,9.311,8.4L2.493,98.232a4.364,4.364,0,0,0,8.438,2.188l6.162-19.369H45.139l6.16,19.361a4.364,4.364,0,0,0,8.438-2.2L52.062,61.432a20.889,20.889,0,0,0,9.311-8.4,6.648,6.648,0,0,0-.507-7.054A6.913,6.913,0,0,0,54.024,43.225Zm-7.932,1.108q-1.778.2-3.431.345L41.563,33.1a27.5,27.5,0,0,0,3.345-1.22ZM31.115,34.5a39.867,39.867,0,0,0,7.054-.6l1.047,11.044c-4.785.317-8.041.328-8.1.328s-3.316-.01-8.1-.328l1.047-11.044A39.8,39.8,0,0,0,31.115,34.5ZM5.269,7.32A3.223,3.223,0,0,1,6.459,4.533,4.575,4.575,0,0,1,9.441,3.46a5.152,5.152,0,0,1,1.285.172A89.253,89.253,0,0,0,31.115,6.5,89.252,89.252,0,0,0,51.5,3.632a4.733,4.733,0,0,1,4.276.909,3.218,3.218,0,0,1,1.181,2.786c-1.117,10.818-6.511,23.72-25.846,23.72a28.282,28.282,0,0,1-14.473-3.412c-.024-.012-.043-.031-.067-.043C8.788,23.025,6.031,14.695,5.269,7.32ZM17.33,31.874a27.455,27.455,0,0,0,3.345,1.22l-1.1,11.575q-1.654-.144-3.431-.345ZM7.642,99.382a.911.911,0,0,1-.873.636.894.894,0,0,1-.7-.333.914.914,0,0,1-.2-.764l7.516-36.026A53.562,53.562,0,0,0,18.67,64.72Zm32.571-33.81,1.632,5.134H20.386l1.632-5.134A43.949,43.949,0,0,0,40.213,65.572ZM18.184,77.6l1.1-3.448H42.944l1.1,3.448Zm38.175,21.3a.923.923,0,0,1-.195.777.894.894,0,0,1-.7.333.911.911,0,0,1-.875-.643L43.561,64.717a53.363,53.363,0,0,0,5.285-1.826Zm1.958-47.493c-2.274,4.3-7.869,7.8-16.175,10.139a40.7,40.7,0,0,1-22.053,0c-8.306-2.336-13.9-5.842-16.175-10.139A3.171,3.171,0,0,1,4.164,48a3.449,3.449,0,0,1,3.448-1.38,154,154,0,0,0,23.5,2.09,154.045,154.045,0,0,0,23.5-2.09A3.45,3.45,0,0,1,58.062,48,3.173,3.173,0,0,1,58.317,51.415Zm0,0"
                transform="translate(-0.086 -0.001)"/>
        </svg>
    )
}
