import React, {useState, useEffect} from 'react';
import Dashboard from "./Dashboard/Dashboard";
import ScenariosSelect from "./ScenariosSelect/ScenariosSelect";
import Theory from "./Theory/Theory";
import Quiz from "./Quiz/Quiz";
import Intro from "./Intro/Intro";
import Tasks from "./Tasks/Tasks";
import CaseStudy from "./CaseStudies/CaseStudy";
import {connect} from "react-redux";
import {gameModuleClose} from "../../../Store/actions/gameActions";
import Badge from "./Badge";
import Diploma from "./Diploma";
import {
    AUTH_TEAM_BADGE_CLOSE,
    AUTH_TEAM_DIPLOMA_CLOSE,
    authTeamBadgeClose,
    authTeamDiplomaClose, authTeamModuleUnlockedClose, authTeamOpenTaskEvaluationClose, authTeamOpenTaskStoryClose
} from "../../../Store/actions/authActions";
import OpenTaskEvaluation from "./OpenTaskEvaluation";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {creditSet} from "../../../Store/actions/creditActions";
import Faq from "./Intro/Faq";
import Video from "./Intro/Video";
import ModuleIntro from "./ModuleIntro/ModuleIntro";
import ModuleUnlocked from "./ModuleUnlocked";
import OpenTaskStory from "./OpenTaskStory";

function GameSections(props) {
    if (props.game.module === null) {
        return (
            <Dashboard handleSidebarSticky={props.handleSidebarSticky}/>
        )
    } else {
        switch (props.game.section) {
            case 'intro':

                if (props.game.module > 0) {
                    return (
                        <ModuleIntro
                            module={props.game.module}
                        />
                    );
                }
                return (
                    <Intro/>
                );

            case 'video':
                return (
                    <Video/>
                );

            case 'faq':
                return (
                    <Faq/>
                );

            case 'theory':
                return (
                    <Theory/>
                );

            case 'quiz':
                return (
                    <Quiz/>
                );

            case 'scenarios':
                return (
                    <ScenariosSelect/>
                );

            case 'tasks':
                return (
                    <Tasks/>
                );

            case 'case_studies':
                return (
                    <CaseStudy/>
                )

        }
    }
}


function Game(props) {

    useEffect(() => {
        // props.dispatch( gameModuleClose() );
    }, [props.time])

    const module_unlocked_notification = (props.team.module_unlocked_notifications && props.team.module_unlocked_notifications.length > 0) ? props.team.module_unlocked_notifications[0] : null;
    const open_task_evaluation         = (props.team.open_task_evaluations && props.team.open_task_evaluations.length > 0) ? props.team.open_task_evaluations[0] : null;
    const badge                        = (props.team.badges && props.team.badges.length > 0) ? props.team.badges[0] : null;
    const diploma                      = (props.team.diplomas && props.team.diplomas.length > 0) ? props.team.diplomas[0] : null;
    const open_task_story              = props.team.open_task_story ? props.team.open_task_story : null;


    return (
        <>
            <GameSections
                {...props}
            />

            {
                open_task_story !== null ? (
                    <OpenTaskStory
                        {...open_task_story}
                        handleClose={() => {
                            props.dispatch(authTeamOpenTaskStoryClose(open_task_story));
                        }}
                    />
                ) : (
                    module_unlocked_notification !== null ? (
                        <ModuleUnlocked
                            {...module_unlocked_notification}
                            handleClose={() => {
                                props.dispatch(authTeamModuleUnlockedClose(module_unlocked_notification));
                            }}
                        />
                    ) : (
                        open_task_evaluation !== null ? (
                            <OpenTaskEvaluation
                                {...open_task_evaluation}
                                handleShare={() => {
                                    window.FB.ui({
                                        method: 'share',
                                        href: open_task_evaluation.share_facebook_url
                                    }, (response) => {
                                        console.log(response)
                                    });
                                }}
                                handleClose={() => {
                                    props.dispatch(authTeamOpenTaskEvaluationClose(open_task_evaluation));
                                }}
                            />
                        ) : (
                            badge !== null ? (
                                <Badge
                                    {...badge}
                                    handleShare={() => {
                                        window.FB.ui({
                                            method: 'share',
                                            href: badge.share_facebook_url
                                        }, (response) => {
                                            console.log(response)
                                        });
                                    }}
                                    handleClose={() => {
                                        props.dispatch(authTeamBadgeClose(badge));
                                        props.dispatch(gameModuleClose());
                                    }}
                                />
                            ) : (
                                diploma !== null ? (
                                    <Diploma
                                        {...diploma}
                                        handleShare={() => {
                                            window.FB.ui({
                                                method: 'share',
                                                href: diploma.share_facebook_url
                                            }, (response) => {
                                                console.log(response)
                                            });
                                        }}
                                        handleClose={() => {
                                            props.dispatch(authTeamDiplomaClose(diploma));
                                        }}
                                    />
                                ) : null
                            )
                            // )
                        )
                    )
                )

            }
        </>
    )
}

function mapStateToProps(state) {
    const {game, auth} = state;


    return {game, team: auth.team}
}

export default connect(
    mapStateToProps
)(Game);