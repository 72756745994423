import React, {useEffect} from 'react';
import {authLogout} from "../Store/actions/authActions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import NavbarDropdown from "./NavbarDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import {soundToggle} from "../Store/actions/soundActions";
import Chat from "./Screens/Chat/Chat";
import * as axios from "axios";
import {apiURL} from "../env";
import {unseenMessagesCountLoaded} from "../Store/actions/chatActions";

function Navbar(props) {
    const {t, i18n} = useTranslation();

    const loadUnseenMessagesCount = () => {
        axios( {
            method: "get",
            url:    apiURL + '/chat/unseen-messages',
        } )
            .then( ( response ) => {
                props.dispatch( unseenMessagesCountLoaded( response.data.unseen ) );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    };

    useEffect( () => {
        loadUnseenMessagesCount();

        const refresh = setInterval( () => {
            loadUnseenMessagesCount();
        }, 10000 );

        return ( () => {
            clearInterval( refresh );
        } )
    }, [] );

    return (
        <div className="header d--flex">
            <div className="navbar d--flex jc--sb m--0a">
                <div className="nav-left d--flex ai--c">
                    <div className={"nav-toggle mr--2 sm:mr--1"}>
                        <button className={"button-clear"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.handleSidebar(true)
                                }}
                        >
                            <img src="img/menu-toggle.svg" alt=""/>
                        </button>

                    </div>

                    <div className={"nav-info mr--1 sm:d--none"}>
                        <div className="nav-info__inner nav--supporters d--flex">
                            <div className="nav-info__left d--flex ai--c">
                                <img src="img/navbar/supporters.svg" alt=""/>
                            </div>

                            <div className="nav-info__right d--flex ai--c">
                                <span className={"nav-info__right-inner"}>{props.credit.supporters}</span>
                            </div>
                        </div>
                    </div>

                    <div className={"nav-info mr--1 sm:d--none"}>
                        <div className="nav-info__inner d--flex">
                            <div className="nav-info__left d--flex ai--c">
                                <img src="img/navbar/coin.svg" alt=""/>
                            </div>

                            <div className="nav-info__right d--flex ai--c">

                                <span className={"nav-info__right-inner"}>{props.credit.credit}</span>

                                {/*<button className={"nav-info__addcoins button-clear ml--1"}>*/}
                                {/*<img src="img/add-coin.svg" alt=""/>*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    </div>

                    <div className={"nav-info mr--1 sm:d--none"}>
                        <div className="nav-info__inner d--flex">
                            <div className="nav-info__left d--flex ai--c">
                                <img src="img/navbar/badge.png" alt=""/>
                            </div>

                            <div className="nav-info__right d--flex ai--c">
                                <span
                                    className={"nav-info__right-inner"}>{props.credit.badges_count}&nbsp;/&nbsp;{props.auth.team.is_advanced ? 6 : 5}</span>
                            </div>
                        </div>
                    </div>

                    <div className={"nav-info mr--1 sm:d--none"}>
                        <div className="nav-info__inner d--flex">
                            <div className="nav-info__left d--flex ai--c">
                                <img src="img/navbar/finnish.png" alt=""/>
                            </div>

                            <div className="nav-info__right d--flex ai--c">
                                <span className={"nav-info__right-inner progress"}>
                                    <span className="nav-info__progress" style={{
                                        width: Math.round(props.credit.progress),
                                        marginRight: (100 - Math.round(props.credit.progress))
                                    }}/>
                                </span>
                            </div>

                            <div className="nav-info__right-right d--flex ai--c">
                                <span>{props.credit.progress}%</span>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="nav-menu d--flex jc--fe">

                    <div className="menu__item d--flex">
                        <a
                            href="#"
                            className={"d--flex ai--c"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.dispatch(soundToggle(!props.sound));
                            }}
                        >
                            {
                                props.sound ? (
                                    <img src="img/speaker-on.svg" alt=""/>
                                ) : (
                                    <img src="img/speaker-off.svg" alt=""/>
                                )
                            }

                        </a>
                    </div>

                    <div className="menu__item d--flex">
                        <a
                            href="#"
                            className={"d--flex ai--c"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.handleFullScreenModal();
                            }}
                        >
                            <img src="img/fullscreen.svg" alt=""/>
                        </a>
                    </div>


                    <NotificationsDropdown/>

                    <div className="menu__item d--flex">

                                <a
                                    href="/chat"
                                    className={"d--flex ai--c" + (props.chat && props.chat.unseen ? " flashing" : "")}
                                >
                                    <img src="img/chat.svg" alt=""/>
                                </a>


                    </div>

                    <NavbarDropdown/>

                    <div className="nav-logo h--100">
                        <img src="img/innoschool-logo_bw.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    const {auth, credit, sound, chat} = state;

    return {
        credit,
        auth,
        sound,
        chat
    }
}

export default connect(
    mapStateToProps
)(Navbar);