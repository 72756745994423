import React, {useState} from 'react';
import Form from "../../Form/Form";
import {apiURL} from "../../../env";
import {useTranslation} from "react-i18next";
import LinkToLogin from "../LinkToLogin";

export default function TeamCodeForm({changeState}) {
    const {t, i18n} = useTranslation();

    return (
        <div className={"wrapper__login m--0a d--flex jc--c"}>
            <div className="wrapper__login-bg"></div>
            <div className="w--40 sm:w--100 as--c">
                <h1 className={"heading--m text--center mb--3"}>{t('auth_reset_password_heading')}</h1>
                <div className={"login-container"}>
                    <Form
                        locale={i18n.language}
                        submitLabel={t('auth_reset_password_btn')}
                        submitMethod={"post"}
                        submitUrl={apiURL + '/password-reset'}
                        handleSubmit={() => {

                        }}
                        handleSubmitSuccess={(data, response) => {
                            changeState('loading');
                            if (parseInt(response.data.email) === 1) {
                                changeState('email_success');
                            } else {
                                changeState('teacher_success');
                            }
                            console.log(data, response);
                        }}
                        handleSubmitError={(response) => {
                            console.error(response);

                        }}
                        inputs={[
                            <input className={"input__item mb--2 w--100"}
                                   placeholder={t('team_code_email')} name="code" type="text"/>,
                        ]}
                    />
                    <div className="d--flex fd--c mt--3">
                        <button
                            className={"btn btn--text btn--0s mb--1"}
                            onClick={() => changeState('team_secret_form')}
                        >
                            <strong className={"text--underline"}>{t('auth_reset_code')}</strong>
                        </button>

                        <LinkToLogin/>
                    </div>
                </div>
            </div>
        </div>
    )
}