import React from 'react';
import {useTranslation} from "react-i18next";

export default function Intro( props ) {

    const {t} = useTranslation();
    return (
        <div className="wrapper__content m--0a d--flex fd--c">
            <h1 className="mt--3 mb--3 heading--m">Intro tasks</h1>
            <div className="btn-container d--flex jc--fs">
                <button className={"btn btn--success btn--m btn--shadow"} onClick={( e ) => {
                    props.handleClose()
                }}>
                    {t( 'next' )}
                </button>
            </div>
        </div>
    )
}