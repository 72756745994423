import React from 'react';
import {useTranslation} from "react-i18next";

export default function Outro(props) {

    const {t} = useTranslation();

    return (
        <>
            <div className="backdrop info"/>
            <div className="modal-wrapper">
                <div className="modal modal--l modal__intro">

                    <div className="modal__body d--flex">
                        <div className="modal__left bg--featured w--60 h--100">
                            <div className="modal__inner h--100">
                                <h2 className="mb--3 sm:mb--2">{t( 'gameoutro_scenarios_heading' )}</h2>
                                <p className="mb--1">
                                    {t( 'gameoutro_scenarios_text' )}
                                </p>

                                <button
                                    className="btn btn--m btn--primary nb btn--shadow w--100 mt--3 sm:mt--2"
                                    onClick={() => props.handleClose()}
                                >
                                    {t( 'other_cs' )}
                                </button>

                                <div className="d--flex sm:fw--w mt--2 sm:mt--1">

                                    <button
                                        className="btn btn--m btn--white nb btn--shadow w--50 mr--1 sm:w--100"
                                        onClick={() => props.handleKeepPlaying()}
                                    >
                                        {t( 'keep_playing' )}
                                    </button>

                                    <button
                                        className="btn btn--m btn--text  w--50 sm:w--100"
                                        onClick={() => props.handleBackToMenu()}
                                    >
                                        {t( 'back_to_menu' )}
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div className="modal__right w--40 h--100">
                            <div className="modal__inner d--flex h--100">
                                <img src="img/marvin-asking.svg" className="h--100 m--a m:h--a" alt=""/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}