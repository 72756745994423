import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {gameModulesLoad} from "../../../Store/actions/gameActions";
import {authTeamLoaded} from "../../../Store/actions/authActions";
import {notificationsLoaded} from "../../../Store/actions/notificationsActions";
import {set} from "immutable";

function Channels( {auth, selected, setSelected} ) {
    const {t} = useTranslation();

    const [channels, setChannels] = useState( [] );

    const loadChannels = () => {
        axios( {
            method: 'get',
            url:    apiURL + `/chat/channels`,
        } )
            .then( ( response ) => {
                if ( response.data ) {
                    let filteredChannels = response.data.channels;

                    if ( !auth.student ) {
                        filteredChannels = response.data.channels.filter( ( item ) => item.channel.teacher_chat !== 0 );
                    }

                    setChannels( filteredChannels );

                    setSelected( (selected) => {
                        if ( selected === null || response.data.channels.map( ( ch ) => ch.channel.id ).indexOf( selected ) < 0 ) {
                            return filteredChannels[ 0 ].channel.id;
                        }
                        return selected
                    })
                }
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }

    useEffect( () => {
        loadChannels();

        const refresh = setInterval( loadChannels, 10000 );

        return ( () => {
            clearInterval( refresh );
        } )
    }, [selected] );

    return (
        <>
            {channels.map( ( item ) => {
                if ( auth.student ) {
                    return <div
                        className={"chat__channel" + ( ( selected === item.channel.id ) ? " active" : "" ) + ( item.unseen ? ' flashing-channel' : '' )}
                        key={item.channel.id} onClick={() => {
                        setSelected( item.channel.id );
                    }}>
                        {item.channel.teacher_chat ? t( 'chat_teacher_channel_heading' ) : t( 'chat_team_channel_heading' )}
                    </div>;
                } else {
                    if ( item.channel.teacher_chat ) {
                        return <div
                            className={"chat__channel" + ( ( selected === item.channel.id ) ? " active" : "" ) + ( item.unseen ? ' flashing-channel' : '' )}
                            key={item.channel.id} onClick={() => {
                            setSelected( item.channel.id );
                        }}>
                            {t( 'chat_teacher_channel_heading' )}
                        </div>;
                    }
                    return null;
                }

            } )}
        </>
    );


}

function mapStateToProps( state ) {
    const {auth} = state;

    return {auth}
}

export default connect(
    mapStateToProps
)( Channels );