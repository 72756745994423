import React, {useState, cloneElement} from 'react';
import {connect} from "react-redux";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Deactivated from "./Deactivated";
import FullScreenModal from "./Screens/Game/FullScreenModal";

function AuthWrapper( props ) {
    const [sidebar, setSidebar] = useState( false );
    const [sticky, setSticky] = useState( false );
    const [fullScreenModal, setFullScreenModal] = useState( false );

    if ( !props.auth.team.activated ) {
        return <Deactivated/>
    }

    return (
        <>

            <Navbar
                handleSidebar={( open ) => setSidebar( open )}
                handleFullScreenModal={() => setFullScreenModal( true )}
            />

            <Sidebar
                open={sidebar}
                sticky={sticky}
                handleSidebarSticky={( value ) => setSticky( value )}
                handleClose={() => setSidebar( false )}
            />

            {
                props.flashes.map( ( flash, i ) => (
                    <div id="flashes">
                        <div className={`alert alert--${flash.status}`}>{flash.text}</div>
                    </div>
                ) )
            }

            {
                cloneElement(
                    props.children,
                    {
                        handleSidebarSticky: ( ( value ) => setSticky( value ) )
                    }
                )
            }

            {
                fullScreenModal && <FullScreenModal handleClose={() => (setFullScreenModal(false))}/>
            }
        </>
    );

}


function mapStateToProps( state ) {
    const {auth, credit, flashes} = state;

    return {
        credit,
        flashes,
        auth
    }
}

export default connect(
    mapStateToProps
)( AuthWrapper );