import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function Diploma({time, diploma}) {
    const {t} = useTranslation();
    console.log({time, diploma})

    return (

        <a href="#" className={"d--flex ai--fs"}>
            <img src="img/notifications/notif-diploma.svg" alt=""/>
            {t('notification_diploma', {time, module: diploma.type})}</a>
    )
}