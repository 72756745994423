import React from 'react';

export default function icon( props ) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="77.591" height="96.989" viewBox="0 0 77.591 96.989">
            <g id="_004-web" data-name="004-web" transform="translate(0)">
                <g transform="translate(0)">
                    <path
                        d="M117.508,0H56.082A8.092,8.092,0,0,0,48,8.082V51.727a8.092,8.092,0,0,0,8.082,8.082h22.1l-2.308,8.082H70.631v3.233h32.33V67.892H97.713L95.4,59.81h22.1a8.092,8.092,0,0,0,8.082-8.082V8.082A8.092,8.092,0,0,0,117.508,0ZM79.24,67.892l2.308-8.082H92.042l2.308,8.082Zm43.118-16.165a4.849,4.849,0,0,1-4.849,4.849H56.082a4.849,4.849,0,0,1-4.849-4.849V48.494h71.125Zm0-6.466H51.233V8.082a4.849,4.849,0,0,1,4.849-4.849h61.426a4.849,4.849,0,0,1,4.849,4.849Z"
                        transform="translate(-48)"/>
                </g>
                <g transform="translate(4.849 74.358)">
                    <path
                        d="M118.878,368H73.616A1.617,1.617,0,0,0,72,369.616v19.4a1.617,1.617,0,0,0,1.616,1.617h45.261a1.617,1.617,0,0,0,1.616-1.617v-19.4A1.617,1.617,0,0,0,118.878,368Zm-1.616,19.4H75.233V371.233h42.028Z"
                        transform="translate(-72 -368)"/>
                </g>
                <g transform="translate(11.315 87.29)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(11.315 80.824)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(17.781 80.824)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(24.247 80.824)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(37.179 80.824)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(30.713 80.824)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(43.645 87.29)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(43.645 80.824)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(17.781 87.29)">
                    <rect width="22.631" height="3.233"/>
                </g>
                <g transform="translate(56.577 74.358)">
                    <path
                        d="M336.082,368A8.083,8.083,0,0,0,328,376.082v6.466a8.082,8.082,0,1,0,16.165,0v-6.466A8.082,8.082,0,0,0,336.082,368Zm4.849,14.548a4.849,4.849,0,1,1-9.7,0v-6.466a4.849,4.849,0,1,1,9.7,0Z"
                        transform="translate(-328 -368)"/>
                </g>
                <g transform="translate(63.043 80.824)">
                    <rect width="3.233" height="3.233"/>
                </g>
                <g transform="translate(33.995 14.138)">
                    <rect width="23.526" height="3.231" transform="translate(0 22.618) rotate(-74.032)"/>
                </g>
                <g transform="translate(17.782 16.54)">
                    <path
                        d="M148.353,84.339l-2.069-2.483-9.7,8.082a1.617,1.617,0,0,0,0,2.483l9.7,8.082,2.069-2.483-8.208-6.841Z"
                        transform="translate(-136.004 -81.856)"/>
                </g>
                <g transform="translate(47.46 16.54)">
                    <path
                        d="M294.854,90.145a1.618,1.618,0,0,0-.206-.206l-9.7-8.082-2.069,2.483,8.209,6.841-8.209,6.841,2.069,2.483,9.7-8.082A1.617,1.617,0,0,0,294.854,90.145Z"
                        transform="translate(-282.88 -81.856)"/>
                </g>
            </g>
        </svg>
    )
}