import React, {useState} from 'react';
import * as axios from "axios";
import {apiURL} from "../../../env";
import store from "../../../index";
import {authTeamLoaded} from "../../../Store/actions/authActions";
import {connect} from "react-redux";
import {flashOpen} from "../../../Store/actions/flashActions";
import {useTranslation} from "react-i18next";

function Password(props) {
    const {t} = useTranslation();

    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordConfirmationVisible, setPasswordConfirmationVisible] = useState(false);
    const [error, setError] = useState( null );

    return (
        <div className="settings-item card">
            <form
                onSubmit={( e ) => {
                    e.preventDefault();

                    axios.post( apiURL + '/settings/password', {
                        current_password: currentPassword,
                        password: password,
                        password_confirmation: passwordConfirmation
                    } )
                        .then( ( response ) => {
                            console.log( response );
                            setError( null );
                            setCurrentPassword('');
                            setPassword('');
                            setPasswordConfirmation('');
                            // TODO - notifications: false parameter added 10.09. to fix #709 - 7) by Marcel
                            store.dispatch( authTeamLoaded( response.data.team, false ) );
                            props.dispatch(flashOpen({status: 'success', text: t('settings_password_flash_success')}));
                        } )
                        .catch( ( error ) => {
                            setError( null );
                            if ( error.response.status === 422 ) {
                                setError( error.response.data );
                            }else{
                                props.dispatch(flashOpen({status: 'danger', text: t('settings_flash_fail')}));
                            }
                        } )
                }}
            >
                <h2 className={"mb--3"}>{t( 'settings_pass' )}</h2>

                <div className="input-container mb--3">
                    <input
                        type={currentPasswordVisible ? 'text' : 'password'}
                        className="input__item icon__after m w--100"
                        placeholder={t( 'settings_pass_placeholder' )}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <span
                        className={"mdi mdi-eye-outline"}
                        onMouseDown={() => setCurrentPasswordVisible(true)}
                        onMouseUp={() => setCurrentPasswordVisible(false)}
                    />
                </div>

                <div className="input-container mb--1">
                    <input
                        type={passwordVisible ? 'text' : 'password'}
                        className="input__item icon__after m w--100"
                        placeholder={t( 'settings_pass_new_placeholder' )}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                        className={"mdi mdi-eye-outline"}
                        onMouseDown={() => setPasswordVisible(true)}
                        onMouseUp={() => setPasswordVisible(false)}
                    />
                </div>

                <div className="input-container mb--3">
                    <input
                        type={passwordConfirmationVisible ? 'text' : 'password'}
                        className="input__item icon__after m w--100"
                        placeholder={t( 'settings_pass_again_placeholder' )}
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                    <span
                        className={"mdi mdi-eye-outline"}
                        onMouseDown={() => setPasswordConfirmationVisible(true)}
                        onMouseUp={() => setPasswordConfirmationVisible(false)}
                    />
                </div>

                {
                    error !== null && (
                        <div className="alert alert--danger">
                            {error.message}
                            <ul>
                                {Object.keys(error.errors).map((input, i) => (
                                    <li key={i}>{error.errors[input]}</li>
                                ))}
                            </ul>
                        </div>
                    )
                }

                <button type="submit" className={"btn btn--m btn--secondary w--100 mt--3"}>{t( 'settings_pass_btn_submit' )}</button>
            </form>
        </div>
    )
}

function mapStateToProps( state ) {
    const {auth} = state;

    return {auth}
}

export default connect(
    mapStateToProps
)( Password );