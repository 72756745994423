import React from "react";
import {categories} from "./categories";

export default function MarketplacesMenu( props ) {
    return (
        <div className="market__categories d--flex fd--c jc--fe">
            {
                categories.map( ( category, i ) => (
                    <div
                        key={i}
                        className={"market__category d--flex ai--fe jc--c" + ( i === props.active ? ' active' : '' )}>
                        <button
                            className="button-clear"
                            onClick={() => {
                                props.handleOpen( i )
                            }}
                        >
                            {category.image}
                        </button>
                    </div>
                ) )
            }
        </div>
    )
}