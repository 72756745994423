import React from 'react';
import {gameModuleClose} from "../../../Store/actions/gameActions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

function ModuleFinish(props) {
    const {t} = useTranslation();

    const classes = [
        'empathy',
        'idea',
        'socialneeds', 
        'sustainable',
        'ideageneration',
        'visionary'
    ];

    return (
        <>
            <div className="modal-wrapper">
                <div className="modal modal--l">
                    <div className={"modal__body modal__module-finish text--center " + classes[props.game.module - 1]}>
                        <div className="modal__inner">
                            <h2>{t( 'module_finish_congrats' )}</h2>
                            <p className={"subtitle mb--3"}>{t( 'module_finish_subheading' )}</p>

                            <img src={`img/modules/bg/badge-${ classes[props.game.module - 1] }.png`} alt=""/>
                            <p className={"mb--2"}>
                                {t(`module_finished_${props.game.module}_quote`)}
                            </p>

                            <p className={"mb--2"} dangerouslySetInnerHTML={{__html: t( `module_finished_${props.game.module}_congratulations` )}}/>

                            <div className="d--flex jc--c mt--3">
                                <button
                                    className={"btn btn--m btn--text mr--1"}
                                    onClick={() => props.dispatch(gameModuleClose())}
                                >
                                    {t( 'back_to_menu' )}
                                </button>
                                <button className={"btn btn--m btn--success"}>
                                    {t( 'share' )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="backdrop info"></div>
        </>
    )
}


function mapStateToProps(state) {
    const {game} = state;

    return {game}
}

export default connect(
    mapStateToProps
)(ModuleFinish);