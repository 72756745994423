import React from 'react';

export default function icon( props ) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="88.447" height="88.447"
             viewBox="0 0 88.447 88.447">
            <g transform="translate(60.018 38.519)">
                <path
                    d="M350.752,223.86a1.727,1.727,0,0,0-2.351-.664l-.091.052a1.727,1.727,0,1,0,1.71,3l.069-.039A1.727,1.727,0,0,0,350.752,223.86Z"
                    transform="translate(-347.437 -222.976)"/>
            </g>
            <g transform="translate(32.131 51.306)">
                <path d="M188.95,297.508a1.726,1.726,0,1,0,.506,1.221A1.74,1.74,0,0,0,188.95,297.508Z"
                      transform="translate(-186.001 -297.002)"/>
            </g>
            <g transform="translate(0 0)">
                <path
                    d="M44.57,43.533H40.342a61.235,61.235,0,0,0,4.878-17.359l.718-5.647a1.728,1.728,0,0,0-2.889-1.484l-.578.537a48.014,48.014,0,0,0-6.423,7.306c1.517-7.282,3.467-14.643,5.587-19.264l.009-.019a1.727,1.727,0,0,0-2.587-2.116,47.538,47.538,0,0,0-9.167,8.786A39.428,39.428,0,0,1,33.2,2.45,1.728,1.728,0,0,0,30.862.183l-3.194,1.6A17.828,17.828,0,0,0,18.15,14.1a26.565,26.565,0,0,0-8.6-6.55L4.9,5.324A1.727,1.727,0,0,0,2.638,7.716l.311.565a76.541,76.541,0,0,1,7.192,18.313,22.615,22.615,0,0,0-3.767-2.279l-3.9-1.864A1.727,1.727,0,0,0,.215,24.842l.26.473A63.9,63.9,0,0,1,7.143,43.533H1.728A1.728,1.728,0,0,0,0,45.26V73.85a14.614,14.614,0,0,0,14.6,14.6H31.7a14.614,14.614,0,0,0,14.6-14.6V45.26A1.727,1.727,0,0,0,44.57,43.533Zm-2.7-18.411-.078.616a57.8,57.8,0,0,1-5.26,17.795H32.06A44.014,44.014,0,0,1,41.871,25.122ZM36.126,12.5a184.826,184.826,0,0,0-5.333,23.889c0,.01,0,.02,0,.03A47.971,47.971,0,0,0,28.5,43.533H23A44.128,44.128,0,0,1,36.126,12.5Zm-14.9,5.326A14.4,14.4,0,0,1,28.357,5.339a42.916,42.916,0,0,0-2.193,13.482V19.3a1.725,1.725,0,0,0,.03.317c-.525.883-1.024,1.781-1.49,2.7q-.527,1.036-1,2.1a26.574,26.574,0,0,0-2.553-6.1A1.725,1.725,0,0,0,21.221,17.822ZM8.057,10.664h0A23.265,23.265,0,0,1,21.221,31.353a47.553,47.553,0,0,0-1.674,12.18H19.19A22.555,22.555,0,0,0,15.743,32.65c-.347-.55-.719-1.082-1.109-1.6A79.965,79.965,0,0,0,8.057,10.664ZM5.6,27.793A19.249,19.249,0,0,1,15.73,43.533H10.665A67.324,67.324,0,0,0,5.6,27.793ZM42.842,73.85A11.155,11.155,0,0,1,31.7,84.992H14.6A11.155,11.155,0,0,1,3.456,73.85V54.761H27.209a1.727,1.727,0,0,0,0-3.455H3.456V46.988H42.842Z"
                    transform="translate(-0.001 0)"/>
            </g>
            <g transform="translate(52.17 17.65)">
                <path
                    d="M337.91,146.683a1.728,1.728,0,0,0-1.359-.661h-9.357V133.263a11.171,11.171,0,0,0-.2-2.105l6.334-4.264a8.235,8.235,0,0,0-.576-14.017l-.065-.035a8.257,8.257,0,0,0-8.493.649v-4.741a6.579,6.579,0,0,0-9.546-5.872l-.065.035a6.579,6.579,0,0,0-.458,11.2l6.762,4.551a8.179,8.179,0,0,0-.147,1.533v2.891a11.273,11.273,0,0,0-3.537-1,1.727,1.727,0,0,0-.384,3.434,7.724,7.724,0,0,1,4.421,2.019c.026.027.054.051.082.076a7.918,7.918,0,0,1,.771.852,7.828,7.828,0,0,1,1.65,4.795v12.758H321.6l-9.639-6.488a7.789,7.789,0,0,1-2.748-9.671,1.727,1.727,0,0,0-3.148-1.423A11.244,11.244,0,0,0,310.03,142.4l5.381,3.621H303.729a1.727,1.727,0,0,0-1.678,2.139l5.76,23.494a1.727,1.727,0,0,0,1.678,1.316h21.3a1.727,1.727,0,0,0,1.678-1.316l5.76-23.494A1.728,1.728,0,0,0,337.91,146.683Zm-21.861-35.439a3.025,3.025,0,1,1,4.685-2.494V114.4Zm9.153,6.006a4.648,4.648,0,1,1,6.192,6.777l-5.616,3.781a11.15,11.15,0,0,0-.967-1.466c-.2-.253-.406-.494-.621-.726v-5.423A4.8,4.8,0,0,1,325.2,117.25Zm7.707,38.1h-19.1a1.727,1.727,0,1,0,0,3.455h18.256l-2.626,10.71H310.844l-4.913-20.039h28.417Z"
                    transform="translate(-302.001 -102.173)"/>
            </g>
        </svg>
    )
}
