import React from 'react';
import {useTranslation} from "react-i18next";

export default function TeacherEvaluation( props ) {

    const {t} = useTranslation();

    return (
        <div className="d--iflex fd--c ai--fs question-container">

            <div className="modal-wrapper">
                <div className="modal modal--l modal__outro">

                    <button
                        className={"modal__close d--flex dark"}
                        onClick={() => props.handleClose()}
                    >
                        <span className={"mdi mdi-close m--a"}> </span>
                    </button>

                    <div className={"modal__body d--flex fd--c"}>

                        <div className="modal__inner">
                            <h2 className={"text--center mb--3"}>{t( 'task-evaluate-modal-heading' )}</h2>


                            <p className={""}>{t( 'points' )}: {props.points}</p>
                            <hr/>
                            <div className={""} dangerouslySetInnerHTML={{__html: props.comment}}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="backdrop info"></div>

        </div>
    )
}