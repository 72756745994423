import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useTranslation} from "react-i18next";

export default function Imgright(props) {
    const {t, i18n} = useTranslation();
    return (

        <div className={"wrapper__content wrapper__cs d--flex"}>
            <div className="color--bg-full__white"></div>


            <div className="d--flex pb--2 pt--2 w--50 sm:w--60 mr--4 ai--c">
                <div dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_text`)}}/>
            </div>

            <div className="w--50 sm:w--40 d--flex ai--c">
                <img src={"/img/theory/" + props.image} alt="" className={"w--100"}/>
            </div>
        </div>
    )
}