import React from 'react';

import {I18nextProvider, useTranslation} from "react-i18next";
import {connect, Provider} from "react-redux";

import store from "./index";
import i18n from "./i18n";

import Router from "./Router";

export default function App() {

    const {t} = useTranslation();

    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <div className={"wrapper"}>
                    <Router/>
                </div>
            </Provider>
        </I18nextProvider>
    );

    return (
        <>
            <div className={"disclaimer d--flex"}>
                <div className="disclaimer__wrapper m--a d--flex jc--fs">

                    <div className="disclaimer__copyright d--flex as--c sm:text--center">
                        <p>{t('interreg_copyright')}</p>
                    </div>

                    <div className="disclaimer__logo mr--3 sm:mr--0 sm:text--center">
                        <img src={"/img/innoschool-logo.png"}/>
                    </div>

                    <div className="disclaimer__text d--flex as--c sm:text--center">
                        <p>{t('interreg_disclaimer')}</p>
                    </div>

                </div>
            </div>
        </>
    );
}
