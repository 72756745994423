export const GAME_MODULES_RESET = 'GAME_MODULES_RESET';

export const GAME_MODULE_UNLOCK = 'GAME_MODULE_UNLOCK';
export const GAME_MODULE_OPEN = 'GAME_MODULE_OPEN';
export const GAME_MODULE_CLOSE = 'GAME_MODULE_CLOSE';
export const GAME_MODULES_LOAD = 'GAME_MODULES_LOAD';


export const GAME_SECTION_UNLOCK = 'GAME_SECTION_UNLOCK';
export const GAME_SECTION_OPEN = 'GAME_SECTION_OPEN';
export const GAME_SECTION_CLOSE = 'GAME_SECTION_CLOSE';
export const GAME_SECTION_FINISHED = 'GAME_SECTION_FINISHED';
export const GAME_SECTION_NEXT = 'GAME_SECTION_NEXT';

export const GAME_SECTION_UNLOCK_NEXT = 'GAME_SECTION_UNLOCK_NEXT';

export const GAME_LOGOUT = 'GAME_LOGOUT';


export const gameModuleReset = () => ( {
    type: GAME_MODULES_RESET
} );

export const gameModuleUnlock = ( data ) => ( {
    type: GAME_MODULE_UNLOCK,
    data
} );

export const gameModuleOpen = ( module, section ) => ( {
    type: GAME_MODULE_OPEN,
    module,
    section
} );

export const gameModuleClose = () => ( {
    type: GAME_MODULE_CLOSE
} );

export const gameModulesLoad = ( modules ) => ( {
    type: GAME_MODULES_LOAD,
    modules
} );

export const gameSectionUnlock = ( data ) => ( {
    type: GAME_SECTION_UNLOCK,
    data
} );

export const gameSectionOpen = ( section ) => ( {
    type: GAME_SECTION_OPEN,
    section
} );

export const gameSectionClose = () => ( {
    type: GAME_SECTION_CLOSE
} );

export const gameSectionNext = () => ( {
    type: GAME_SECTION_NEXT
} );

export const gameSectionFinished = () => ( {
    type: GAME_SECTION_FINISHED
} );

export const gameSectionUnlockNext = () => ( {
    type: GAME_SECTION_UNLOCK_NEXT
} );

export const gameLogout = () => ( {
    type: GAME_LOGOUT
} );
