import React, {useState} from 'react';
import {useDrag} from "react-dnd";

export default function WordConnected( {index, word, type, handleClick} ) {
    const [{opacity}, drag] = useDrag( {
        type,
        item:    {index, word},
        collect: ( monitor ) => ( {
            opacity: monitor.isDragging() ? 0.4 : 1,
        } ),
    } )

    return (
        <div
            ref={drag}
            style={{opacity}}
            className="connect__item w--100 text--center"
            onClick={handleClick}
        >
            <span>{word}</span>
        </div>
    )
}