import React from "react";
import Modal from "./Modal";
import {useTranslation} from "react-i18next";

export default function BuyConfirmation( props ) {
    const {t} = useTranslation();

    return (
        <Modal handleClose={props.handleClose}>

            <h2 className={"mb--1"}>{t( 'office_marketplace_buy_confirmation_heading' )}</h2>
            <p className={"mb--3"}>{t( 'office_marketplace_buy_confirmation_text' )}</p>

            <button
                className={"btn btn--m btn--text mr--1"}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    props.handleClose()
                }}
            >
                {t( 'close' )}
            </button>
            <button
                className={"btn btn--m btn--secondary"}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    props.handleBuy()
                    props.handleClose()
                }}
            >
                {t( 'buy-confirmation' )}
            </button>


        </Modal>
    )


}