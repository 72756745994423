import React from 'react';
import {gameModuleClose} from "../../../Store/actions/gameActions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {authTeamModuleUnlockedClose} from "../../../Store/actions/authActions";

function ModuleUnlocked(props) {
    const {t} = useTranslation();


    return (
        <>
            <div className="modal-wrapper">
                <div className="modal modal--l modal__unlock-level">
                    <div className="modal__body">
                        <div className="modal__inner d--flex">

                            <div className="w--50">
                                <h2 className={"mb--3"}>{t('module_ulock_congrats')}</h2>
                                <p className={"subtitle mb--3"}>{t('module_ulock_text')}</p>
                                <p className={"subtitle mb--3"}>{t('good__luck')}</p>

                                <div className="mt--3">
                                    <button
                                        className={"btn btn--m btn--secondary btn--shadow d--block"}
                                        onClick={() => props.dispatch(authTeamModuleUnlockedClose(props.type))}
                                    >
                                        {t('module_finish_btn_continue')}
                                    </button>
                                </div>
                            </div>

                            <div className="img__right ">
                                <img src="img/intro-modal_bubble2.png"
                                     className="intro__bubble" />
                                    <img src="img/marvin-thumbs.svg" className="modal__marvin" />
                                    </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="backdrop info"></div>
        </>
)
}


function mapStateToProps(state) {
    const {game} = state;

    return {game}
}

export default connect(
mapStateToProps
)(ModuleUnlocked);