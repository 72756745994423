import {SOUND_TOGGLE} from "../actions/soundActions";

export const SoundReducer = ( state = true, action ) => {
    switch ( action.type ) {
        case SOUND_TOGGLE:
            return action.sound;

        default:
            return state;
    }
};