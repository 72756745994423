import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

import ScreensNavigation from "../ScreensNavigation";
import {useKeysNavigation} from "../../../../Hooks/useKeysNavigation";
import {useBubbleDelay} from "../../../../Hooks/useBubbleDelay";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import {intro_screens} from "./data/intro";
import store from "../../../../index";
import {gameModuleOpen} from "../../../../Store/actions/gameActions";

function IntroContent({screenContent, screen, step}) {
    const {t} = useTranslation();
    const bubbleDelayed = useBubbleDelay([screen]);

    if (screenContent.layout === 'right') return (
        <div className={"marvin h--100"}>
            <div className="w--100 h--100 p--rel d--flex jc--c sm:o--1 sm:theory__table-center">
                <div className={"marvin-container sm:w--100"}>
                    <img src={"img/" + screenContent.marvine}
                         className={"marvin__img" + (screenContent.person_small ? " s" : "")} alt=""/>
                    {
                        bubbleDelayed && (
                            <div className="text-bubble-theory right">
                                <div className="bubble__inner">
                                    <p dangerouslySetInnerHTML={{
                                        __html: t('gameintro_intro_bubbles_' + (screen + 1))
                                    }}/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
    else if (screenContent.layout === 'left') return (
        <div className={"marvin h--100"}>
            <div className="w--100 h--100 p--rel d--flex jc--c sm:o--1 sm:theory__table-center">
                <div className={"marvin-container sm:w--100"}>
                    <img src={"img/" + screenContent.marvine}
                         className={"marvin__img" + (screenContent.person_small ? " s" : "")} alt=""/>
                    {
                        bubbleDelayed && (
                            <div className="text-bubble-theory left">
                                <div className="bubble__inner">
                                    <p dangerouslySetInnerHTML={{
                                        __html: t('gameintro_intro_bubbles_' + (screen + 1))
                                    }}/>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
    else if (screenContent.layout === 'top') return (
        <div className={"marvin full h--100"}>
            <div className="w--100 h--100 p--rel d--flex jc--c sm:o--1 sm:theory__table-center">
                <div className={"marvin-container sm:w--100"}>
                    {
                        bubbleDelayed && (
                            <div className="text-bubble-theory top">
                                <div className="bubble__inner">
                                    <p dangerouslySetInnerHTML={{
                                        __html: t('gameintro_intro_bubbles_' + (screen + 1))
                                    }}/>
                                </div>
                            </div>
                        )
                    }
                    <img src={"img/" + screenContent.marvine}
                         className={"marvin__img"} alt=""/>
                </div>
            </div>
        </div>
    )
    else if (screenContent.layout === 'dialog') return (

        <div className={'h--100 dialog'}>
            <div className="w--50 h--100 theory__table-right p--rel sm:w--100 sm:o--2 sm:theory__table-right">
                <div className="dialog-container">
                    <img src={"img/" + screenContent.person}
                         className={"dialog__img" + (screenContent.person_small ? " s" : "")}
                         alt=""/>
                    {
                        step > 0 && (
                            <div className="text-bubble-theory left">
                                <div className="bubble__inner">
                                    <p dangerouslySetInnerHTML={{
                                        __html: t('gameintro_intro_bubbles_' + (screen + 1) + '_person_response')
                                    }}/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className="w--50 p--rel sm:w--100 sm:o--1 sm:theory__table-left">
                <div className="marvin-container">
                    <img src={"img/" + screenContent.marvine} className={"marvin__img"} alt=""/>
                    {
                        bubbleDelayed && (
                            <div className="text-bubble-theory right">
                                <div className="bubble__inner">
                                    <p dangerouslySetInnerHTML={{
                                        __html: t('gameintro_intro_bubbles_' + (screen + 1) + '_marvine')
                                    }}/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>

    )
}


function Intro(props) {
    const [screen, setScreen] = useState(0);
    const [step, setStep] = useState(0);

    const handlePrev = () => {

        if (screen > 0) {
            setScreen(screen - 1);
            setStep(0);
        } else {
            setScreen(null);
            setStep(0);
        }
    }

    const handleNext = () => {
        if (screen < (intro_screens.length - 1)) {
            if (intro_screens[screen].layout === 'dialog' && step < 1) {
                setStep(1);
            } else {
                setScreen(screen + 1);
                setStep(0);
            }

        } else {
            axios({
                method: "get",
                url: apiURL + `/module/${props.game.module}/section/intro/complete`,
            })
                .then((response) => {
                    props.dispatch(gameModuleOpen(0, 'faq'));

                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    useKeysNavigation(handlePrev, handleNext);

    return (
        <>
            <div className="intro-wrapper">
                <div className={"theory-container"}>


                    <IntroContent screenContent={intro_screens[screen]} screen={screen} step={step}/>
                    <ScreensNavigation handlePrev={handlePrev} handleNext={handleNext}/>


                </div>

                <img src={"img/" + intro_screens[screen].background}
                     className={`img--bg-full ${intro_screens[screen].background === 'bg-supporters.png' ? 'supporters' : ''}`}
                     alt=""/>

            </div>
        </>
    )


}

function mapStateToProps(state) {
    const {game} = state;

    return {game}
}

export default connect(
    mapStateToProps
)(Intro);