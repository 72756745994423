import React from 'react';

import ScenarioModal from "./ScenarioModal";

export default function Scenario( props ) {

    return (
        <>
            <div className="backdrop info"/>
            <div className="modal-wrapper">
                <ScenarioModal
                    {...props}
                />
            </div>
        </>
    )
}