import React from 'react';
import {useTranslation} from "react-i18next";

export default function Dialog(props) {

    const {t, i18n} = useTranslation();

    return (
        <div className={"theory-container"}>
            <div className={"theory__book-container h--100 d--flex"}>
                <img src={"/img/book/Book_Bg.jpg"} className={"img--bg-full cover"}/>

                <div className="theory__book-book empty m--a">
                    <h2>{t(`theory_${props.module}_slide_${props.slide}_book_heading`)}</h2>
                    <img src={props.book_image} alt="" className={"h--100"}/>
                </div>
            </div>
        </div>
    )
}