import React from 'react';
import {useTranslation} from "react-i18next";

export default function ImgLeft(props) {
    const {t, i18n} = useTranslation();

    return (
        <>
            <h1 className={"text--center cs--theory"}>{t(`scenarios_${props.scenario}_name`)}</h1>
            <div className="wrapper__content wrapper__cs d--flex sm:fd--c">


                <div className="cs--bg" style={{backgroundColor: props.backgroundColor}}></div>
                

                <div className="w--50 sm:w--100 d--flex ai--c">
                    <img src={"/img/case-studies/" + props.image} alt="" className={"w--100"}/>
                </div>

                <div className="d--flex pb--2 pt--2 w--50 sm:w--100 ml--4 sm:ml--0 ai--c">
                    <div>
                        {
                            i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_heading`) && (
                                <h4 className={"mb--2"}
                                    dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_heading`)}}/>
                            )
                        }

                        {
                            i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_text`) && (
                                <p dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_text`)}}/>
                            )
                        }

                        {
                            i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_heading_2`) && (
                                <h4 className={"mb--2 mt--3"}
                                    dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_heading_2`)}}/>
                            )
                        }

                        {
                            i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_text_2`) && (
                                <p dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_text_2`)}}/>
                            )
                        }

                        {
                            i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_heading_3`) && (
                                <h4 className={"mb--2 mt--3"}
                                    dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_heading_3`)}}/>
                            )
                        }

                        {
                            i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_text_3`) && (
                                <p dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_text_3`)}}/>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}