import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function OpenTaskEvaluation({time, openTaskEvaluation}) {
    const {t} = useTranslation();

    return (
        <a href="#" className={"d--flex ai--fs"}>
            <img src="img/notifications/notif-task.svg" alt=""/>
            {t('notification_open_task_evaluation', {time, module: openTaskEvaluation.module})}</a>

    )
}