import React, {useState, useEffect} from 'react';

import introData from './data_intro';
import Outro from "./Outro";
import MultipleChoiceQuestion from "./Questions/MultipleChoice/MultipleChoiceQuestion";
import FillQuestion from "./Questions/Fill/FillQuestion";
import {connect} from "react-redux";
import {
    gameModuleClose,
    gameModulesLoad,
    gameSectionNext,
} from "../../../../Store/actions/gameActions";
import Marvin from "./Marvin";
import Countdown from "./Countdown";
import ConnectQuestion from "./Questions/Coneect/ConnectQuestion";
import {creditSet, supportersSet} from "../../../../Store/actions/creditActions";
import {useKeysNavigation} from "../../../../Hooks/useKeysNavigation";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import Loading from "./Loading";
import {useTranslation} from "react-i18next";

function Quiz( props ) {
    const [q, setQ] = useState( 0 );
    const [intro, setIntro] = useState( 0 );
    const [closeModal, setCloseModal] = useState( false );
    const [questionsLoading, setQuestionsLoading] = useState( false );
    const [questionsLoaded, setQuestionsLoaded] = useState( false );
    const [questions, setQuestions] = useState( null );
    const [answers, setAnswers] = useState( null );
    const [times, setTimes] = useState( null );
    const [results, setResults] = useState( null );
    const [invalidAnswer, setInvalidAnswer] = useState( false );
    const {t} = useTranslation();

    function handleNext( data ) {
        if ( data !== undefined ) {

            setAnswers( answers.map( ( a, i ) => ( i === q ? data : a ) ) );

            const newTimes = times.map( ( t, i ) => ( i === q ? ( data.duration ) : t ) );

            const question_id = questions[ q ].id;

            axios( {
                method: 'post',
                url:    apiURL + `/question/${question_id}/answer`,
                data:   data
            } )
                .then( ( response ) => {
                    setInvalidAnswer( false );
                    if ( intro < introData[ props.game.module ].length ) {
                        setIntro( intro + 1 );
                    } else {
                        if ( q === questions.length - 1 ) {
                            axios( {
                                method: "get",
                                url:    apiURL + `/module/${props.game.module}/section/quiz/complete`,
                            } )
                                .then( ( response ) => {
                                    props.dispatch( creditSet( response.data.credit ) );
                                    props.dispatch( supportersSet( response.data.supporters ) );
                                    props.dispatch( gameModulesLoad( response.data.modules ) );
                                    setResults( response.data.results );
                                    setQ( q + 1 );
                                } )
                                .catch( ( error ) => {
                                    console.error( error );
                                } );
                        } else if ( q < questions.length ) {

                            setQ( q + 1 );
                            setTimes( newTimes );
                            console.log( 'tuuuu som' );
                        }
                    }
                } )
                .catch( ( error ) => {

                    if ( error.response.status == 422 ) {
                        setInvalidAnswer( true );
                    }
                } );
        } else {
            if ( intro < introData[ props.game.module ].length ) {
                setIntro( intro + 1 );
            } else {
                if ( q === questions.length - 1 ) {
                    axios( {
                        method: "get",
                        url:    apiURL + `/module/${props.game.module}/section/quiz/complete`,
                    } )
                        .then( ( response ) => {
                            props.dispatch( creditSet( response.data.credit ) );
                            props.dispatch( supportersSet( response.data.supporters ) );
                            props.dispatch( gameModulesLoad( response.data.modules ) );
                            setResults( response.data.results );
                            setQ( q + 1 );
                        } )
                        .catch( ( error ) => {
                            console.error( error );
                        } );
                } else if ( q < questions.length ) {
                    setQ( q + 1 );
                }
            }
        }
    };

    function handlePrev(data) {
        if ( q > 0 ) setQ( q - 1 );
        if ( data !== undefined ) {
            const newTimes = times.map( ( t, i ) => ( i === q ? ( data.duration ) : t ) );

            setTimes(newTimes);
        }
    };

    useEffect( () => {
        setQuestionsLoading( true );

        axios( {
            method: 'get',
            url:    apiURL + `/scenario/${1}/module/${props.game.module}/quiz`,
        } )
            .then( ( response ) => {
                setQuestionsLoading( false );
                setQuestions( response.data );
                setAnswers( response.data.map( ( q ) => q.answer ) );
                setTimes( response.data.map( ( q ) => q.time ) );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }, [] );


    if ( intro < introData[ props.game.module ].length ) {
        return (
            <Marvin
                level={props.game.module}
                step={intro}
                // marvin={introData[ props.game.module ][ intro ].marvin}
                marvin_image={introData[ props.game.module ][ intro ].marvin_image}
                handlePrev={q > 0 ? handlePrev : null}
                handleNext={handleNext}
            />
        )
    } else if ( intro === introData[ props.game.module ].length ) {
        if ( questionsLoading ) {
            return (
                <Loading/>
            )
        }

        return (
            <Countdown
                handlePrev={q > 0 ? handlePrev : null}
                handleNext={() => {
                    setIntro( intro + 1 );
                }}
            />
        )
    } else if ( questions !== null && answers !== null && times !== null ) {
        if ( q === questions.length ) {
            return (
                <Outro
                    results={results}
                    level={props.game.module}
                    handlePrev={handlePrev}
                    handleBackToMenu={() => {
                        props.dispatch( gameModuleClose() )
                    }}
                    handleQuizEnd={() => {
                    }}
                    handleContinue={() => props.dispatch( gameSectionNext() )}
                />
            )
        } else {

            return (
                <>
                    {closeModal && (
                        <>
                            <div className="backdrop danger"></div>
                            <div className="modal-wrapper">
                                <div className="modal danger modal--m">
                                    <div className="modal__body">
                                        <div className="modal__inner">

                                            <h2 className={"mb--3"}>{t( 'quiz_modal_close_heading' )}</h2>
                                            <p className={"mb--2"}>{t( 'quiz_modal_close_text1' )}</p>
                                            <p className={"mb--3"}>{t( 'quiz_modal_close_text2' )}</p>
                                            <button className={"btn btn--s btn--danger btn--shadow"}
                                                    onClick={() => {
                                                        props.dispatch( gameModuleClose() )
                                                    }}
                                            >
                                                {t( 'quiz_modal_close_btn_submit' )}
                                            </button>
                                            <button className={"btn btn--s btn--text"}
                                                    onClick={() => {
                                                        setCloseModal( false );
                                                    }}
                                            >
                                                {t( 'back' )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {( () => {
                        console.log( questions[ q ] );
                        if ( questions[ q ].type === 'MultipleChoiceQuestion' ) {
                            return (
                                <MultipleChoiceQuestion
                                    id={questions[ q ].id}
                                    invalid={invalidAnswer}
                                    question={questions[ q ].question}
                                    definition={questions[ q ].definition}
                                    text={questions[ q ].text}
                                    options={questions[ q ].options}
                                    time={times[ q ]}
                                    maxTime={questions[ q ].maxTime}
                                    bonus={questions[ q ].bonus}
                                    coins={questions[ q ].coins}
                                    answers={answers[ q ]}
                                    handleClose={() => setCloseModal( true )}
                                    handlePrev={handlePrev}
                                    handleNext={handleNext}
                                />
                            )
                        } else if ( questions[ q ].type === 'FillQuestion' ) {
                            return (
                                <FillQuestion
                                    id={questions[ q ].id}
                                    invalid={invalidAnswer}
                                    question={questions[ q ].question}
                                    time={times[ q ]}
                                    maxTime={questions[ q ].maxTime}
                                    bonus={questions[ q ].bonus}
                                    coins={questions[ q ].coins}
                                    text={questions[ q ].text}
                                    texts={questions[ q ].texts}
                                    fills={questions[ q ].fills}
                                    answers={answers[ q ]}
                                    handleClose={() => setCloseModal( true )}
                                    handlePrev={handlePrev}
                                    handleNext={handleNext}
                                />
                            )
                        } else if ( questions[ q ].type === 'ConnectQuestion' ) {
                            return (
                                <ConnectQuestion
                                    id={questions[ q ].id}
                                    invalid={invalidAnswer}
                                    left={questions[ q ].left}
                                    right={questions[ q ].right}
                                    correct={questions[ q ].correct}
                                    time={times[ q ]}
                                    maxTime={questions[ q ].maxTime}
                                    bonus={questions[ q ].bonus}
                                    coins={questions[ q ].coins}
                                    question={questions[ q ].question}
                                    text={questions[ q ].text}
                                    answers={answers[ q ]}
                                    handleClose={() => setCloseModal( true )}
                                    handlePrev={handlePrev}
                                    handleNext={handleNext}
                                />
                            )
                        }
                    } )()}
                </>
            )


            // } else if (questions[q].type === 'App\\Models\\Question\\ConnectQuestion') {
            //     return (
            //         <ConnectQuestion
            //             id={questions[q].id}
            //             left={questions[q].left}
            //             right={questions[q].right}
            //             correct={questions[q].correct}
            //             handlePrev={handlePrev}
            //             handleNext={handleNext}
            //         />
            //     )
            // } else if (questions[q].type === 'App\\Models\\Question\\WordsQuestion') {
            //     return (
            //         <WordQuestion
            //             id={questions[q].id}
            //             words={questions[q].words}
            //             handlePrev={handlePrev}
            //             handleNext={handleNext}
            //         />
            //     )
            // } else if (questions[q].type === 'App\\Models\\Question\\PriorityQuestion') {
            //     return (
            //         <PriorityQuestion
            //             words={questions[q].words}
            //             handlePrev={handlePrev}
            //             handleNext={handleNext}
            //         />
            //     )
            // } else if (questions[q].type === 'App\\Models\\Question\\CategoriesQuestion') {
            //     return (
            //         <CategoriesQuestion
            //             words={questions[q].words}
            //             categories={questions[q].categories}
            //             handlePrev={handlePrev}
            //             handleNext={handleNext}
            //         />
            //     )
        }
    }


    return 'loading...';

}

function mapStateToProps( state ) {
    const {game} = state;

    return {game}
}

export default connect(
    mapStateToProps
)( Quiz );