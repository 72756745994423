import React from 'react';

export default function QuizCloseButton( props ) {
    return (
        <button
            className={"question__close d--flex dark"}
            onClick={() => {
                props.handeClick();
            }}
        >
            <span className={"mdi mdi-close m--a"}> </span>
        </button>
    )
}