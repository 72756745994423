import React, {useEffect, useState} from 'react';
import * as axios from "axios";
import {apiURL} from "../../../../../../env";
import QuizCloseButton from "../../QuizCloseButton";
import {useInterval} from "../../../../../../Hooks/useInterval";
import {useTranslation} from "react-i18next";
import InfoPanel from "../../../../../InfoPanel";

function MultipleChoiceQuestion( props ) {
    const [selected, setSelected] = useState( props.answers ? props.answers.option : [] );
    const [options, setOptions] = useState( props.options );
    const [answers, setAnswers] = useState( props.answers );
    const [question, setQuestion] = useState( props.question );

    const [duration, setDuration] = useState( props.time );

    useInterval( () => {
        setDuration( duration + 1 );
    }, 1000 );

    useEffect( () => {
        setSelected( props.answers ? props.answers.option : [] );
        setOptions( props.options );
        setQuestion( props.question );
        setDuration( props.time );
        setAnswers( props.answers );
    }, [props.options, props.questions, props.answers, props.time] )



    const closeButton = typeof props.closeButton === "undefined" ? true : props.closeButton;

    const {t} = useTranslation();

    const timeRemaining = Math.max(0, props.maxTime - duration);
    const timeProgress = Math.round( ( timeRemaining / props.maxTime ) * 100 );

    return (
        <div
            className={"d--iflex fd--c ai--fs question-container" + ( props.className ? ( ' ' + props.className ) : '' ) + ( props.background ? ( ' bg-' + props.background ) : ' bg-default' )}>

            {
                closeButton && (
                    <QuizCloseButton handeClick={() => props.handleClose()}/>
                )
            }


            <div className="question-header-container mb--3 sm:mb--2 w--100">
                <div className="d--flex jc--sb mb--3 ai--c sm:fw--w sm:mb--1">
                    <h1 className={"question__quiz"}>{t( 'quiz_time_heading' )}</h1>
                </div>



                <h2 className={"question__heading d--iblock mb--2"}>{question}</h2>
                <div className="d--flex ">
                <span className={"question__subheading mt--2 d--block"}>{t( 'quiz_type_multiple' )}</span>
                    {
                        props.className && props.className === "theory" && answers && answers.answeredBy && (
                            <InfoPanel text={t( 'info_qff_answered') + " - " + answers.answeredBy} classNames={['w--50 ml--2']}/>
                        )
                    }
                </div>

            </div>



            <div className="question-item-container mb--2 d--flex fd--c">
                {
                    options.map( ( o, i ) => {

                        let className = '';

                        if ( selected.indexOf( o.id ) > -1 ) {
                            className = 'selected';

                            if ( o.correct === true ) className = 'correct';
                            else if ( o.correct === false ) className = 'incorrect';
                        }

                        return (
                            <div
                                key={o.id}
                                className={"question__item d--flex ai--c as--fs mb--2 " + className}
                                onClick={( e ) => {
                                    e.preventDefault();

                                    if (!props.answers){
                                        setSelected(
                                            selected.indexOf( o.id ) === -1
                                                ? selected.concat( [o.id] )
                                                : selected.filter( ( e, i ) => {
                                                    return e !== o.id;
                                                } )
                                        )
                                    }
                                }}
                            >
                                <div className="question__item-options mr--2 d--flex">
                                    <span className={"m--a"}>
                                        {String.fromCharCode( 65 + i )}
                                    </span>
                                </div>

                                <div className="question__item-content">
                                    {o.text}
                                </div>
                            </div>
                        )
                    } )
                }

                {
                    props.className && props.className === "theory" && (
                        <InfoPanel text={t( 'info_qff')} classNames={['w--100']}/>
                    )
                }
            </div>


            <div className="btn-container quiz d--flex jc--fe sm:jc--fs m:jc--fs">
                {
                    props.invalid && (
                        <div className={"invalid--answer"}>
                            <span className="mdi mdi-alert mr--1"></span>
                            {t( 'quiz_invalid_answer' )}</div>
                    )
                }

                <button className={"btn btn--danger btn--m mr--2 btn--shadow"} onClick={( e ) => {
                    props.handlePrev({
                        duration
                    })
                }}>
                    {t( 'back' )}
                </button>
                <button className={"btn btn--success btn--m btn--shadow"} onClick={( e ) => {
                    props.handleNext( {
                        option:   selected,
                        duration
                    } )
                }}>
                    {t( 'next' )}
                </button>
            </div>

            {
                props.maxTime && (
                    <div className="quiz-slider">
                        <div className="slider__top slider__time text--center d--flex">
                            <div
                                className="time__container m--a">{timeRemaining}<br/><span> {t( 'quiz_second_short' )}</span>
                            </div>
                        </div>
                        <div className="slider__center">

                            <div
                                className={"slider__progress vertical " + ( timeRemaining > 10 ? 'success' : 'danger' )}>
                                <div
                                    className="progress__fill"
                                    style={{
                                        height: `${timeProgress}%`
                                    }}/>
                            </div>

                            {
                                timeProgress > 0 && (
                                    <div
                                        className="slider__points d--flex"
                                        style={{
                                            top: `${100 - timeProgress}%`
                                        }}
                                    >
                                        <span className={"mr--1"}>{props.bonus}</span>
                                        <img src="img/coin.svg" alt=""/>
                                    </div>
                                )
                            }
                        </div>
                        <div className="slider__bottom slider__time text--center d--flex">
                            <div className="time__container m--a">0<br/><span>{t( 'quiz_second_short' )}</span></div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default MultipleChoiceQuestion;