import React from 'react';
import {useTranslation} from "react-i18next";

export default function OpenTaskEvaluation(props) {

    const {t} = useTranslation();

    if (props.supporters < 0) {
        // toto je modal so zapornym poctom ziskanych supporterov
        return (
            <div className="d--iflex fd--c ai--fs question-container">
                <div className="modal-wrapper">
                    <div className="modal modal--l modal__outro gray">

                        <button
                            className={"modal__close d--flex dark"}
                            onClick={() => props.handleClose()}
                        >
                            <span className={"mdi mdi-close m--a"}> </span>
                        </button>

                        <div className={"modal__body d--flex fd--c"}>

                            <div className="modal__inner d--flex">
                                <div className="w--50">
                                    <h2 className={"text--center mb--3"}>{t('task-evaluate-modal-heading-bad-results')}</h2>


                                    <p className={'text--center text--light text--m mb--1'}>{t('task-evaluate-modal-text1-bad-results')}</p>

                                    <div className={"d--flex jc--c mb--2"}>
                                        <img src="img/supporters.svg" alt="" className={"mr--3 supporters__img"}/>
                                        <p className={"text--supporters as--c"}>{props.supporters}</p>
                                    </div>

                                    <p className={'text--center text--light text--m mt--2'}>{t('task-evaluate-modal-text2-bad-results')}</p>

                                    <p className={"mt--3 mb--2 text--center"}>
                                        <span
                                            dangerouslySetInnerHTML={{__html: t('task-evaluate-modal-view-results')}}/>
                                    </p>

                                    <div className="d--flex jc--c mt--3">
                                        <button
                                            className={"btn btn--m btn--danger btn--shadow d--block mr--1"}
                                            onClick={() => props.handleClose()}
                                        >
                                            {t('continue')}
                                        </button>
                                        <button
                                            className={"btn btn--m btn--white btn--shadow d--flex btn--share"}
                                            onClick={() => {
                                                props.handleShare();
                                            }}
                                        >
                                            {t('share')}
                                        </button>
                                    </div>
                                </div>

                                <img className={"img__right"} src={"img/tasks/bad_results.png"}
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="backdrop info"></div>
            </div>
        )
    }


    return (
        <div className="d--iflex fd--c ai--fs question-container">
            <div className="modal-wrapper">
                <div className="modal modal--l modal__outro">

                    <button
                        className={"modal__close d--flex dark"}
                        onClick={() => props.handleClose()}
                    >
                        <span className={"mdi mdi-close m--a"}> </span>
                    </button>

                    <div className={"modal__body d--flex fd--c"}>

                        <div className="modal__inner d--flex">
                            <div className="w--50">
                                <h2 className={"text--center mb--3"}>{t('task-evaluate-modal-heading')}</h2>

                                <div className={"d--flex jc--c mb--4"}>
                                    <img src="img/supporters.svg" alt="" className={"mr--3 supporters__img"}/>
                                    <p className={"text--supporters as--c"}>{props.supporters}</p>
                                </div>

                                <p className={"mt--3 mb--2 text--center"}>
                                    <span dangerouslySetInnerHTML={{__html: t('task-evaluate-modal-view-results')}}/>
                                </p>

                                <div className="d--flex jc--c">
                                    <button
                                        className={"btn btn--m btn--danger btn--shadow d--block"}
                                        onClick={() => props.handleClose()}
                                    >
                                        {t('continue')}
                                    </button>
                                </div>
                            </div>
                            <img className={"img__right"} src={"img/tasks/task_M" + props.module + ".png"}
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="backdrop info"></div>
        </div>
    )
}