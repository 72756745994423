import React from 'react';
import {useTranslation} from "react-i18next";

export default function ScreensNavigation( props ) {

    const {t} = useTranslation();

    return (
        <>
            {
                typeof(props.handlePrev) === 'function' && (
                    <button
                        className={"btn btn--white nb btn--prev d--flex ai--c jc--c"}
                        onClick={() => props.handlePrev()}
                    >
                        <div className={"d--flex fd--c jc--c ai--c"}>
                            <span className={"mdi mdi-arrow-left"}></span>
                            <span className={"text"}>{t( 'prev' )}</span>
                        </div>
                    </button>
                )
            }
            {
                typeof(props.handleNext) === 'function' && (
                    <button
                        className={"btn btn--secondary btn--next d--flex jc--c ai--c"}
                        onClick={() => props.handleNext()}
                    >
                        <div className={"d--flex fd--c jc--c ai--c"}>
                            <span className={"mdi mdi-arrow-right"}></span>
                            <span className={"text"}>{t( 'next' )}</span>
                        </div>
                    </button>
                )
            }
        </>
    )
}