import React, {useState} from 'react';
import Form from "../../Form/Form";
import {apiURL} from "../../../env";
import {useTranslation} from "react-i18next";

export default function PasswordForm({changeState, token}) {
    const {t, i18n} = useTranslation();

    return (
        <div className={"wrapper__login m--0a d--flex jc--c"}>
            <div className="wrapper__login-bg"></div>
            <div className="w--40 sm:w--100 as--c">
                <h1 className={"heading--m text--center mb--3"}>{t('auth_reset_password_heading')}</h1>
                <div className={"login-container"}>
                    <Form
                        locale={i18n.language}
                        submitLabel={t('auth_reset_password_btn')}
                        submitMethod={"post"}
                        submitUrl={apiURL + '/password-reset/new-password'}
                        handleSubmitSuccess={(data, response) => {
                            changeState('password_success')
                        }}
                        handleSubmitError={(response) => {
                            // setResetSuccess( false );
                        }}
                        inputs={[
                            <input name="token" type="hidden" value={token}/>,
                            <input className={"input__item mb--1 w--100"}
                                   placeholder={t('auth_password')} name="password" type="password"/>,
                            <input className={"input__item mb--1 w--100"}
                                   placeholder={t('auth_password_again')} name="password_confirmation"
                                   type="password"/>
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}