import React, {useState, useEffect} from 'react';
import Scenario from "./Scenario";
import scenarios from './data.json';
import ScenarioSelectModal from "./ScenarioSelectModal";
import {connect} from "react-redux";
import {
    gameModuleClose,
    gameModulesLoad,
    gameSectionNext
} from "../../../../Store/actions/gameActions";
import SectionFinished from "../SectionFinished/SectionFinished";
import IntroModal from "./IntroModal";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import {authScenario} from "../../../../Store/actions/authActions";
import {useTranslation} from "react-i18next";
import InfoPanel from "../../../InfoPanel";

function ScenariosSelect( props ) {
    const [scenario, setScenario] = useState( null );
    const [selected, setSelected] = useState( null );
    const [selectModal, setSelectModal] = useState( false );
    const [visitedScenarios, setVisitedScenarios] = useState( ( new Array( 6 ) ).fill( false ) )
    const [introModal, setIntroModal] = useState( props.auth.team.scenario === null );
    const [selectedBy, setSelectedBy] = useState(null);

    const [loader, setLoader] = useState( true );
    const [outro, setOutro] = useState( false );

    const allFinished = visitedScenarios.reduce( ( v, i ) => ( v && i ), true );

    const {t} = useTranslation();

    useEffect( () => {
        axios( {
            method: "get",
            url:    apiURL + `/module/${props.game.module}/section/scenarios/start`,
        } )
            .then( ( response ) => {
                setSelectedBy(response.data.selectedBy);
                setSelected(response.data.scenario ? (response.data.scenario - 1) : null);
                setLoader(false);
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }, [] );


    console.log( {visitedScenarios}, {scenario} );
    return (
        <>
            <div className="wrapper__content m--0a d--flex fd--c">

                <div className="d--flex jc--sb ai--c">
                    <h1 className="mt--3 mb--3 heading--m">{t( 'scenario_select' )}</h1>

                    {selected ? (
                        <button className={"btn btn--m btn--secondary btn--s"}
                                onClick={() => {
                                    setLoader( true );
                                    axios( {
                                        method: 'get',
                                        url:    apiURL + `/module/${props.game.module}/section/scenarios/complete`,
                                    } )
                                        .then( ( response ) => {
                                            props.dispatch( gameModulesLoad( response.data.modules ) );
                                            props.dispatch( gameModuleClose() );
                                            setLoader( false );
                                        } );
                                }}
                        >
                            {t( 'close' )}
                        </button>
                    ) : (
                        <button className={"btn btn--m btn--secondary btn--s" + ( allFinished ? '' : ' disabled' )}
                                onClick={() => {
                                    if ( allFinished ) setSelectModal( true )
                                }}
                        >
                            {t( 'scenario_select' )}
                        </button>
                    )}

                </div>

                <div className={"card-container w--100"}>
                    {
                        scenarios.map( ( s, i ) => (

                            <button
                                key={i + 1}
                                className={"card scenarios-item" + ( selected == i ? ' selected' : '' ) + ( visitedScenarios[ i ] ? ' visited' : '' )}
                                onClick={( e ) => {
                                    setVisitedScenarios(
                                        visitedScenarios.map( ( v, j ) => ( i === j ) ? true : v )
                                    )
                                    setScenario( i );
                                }}
                            >
                                {
                                    selected == i && (
                                        <div className="scenario__selected__bg d--flex ai--c">
                                        </div>
                                    )
                                }

                                <div className="scenario__bg d--flex ai--c">
                                    <p className={"ai--c"}>{t( `scenarios_${s.code}_description` )}</p>
                                </div>

                                <div className="scenarios__img">
                                    <img src={`/img/scenarios/s${i + 1}.png`} alt=""/>
                                </div>

                                <div className="scenarios__name d--flex ai--c">
                                    <h2>{t( `scenarios_${s.code}_name` )}</h2>
                                </div>

                                {
                                    visitedScenarios[ i ] && (
                                        <div className={"scenario__seen d--flex"}>
                                            <span className={"mdi mdi-eye-check-outline m--a"}></span>
                                        </div>
                                    )
                                }

                            </button>
                        ) )
                    }


                    {
                        scenario !== null && (
                            <Scenario
                                handleClose={() => setScenario( null )}
                                handleChoose={() => setSelected( scenario )}
                                scenario={scenarios[ scenario ]}
                            />
                        )
                    }

                    {
                        selectModal && (
                            <ScenarioSelectModal
                                handleClose={() => setSelectModal( false )}
                                handleSelect={( s ) => {
                                    setSelected( s );
                                    setSelectModal( false );
                                    setLoader( true );

                                    axios( {
                                        method: 'post',
                                        url:    apiURL + '/access/scenario/set',
                                        data:   {scenario: s + 1}
                                    } )
                                        .then( ( response ) => {
                                            props.dispatch( authScenario( s + 1 ) );

                                            axios( {
                                                method: 'get',
                                                url:    apiURL + `/module/${props.game.module}/section/scenarios/complete`,
                                            } )
                                                .then( ( response ) => {
                                                    props.dispatch( gameModulesLoad( response.data.modules ) );
                                                    setLoader( false );
                                                    setOutro( true );
                                                } );

                                        } )
                                        .catch( ( error ) => {
                                            console.error( error );
                                        } );
                                }}
                            />
                        )
                    }

                    {
                        (selected && selectedBy) ? (<InfoPanel text={t( 'info_scenario_selected' ) + " - " + selectedBy} classNames={['w--100']}/>) : (<InfoPanel text={t( 'info_scenario' )}/>)
                    }

                </div>
            </div>
            {
                loader && (
                    selected ? (<div className="loader">
                        <div>
                            <h1>{t( 'loader_saving' )}</h1>
                        </div>
                    </div>) : (<div className="loader">
                        <div>
                            <h1>{t( 'loader_loading' )}</h1>
                        </div>
                    </div>)

                )
            }
            {
                outro && (
                    <>
                        {/* milos tu */}
                        <img src="#" className={"img--bg-full theory"} alt=""/>
                        <SectionFinished
                            handleBackToMenu={() => {
                                props.dispatch( gameModuleClose() );
                            }}
                            handleContinue={() => props.dispatch( gameSectionNext() )}
                        />
                    </>
                )
            }
            {
                introModal && (
                    <IntroModal
                        handleClose={() => setIntroModal( false )}
                    />
                )
            }
        </>


    )
}


function mapStateToProps( state ) {
    const {game, auth} = state;

    return {game, auth}
}

export default connect(
    mapStateToProps
)( ScenariosSelect );