import React from 'react';
import {connect} from "react-redux";

import {useTranslation} from "react-i18next";
import {localeList} from "../i18n";


function AuthWrapper( props ) {
    const {i18n} = useTranslation();

    return (
        <>
            {props.children}
        </>
    );

}


function mapStateToProps( state ) {
    const {auth, credit} = state;

    return {
        credit,
        auth
    }
}

export default connect(
    mapStateToProps
)( AuthWrapper );