import React, {useState, useEffect} from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Upload from "../../../Upload/Upload";
import TeacherEvaluation from "./TeacherEvaluation";
import {apiURL} from "../../../../env";
import {useTranslation} from "react-i18next";
import store from "../../../../index";
import {authTeamOpenTaskStoryOpen} from "../../../../Store/actions/authActions";
import axios from "axios";


const EditorComponent = () => <Editor/>

export default function Task( props ) {
    const {t} = useTranslation();

    console.log('renderujem task', props.files);

    const createEditorStateFromHtml = ( html ) => {
        const contentBlock = htmlToDraft( html );
        const contentState = ContentState.createFromBlockArray( contentBlock.contentBlocks );
        return EditorState.createWithContent( contentState );
    };

    const [editorState, setEditorState] = useState( createEditorStateFromHtml( props.solution ) );
    // const [files, setFiles] = useState( props.files );
    const [evaluation, setEvaluation] = useState( false );

    // useEffect(() => {
    //     setFiles(props.files);
    // }, [props.files])

    useEffect( () => {
        console.log('otvaram task', props);
        axios.get(`${apiURL}/tasks/${props.task.id}/answer`)
            .then((response) => {
                console.log(response);  
            })
    }, [props.task])

    useEffect( () => {
        setEditorState( createEditorStateFromHtml( props.solution ) );
        setEvaluation( false );
    }, [props.solution] );

    useEffect( () => {
        if ( props.solution ) {
            return;
        }

        handleStoryOpen();
    }, [] );

    const handleStoryOpen = () => {
        store.dispatch( authTeamOpenTaskStoryOpen( props.task, props.level, props.index ) );
    }

    if ( ( props.level === 6 && !props.team.is_advanced ) || ( props.advanced && !props.team.is_advanced ) ) {
        return (
            <div className="wrapper__content m--0a d--flex fd--c">

                <div className="w--80 m:w--100 sm:w--100 open-task">

                    <div className="d--flex jc--sb ai--c">
                        <h1 className="mt--3 mb--3 heading--m" dangerouslySetInnerHTML={{__html: props.task.name}}/>
                        <button className={"btn btn--primary btn--shadow btn--s ml--1"}
                                onClick={() => handleStoryOpen()}>
                            {t( 'task-story-open' )}
                        </button>
                    </div>

                    <div className={"mb--1"} dangerouslySetInnerHTML={{__html: props.task.task}}/>
                    <div className={"mb--3 text--s"} dangerouslySetInnerHTML={{__html: props.task.notice}}/>

                    <div className="d--flex jc--fs">
                        <button className={"btn btn--text btn--m ml--1"} onClick={( e ) => {
                            props.handleClose()
                        }}>
                            {t( 'close' )}
                        </button>
                    </div>
                </div>

                <img src={"/img/bg-open_task.png"} className={"img--bg-full"}/>
            </div>
        )
    }

    return (
        <div className="wrapper__content m--0a d--flex fd--c">

            {
                evaluation && (
                    <TeacherEvaluation
                        points={props.task.points}
                        comment={props.task.comment}
                        handleClose={() => setEvaluation( false )}
                    />
                )
            }

            <div className="w--80 m:w--100 sm:w--100 open-task">
                <div className="d--flex jc--sb ai--c">
                    <h1 className="mt--3 mb--3 heading--m" dangerouslySetInnerHTML={{__html: props.task.name}}/>

                    <button className={"btn  btn--primary btn--shadow btn--s ml--1"} onClick={() => handleStoryOpen()}>
                        {t( 'task-story-open' )}
                    </button>
                    {
                        props.task.points !== null && (
                            <button
                                onClick={( e ) => {
                                    setEvaluation( true );
                                }}
                                className={"btn btn--success btn--s nb as--c btn--shadow ml--s"}
                            >
                                <span className={"mdi mdi-eye-check mr--1"}></span>
                                {t( 'task_show_eval' )}
                            </button>
                        )
                    }

                </div>

                {/*<h3 className={"mb--2"} dangerouslySetInnerHTML={{__html: props.task.text}}/>*/}
                <div className={"mb--1"} dangerouslySetInnerHTML={{__html: props.task.task}}/>
                <div className={"mb--3 text--s"} dangerouslySetInnerHTML={{__html: props.task.notice}}/>


                <Editor
                    toolbar={
                        {
                            options:    ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                            inline:     {
                                inDropdown:        false,
                                className:         undefined,
                                component:         undefined,
                                dropdownClassName: undefined,
                                options:           ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                            },
                            blockType:  {
                                inDropdown: true,
                                options:    ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                            },
                            fontSize:   {
                                // icon:              fontSize,
                                options:           [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                className:         undefined,
                                component:         undefined,
                                dropdownClassName: undefined,
                            },
                            fontFamily: {
                                options:           ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                className:         undefined,
                                component:         undefined,
                                dropdownClassName: undefined,
                            },
                            list:       {
                                inDropdown:        false,
                                className:         undefined,
                                component:         undefined,
                                dropdownClassName: undefined,
                                options:           ['unordered', 'ordered', 'indent', 'outdent'],
                                // unordered: { icon: unordered, className: undefined },
                                // ordered: { icon: ordered, className: undefined },
                                // indent: { icon: indent, className: undefined },
                                // outdent: { icon: outdent, className: undefined },
                            },
                            textAlign:  {
                                inDropdown:        false,
                                className:         undefined,
                                component:         undefined,
                                dropdownClassName: undefined,
                                options:           ['left', 'center', 'right', 'justify'],
                                // left: { icon: left, className: undefined },
                                // center: { icon: center, className: undefined },
                                // right: { icon: right, className: undefined },
                                // justify: { icon: justify, className: undefined },
                            },
                            // colorPicker: {
                            //     icon: color,
                            //     className: undefined,
                            //     component: undefined,
                            //     popupClassName: undefined,
                            //     colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                            //              'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                            //              'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                            //              'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                            //              'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                            //              'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                            // },
                            link:    {
                                inDropdown:            false,
                                className:             undefined,
                                component:             undefined,
                                popupClassName:        undefined,
                                dropdownClassName:     undefined,
                                showOpenOptionOnHover: true,
                                defaultTargetOption:   '_self',
                                options:               ['link', 'unlink'],
                                // link: { icon: link, className: undefined },
                                // unlink: { icon: unlink, className: undefined },
                                // linkCallback: undefined
                            },
                            image:   {
                                // icon:             image,
                                className:        undefined,
                                component:        undefined,
                                popupClassName:   undefined,
                                urlEnabled:       true,
                                uploadEnabled:    true,
                                alignmentEnabled: true,
                                uploadCallback:   ( ( file ) => {
                                    return new Promise( ( resolve, reject ) => {

                                        const formData = new FormData();
                                        formData.append( "file", file );

                                        axios.post( apiURL + '/editor-upload', formData, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }
                                        } )
                                            .then( ( response ) => {
                                                resolve( {data: {link: response.data.file}} );
                                            } )
                                    } );
                                } ),
                                previewImage:     true,
                                inputAccept:      'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                alt:              {present: false, mandatory: false},
                                defaultSize:      {
                                    height: '100',
                                    width:  'auto',
                                },
                            },
                            remove:  {
                                // icon: eraser,
                                // className: undefined,
                                // component: undefined
                            },
                            history: {
                                inDropdown:        false,
                                className:         undefined,
                                component:         undefined,
                                dropdownClassName: undefined,
                                options:           ['undo', 'redo'],
                                // undo:              {icon: undo, className: undefined},
                                // redo:              {icon: redo, className: undefined},
                            },
                        }
                    }
                    stripPastedStyles={true}
                    editorState={editorState}
                    onEditorStateChange={( s ) => {
                        props.editable && setEditorState( s )
                    }}
                />

                {
                    props.editable && (
                        <Upload
                            url={apiURL + `/task/${props.task.id}/file`}
                            files={props.files}
                            setFiles={props.setFiles}
                            handleFilesAdd={( files ) => {

                            }}
                            handleFilesChange={( files ) => {
                                props.editable && props.setFiles( files )
                            }}
                            handleUpdatedTimestamp={(timestamp) => {
                                props.handleUpdate(timestamp);
                            }}
                        />
                    )
                }


                <div className="d--flex jc--fs">

                    {
                        props.editable && (
                            <button className={"btn btn--secondary btn--m btn--shadow"} onClick={( e ) => {
                                props.editable &&
                                props.handleSave( draftToHtml( convertToRaw( editorState.getCurrentContent() ) ), props.files );
                            }}>
                                {t( 'save' )}
                            </button>
                        )
                    }


                    <button className={"btn btn--text btn--m ml--1"} onClick={( e ) => {
                        props.handleClose()
                    }}>
                        {t( 'close' )}
                    </button>

                    {
                        props.conflict && (
                            <div className={'label label--danger label--l ml--2'}>{t( 'tasks_conflict' )}</div>
                        )
                    }
                </div>
            </div>

            <img src={"/img/bg-open_task.png"} className={"img--bg-full"}/>
        </div>
    )
}