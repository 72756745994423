import React from 'react';
import {useTranslation} from "react-i18next";


export default function Loader(props) {
    const {t} = useTranslation();
    return (
        <div className={"wrapper__content m--0a d--flex jc--sb fd--c"}>
            <h1 className={"heading--xl mb--3"}>{t('loading')}</h1>

            <img src={"/img/bg-app.png"} className={"dashboard__avatar"}/>

            <div className={"disclaimer d--flex"}>

                <div className="disclaimer__wrapper m--a d--flex jc--fs">

                    <div className="disclaimer__copyright d--flex as--c sm:text--center">
                        <p>{t('interreg_copyright')}</p>
                    </div>

                    <div className="disclaimer__logo mr--3 sm:mr--0 sm:text--center">
                        <img src={"/img/innoschool-logo.png"}/>
                    </div>

                    <div className="disclaimer__text d--flex as--c sm:text--center">
                        <p>{t('interreg_disclaimer')}</p>
                    </div>

                </div>

            </div>
        </div>
    )
}