import React from 'react';

export default function icon( props ) {
    return (
        <svg id="_001-lamp" data-name="001-lamp" xmlns="http://www.w3.org/2000/svg" width="80.955" height="103.455"
             viewBox="0 0 80.955 103.455">
            <path
                d="M71.183,85.951a2.019,2.019,0,0,0,2.553-1.284,23.39,23.39,0,0,1,45.594,7.347c0,9.966-4.464,15.921-8.051,20.706-2.609,3.48-4.863,6.486-4.863,10.132q0,.36-.009.714H97.964v-4.642a2.021,2.021,0,1,0-4.041,0v4.642H85.479q-.008-.354-.009-.714c0-3.647-2.253-6.652-4.863-10.133-2.642-3.524-5.636-7.519-7.12-13.195a2.02,2.02,0,1,0-3.909,1.022c1.685,6.443,5.074,10.964,7.8,14.6,2.265,3.022,4.055,5.409,4.055,7.709,0,.968.041,1.912.121,2.812v17.091c0,.054,0,.107.008.16,0,.013,0,.026,0,.039a2.033,2.033,0,0,0,.034.22v0a15.143,15.143,0,0,0,5.06,8.427,14.43,14.43,0,0,0,18.578,0,15.142,15.142,0,0,0,5.059-8.427v0a2.2,2.2,0,0,0,.034-.219c0-.013,0-.026,0-.039,0-.054.008-.107.008-.161V125.663c.08-.9.122-1.845.122-2.812,0-2.3,1.789-4.687,4.055-7.709,3.738-4.987,8.858-11.816,8.858-23.129A27.431,27.431,0,0,0,69.9,83.4,2.021,2.021,0,0,0,71.183,85.951Zm24.761,65a10.7,10.7,0,0,1-9.569-6.175h19.137A10.7,10.7,0,0,1,95.944,150.949ZM106.3,140.733h-20.7V136.19h20.7Zm0-8.584h-20.7v-4.543h20.7Zm0,0"
                transform="translate(-55.466 -51.536)"/>
            <path
                d="M192.345,9.425A2.02,2.02,0,0,0,194.365,7.4V2.021a2.021,2.021,0,0,0-4.041,0V7.4A2.02,2.02,0,0,0,192.345,9.425Zm0,0"
                transform="translate(-151.867)"/>
            <path d="M2.021,194.365H7.4a2.021,2.021,0,0,0,0-4.041H2.021a2.021,2.021,0,0,0,0,4.041Zm0,0"
                  transform="translate(0 -151.867)"/>
            <path
                d="M173.042,212.548a2.02,2.02,0,0,0-2.021-2.021H156.56a2.021,2.021,0,0,0,0,4.041h5.21v4.642a2.021,2.021,0,1,0,4.041,0v-4.642h5.21A2.02,2.02,0,0,0,173.042,212.548Zm0,0"
                transform="translate(-123.313 -167.988)"/>
            <path d="M60.144,63A2.02,2.02,0,1,0,63,60.145l-3.807-3.807A2.021,2.021,0,0,0,56.336,59.2Zm0,0"
                  transform="translate(-44.481 -44.482)"/>
            <path
                d="M50.867,320.809a2.014,2.014,0,0,0,1.429-.592L56.1,316.41a2.02,2.02,0,1,0-2.857-2.857l-3.808,3.807a2.021,2.021,0,0,0,1.429,3.449Zm0,0"
                transform="translate(-38.977 -249.724)"/>
            <path
                d="M308.083,63.592A2.014,2.014,0,0,0,309.511,63l3.807-3.807a2.021,2.021,0,0,0-2.857-2.858l-3.807,3.807a2.021,2.021,0,0,0,1.429,3.45Zm0,0"
                transform="translate(-244.219 -44.48)"/>
            <path
                d="M313.553,313.553a2.02,2.02,0,0,0,0,2.857l3.807,3.807a2.02,2.02,0,0,0,2.857-2.857l-3.807-3.807A2.02,2.02,0,0,0,313.553,313.553Zm0,0"
                transform="translate(-249.724 -249.724)"/>
            <path d="M356.025,194.365h5.384a2.021,2.021,0,0,0,0-4.041h-5.384a2.021,2.021,0,0,0,0,4.041Zm0,0"
                  transform="translate(-282.474 -151.867)"/>
            <path d="M192.345,283.5a2.021,2.021,0,1,0,2.021,2.021A2.025,2.025,0,0,0,192.345,283.5Zm0,0"
                  transform="translate(-151.867 -226.216)"/>
            <path d="M68.627,192.345a2.021,2.021,0,1,0-2.021,2.021A2.025,2.025,0,0,0,68.627,192.345Zm0,0"
                  transform="translate(-51.536 -151.867)"/>
        </svg>
    )
}
