import React, {useState, useRef, useEffect} from 'react';
import * as axios from "axios";
import store from "../../index";
import {flashOpen} from "../../Store/actions/flashActions";
import {useTranslation} from "react-i18next";


export default function Dropzone( props ) {
    const {t} = useTranslation();

    const [draging, setDraging] = useState( false );
    const [loading, setLoading] = useState( false );
    const fileInputRef = useRef( null );
    const dropZoneRef = useRef( null );

    const handleUpload = ( files ) => {
        const formData = new FormData();

        for( let i = 0; i < files.length; i++ ){
            formData.append('files[' + i + ']', files[i]);
        }

        axios.post( props.url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
            .then( response => {
                props.handleFilesAdd(response.data);
            } )
            .catch( errors => {
                console.log( errors )
                store.dispatch(flashOpen({status: 'danger', text: t('upload-failed')}));
            } )
    }

    const fileListToArray = ( list ) => {
        const array = [];
        for ( var i = 0; i < list.length; i++ ) {
            array.push( list.item( i ) );
        }
        return array;
    };

    const handleDrag = ( e ) => {
        e.preventDefault()
        e.stopPropagation()
    };

    const handleDragIn = ( e ) => {
        e.preventDefault();
        e.stopPropagation();

        if ( e.dataTransfer.items && e.dataTransfer.items.length > 0 ) {
            setDraging( true );
        }
    };

    const handleDragOut = ( e ) => {
        e.preventDefault();
        e.stopPropagation();
        setDraging( false );
    };

    const handleDrop = ( e ) => {
        e.preventDefault();
        e.stopPropagation();

        setDraging( false );

        if ( e.dataTransfer.files && e.dataTransfer.files.length > 0 ) {
            handleUpload( e.dataTransfer.files );
        }
    };

    useEffect( () => {
        const {current} = dropZoneRef;

        current.addEventListener( 'dragenter', handleDragIn );
        current.addEventListener( 'dragleave', handleDragOut );
        current.addEventListener( 'dragover', handleDrag );
        current.addEventListener( 'drop', handleDrop );

        return () => {
            current.removeEventListener( 'dragenter', handleDragIn );
            current.removeEventListener( 'dragleave', handleDragOut );
            current.removeEventListener( 'dragover', handleDrag );
            current.removeEventListener( 'drop', handleDrop );
        }
    }, [dropZoneRef] );


    return (
        <div
            ref={dropZoneRef}
            className="dropzone mb--3"
            onClick={() => {
                fileInputRef.current.click();
            }}
            style={{cursor: props.disabled ? "default" : "pointer"}}
        >

            <img src="img/upload-file.svg" alt="" className={"mb--2"}/>

            {
                loading ? (
                    'loading'
                ) : (
                    draging ? t('dnd_draging') : t('dnd_drop')
                )
            }
            <br/>
            <button className={"btn btn--s btn--white d--iblock mt--2"}>{t('dnd_browse')}</button>

            <input
                ref={fileInputRef}
                className="FileInput"
                type="file"
                multiple
                onChange={( e ) => {
                    if ( props.disabled ) return;
                    handleUpload( e.target.files );
                    // const files = fileListToArray( e.target.files );
                    //
                    // if ( typeof props.handleFilesAdd == 'function' ) {
                    //     props.handleFilesAdd( files );
                    // }

                }}
            />
        </div>
    )
}