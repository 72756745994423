import React, {Component} from 'react';
import SubmitButton from "./SubmitButton";
import * as axios from "axios";
import {apiURL} from "../../env";

class Form extends Component {
    constructor(props) {
        super(props);

        let values = props.inputs.reduce((values, input) => {
            values[input.props.name] = input.props.value !== undefined ? input.props.value : '';
            return values;
        }, {});

        console.log(values);

        this.state = {
            alert: null,
            errors: {},
            values: values
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.values = props.inputs.reduce((values, input) => {
            values[input.props.name] = state.values[input.props.name] ? state.values[input.props.name] : (input.props.value !== undefined ? input.props.value : '');
            return values;
        }, {});

        return state;
    }


    render() {
        const {values, errors, alert} = this.state;

        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                if (typeof this.props.handleSubmit == 'function') {
                    this.props.handleSubmit();
                }

                const data = Object.assign({locale: this.props.locale}, values);

                axios({
                    method: this.props.submitMethod,
                    url: this.props.submitUrl,
                    data: data
                })
                    .then((response) => {
                        if (typeof this.props.handleSubmitSuccess == 'function') {
                            this.props.handleSubmitSuccess(data, response);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {

                            if (error.response.status == 422) {
                                this.setState({
                                    errors: error.response.data.errors,
                                    alert: {
                                        status: 'error',
                                        text: error.response.data.message
                                    }
                                })
                            } else if (error.response.status == 401) {
                                this.setState({
                                    errors: {},
                                    alert: {
                                        status: 'error',
                                        text: error.response.data.message
                                    }
                                })
                            }
                        }

                        if (typeof this.props.handleSubmitError == 'function') {
                            this.props.handleSubmitError(error);
                        }
                    });
            }}>

                {
                    alert ? (
                        <div
                            className={"alert alert-" + alert.message ? alert.status : 'success'}>{alert.text}</div>
                    ) : null
                }

                {
                    this.props.inputs.map((input, key) => {
                        return (
                            <div key={key} className="input-container">
                                {
                                    React.cloneElement(input, {
                                        value: values[input.props.name],
                                        onChange: (e) => {
                                            const newValue = {};
                                            newValue[input.props.name] = e.target.value;

                                            this.setState({values: Object.assign({}, values, newValue)})
                                        }
                                    })
                                }

                            </div>
                        )
                    })
                }

                <SubmitButton label={this.props.submitLabel}/>
            </form>
        );

    }
}

export default Form;