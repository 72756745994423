import React from 'react';

export default function icon( props ) {
    return (
        <svg enable-background="new 0 0 800 800" height="800px" id="GUIDE" version="1.1" viewBox="0 0 800 800" width="800px"
             xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M776.557,119.544c0.357-21.353-7.375-41.042-21.771-55.438c-7.812-7.81-20.475-7.81-28.285,0.001s-7.811,20.474,0,28.284   c6.663,6.662,10.236,16.067,10.063,26.484c-0.166,9.938-3.928,19.534-10.063,25.669c-12.725,12.725-139.57,124.086-238.635,210.734   L463.586,331c72.32-82.747,162.068-185.064,197.172-223.914l7.986,7.987c7.811,7.811,20.475,7.812,28.284,0.002   c7.812-7.81,7.813-20.473,0.003-28.284L674.35,64.108c-3.75-3.751-8.838-5.859-14.142-5.859h-0.001   c-5.305,0-10.391,2.107-14.142,5.857c-12.171,12.17-86.618,96.725-153.792,173.381c-18.065-27.343-42.194-50.865-70.895-68.921   c-41.949-26.391-90.855-39.772-145.362-39.772c-11.991,0-23.854,0.756-35.257,2.249c-10.953,1.434-18.669,11.474-17.236,22.427   c1.434,10.952,11.473,18.668,22.426,17.235c9.689-1.268,19.805-1.911,30.066-1.911c82.994,0,152.612,37.208,188.714,100.146   c-33.688,38.495-62.632,71.651-76.59,87.649c-24.918,3.395-43.511,13.592-56.621,31.078   c-12.189,16.257-17.532,35.673-23.188,56.229c-6.82,24.786-13.872,50.416-33.983,70.526c-5.721,5.72-7.432,14.322-4.336,21.796   s10.389,12.347,18.478,12.347c53.825,0,96.486-11.473,126.799-34.099c27.317-20.391,43.6-49.341,47.45-84.105   c51.533-45.007,273.565-239.051,292.047-257.533C768.281,159.333,776.217,139.911,776.557,119.544z M457.684,381.666   c-6.01,5.251-11.836,10.341-17.438,15.232l-18.261-18.26c4.887-5.601,9.976-11.432,15.226-17.444L457.684,381.666z    M391.361,482.411c-15.732,11.744-36.668,19.57-62.509,23.403c8.793-17.681,13.726-35.608,18.045-51.307   c9.27-33.688,14.916-50.596,37.758-56.63l36.929,36.928C417.518,454.463,407.401,470.438,391.361,482.411z"/>
                <path
                    d="M178.311,189.959c2.913,0,5.872-0.64,8.671-1.989c1.795-0.866,3.639-1.717,5.479-2.528   c10.105-4.461,14.681-16.269,10.22-26.374c-4.461-10.104-16.268-14.68-26.374-10.22c-2.247,0.992-4.5,2.032-6.697,3.091   c-9.949,4.797-14.126,16.752-9.33,26.701C163.728,185.79,170.871,189.959,178.311,189.959z"/>
                <path
                    d="M322.839,570.789c-11.035-0.108-20.099,8.729-20.224,19.773c-0.863,76.887-12.47,111.188-78.964,111.188   c-37.276,0-76.396-27.269-107.328-74.813C82.216,574.51,63.432,506.443,63.432,435.274c0-92.393,27.9-169.269,78.561-216.465   c8.082-7.529,8.53-20.185,1.001-28.267s-20.185-8.53-28.267-1.001c-29.841,27.801-52.93,63.923-68.627,107.363   c-15.042,41.626-22.668,88.181-22.668,138.37c0,78.821,21.083,154.636,59.363,213.476c38.451,59.104,89.792,93.001,140.856,93.001   c48.841,0,82.882-17.187,101.175-51.082c15.706-29.104,17.416-66.701,17.786-99.657   C342.736,579.967,333.884,570.912,322.839,570.789z"/>
                <path
                    d="M421.257,274.597c0-36.74-29.891-66.631-66.631-66.631c-36.741,0-66.633,29.891-66.633,66.631   c0,36.741,29.892,66.633,66.633,66.633C391.366,341.229,421.257,311.338,421.257,274.597z M327.993,274.597   c0-14.685,11.947-26.631,26.633-26.631c14.685,0,26.631,11.946,26.631,26.631c0,14.686-11.946,26.633-26.631,26.633   C339.94,301.229,327.993,289.282,327.993,274.597z"/>
                <path
                    d="M195.826,365.177c36.741,0,66.632-29.891,66.632-66.631c0-36.741-29.891-66.632-66.632-66.632   s-66.632,29.891-66.632,66.632C129.194,335.286,159.085,365.177,195.826,365.177z M195.826,271.914   c14.685,0,26.632,11.947,26.632,26.632s-11.947,26.631-26.632,26.631s-26.632-11.946-26.632-26.631   S181.142,271.914,195.826,271.914z"/>
                <path
                    d="M211.417,451.638c0-36.741-29.891-66.633-66.632-66.633s-66.632,29.892-66.632,66.633c0,36.74,29.891,66.631,66.632,66.631   S211.417,488.378,211.417,451.638z M144.785,478.269c-14.685,0-26.632-11.946-26.632-26.631c0-14.686,11.947-26.633,26.632-26.633   s26.632,11.947,26.632,26.633C171.417,466.322,159.47,478.269,144.785,478.269z"/>
                <path
                    d="M214.792,674.175c36.741,0,66.633-29.891,66.633-66.631s-29.892-66.631-66.633-66.631   c-36.74,0-66.631,29.891-66.631,66.631S178.052,674.175,214.792,674.175z M214.792,580.913c14.686,0,26.633,11.946,26.633,26.631   s-11.947,26.631-26.633,26.631c-14.684,0-26.631-11.946-26.631-26.631S200.108,580.913,214.792,580.913z"/>
            </g>
        </svg>

    )
}
