import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

export default function LinkToLogin() {
    const {t, i18n} = useTranslation();

    return (
        <Link className={"btn btn--text btn--m text--black"} to="/login">
            <strong className={"text--underline"}>{t( 'auth_back_login' )}</strong>
        </Link>
    );
}