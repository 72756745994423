import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function SectionFinishedContent(props) {

    const {t} = useTranslation();

    return (
        <div className="modal__body modal__outro section__outro d--flex jc--c">


            <div className="modal__inner d--flex fd--c jc--sa w--60 sm:w--100 h--100">
                <div className={"text--center ml--2 mr--2"}>
                    <h2 className={"mb--3 sm:mb--2 text--black"}>{t( 'outro_modal_heading' )}</h2>
                    <p className={"mb--3 sm:mb--2"}><strong>{t( 'outro_modal_subheading' )}</strong></p>
                </div>
                <div className="outro__buttons d--flex fd--c ai--c mt--2 sm:fw--w">

                    <button onClick={() => props.handleContinue()}
                            className="btn btn--warning dark btn--m w--60 mb--1 sm:mr--0">{t( 'outro_modal_btn_keep_playing' )}
                    </button>
                    <button onClick={() => props.handleBackToMenu()}
                            className="btn btn--warning dark w--60 btn--m">{t( 'outro_modal_btn_back_menu' )}
                    </button>

                </div>

                <div className="modal__outro--bottom_btn d--flex">
                    <Link
                        exact={true}
                        to={"/office"}
                        className="btn btn--text featured w--100 text--center"
                    >
                        {t( 'outro_modal_btn_office' )}
                    </Link>

                </div>

            </div>


        </div>
    )
}