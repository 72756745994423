import React from 'react';
import {useTranslation} from "react-i18next";

export default function ImgLeft( props ) {
    const {t, i18n} = useTranslation();

    return (
        <div className="d--flex">
            <div className="modal__left w--40">
                <img className={"w--100"} src={"/img/scenarios/content/" + props.image} alt=""/>
            </div>

            <div className="modal__right d--flex modal__inner pb--0 pt--0 w--60 ai--c">
                <div className="modal__item m--a">
                    {
                        i18n.exists( `scenarios_${props.scenario}_presentation_${props.slide + 1}_heading` ) && (
                            <h4 className={"mb--3"}>{t( `scenarios_${props.scenario}_presentation_${props.slide + 1}_heading` )}</h4>
                        )
                    }

                    {
                        i18n.exists( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_1` ) && (
                            <p dangerouslySetInnerHTML={{__html: t( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_1` )}}/>
                        )
                    }
                    {
                        i18n.exists( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_2` ) && (
                            <p dangerouslySetInnerHTML={{__html: t( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_2` )}}/>
                        )
                    }
                    {
                        i18n.exists( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_3` ) && (
                            <p dangerouslySetInnerHTML={{__html: t( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_3` )}}/>
                        )
                    }
                    {
                        i18n.exists( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_4` ) && (
                            <p dangerouslySetInnerHTML={{__html: t( `scenarios_${props.scenario}_presentation_${props.slide + 1}_content_4` )}}/>
                        )
                    }
                </div>

            </div>
        </div>
    )
}