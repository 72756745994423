import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useTranslation} from "react-i18next";

export default function MarvinTable(props) {
    const bubbleDelayed = useBubbleDelay([props.marvin], props.marvin !== null);
    const {t, i18n} = useTranslation();

    console.log(`theory_${props.module}_slide_${props.slide}_marvin`, i18n.exists(`theory_${props.module}_slide_${props.slide}_marvin`));
    return (
        <div className={"theory-container"}>
            <div className={"h--100 marvin-table-column"}>

                <div className="w--50 h--100 p--rel sm:w--100 sm:o--2 sm:theory__table-fixed">
                    <div className="theory__table d--flex">

                        <div className="w--100 table__top d--flex jc--fe">
                            <img src="img/innoschool-logo.png" alt=""/>
                        </div>

                        {
                            i18n.exists(`theory_${props.module}_slide_${props.slide}_table_text`) && (
                                <p className={"text-theory--table w--100 m--a as--c"}
                                   dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_table_text`)}}/>
                            )
                        }

                        {
                            i18n.exists(`theory_${props.module}_slide_${props.slide}_table_heading`) && (
                                <h2 className={"text--xl m--a"}>{t(`theory_${props.module}_slide_${props.slide}_table_heading`)}</h2>
                            )
                        }
                        {
                            props.table_image && (
                                <img src={props.table_image} className={"table__img"} alt=""/>
                            )
                        }

                        <div className="w--100 table__bottom">
                            <p>{t( 'theory_table_disclaimer' )}</p>
                        </div>
                    </div>
                </div>

                <div className="w--50 p--rel sm:w--100 sm:o--1 sm:theory__table-center">
                    <div className="marvin-container sm:w--100">
                        <img src={props.marvin_image} className={"marvin__img" + (props.person_small ? " s" : "")} alt=""/>

                        {
                            bubbleDelayed && i18n.exists(`theory_${props.module}_slide_${props.slide}_marvin`) && (
                                <div className="text-bubble-theory right">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_marvin`)}}/>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}