import React from 'react';
import {apiURL} from "../../../env";
import Form from "../../Form/Form";
import {useTranslation} from "react-i18next";

const FirstPasswordForm = (props) => {

    const {t, i18n} = useTranslation();
    return (
        <Form
            locale={i18n.language}
            submitLabel={t( 'submit' )}
            submitMethod={"post"}
            submitUrl={apiURL + '/set-password/team/' + props.code + '/set'}
            handleSubmitSuccess={(data, response) => {
                props.handleSuccess(parseInt(response.data) === 1);
            }}
            handleSubmitError={(response) => {
                console.error(response);
            }}
            inputs={[
                <input className={"input__item mb--1 w--100"}
                       placeholder={t( 'auth_password' )} name="password" type="password"/>,
                <input className={"input__item mb--1 w--100"}
                       placeholder={t( 'auth_password_again' )} name="password_confirmation"
                       type="password"/>
            ]}
        />
    );
};

export default FirstPasswordForm;
