export const CREDIT_SPEND = 'CREDIT_SPEND';
export const CREDIT_SET = 'CREDIT_SET';
export const SUPPORTERS_SET = 'SUPPORTERS_SET';
export const BADGES_COUNT_SET = 'BADGES_COUNT_SET';
export const PROGRESS_SET = 'PROGRESS_SET';

export const creditSpend = ( value ) => ( {
    type: CREDIT_SPEND,
    value,
} );

export const creditSet = ( value ) => ( {
    type: CREDIT_SET,
    value,
} );

export const supportersSet = ( value ) => ( {
    type: SUPPORTERS_SET,
    value,
} );

export const badgesCountSet = ( value ) => ( {
    type: BADGES_COUNT_SET,
    value,
} );

export const progressSet = ( value ) => ( {
    type: PROGRESS_SET,
    value,
} );