export const intro_screens = [
    {
        marvine: 'marvin-friends1.svg',
        layout: 'top',
        background: 'bg-intro.png'
    },
    {
        marvine: 'characters/woman-afro_hand.svg',
        person: 'characters/man-wheelchair_neutral.svg',
        person_small: true,
        layout: 'dialog',
        background: 'bg-intro_2.png'
    },
    {
        marvine: 'characters/woman-juicy_hand.svg',
        person: 'characters/man-old_pockets.svg',
        layout: 'dialog',
        background: 'bg-intro_3.png'
    },
    {
        marvine: 'marvin-friends2.svg',
        layout: 'top',
        background: 'bg-intro.png'
    },
    {
        marvine: 'characters/man-wheelchair_neutral.svg',
        person_small: true,
        layout: 'left',
        background: 'bg-intro.png'
    },
    {
        marvine: 'characters/man-old_pockets.svg',
        layout: 'left',
        background: 'bg-intro.png'
    },
    {
        marvine: 'characters/woman-juicy_pointing.svg',
        layout: 'left',
        background: 'bg-intro.png'
    },
    {
        marvine: 'marvin-showing.svg',
        layout: 'left',
        background: 'bg-intro.png'
    },
    {
        marvine: 'characters/woman-afro_pointing.svg',
        layout: 'right',
        background: 'bg-intro_nice.png'
    },
    {
        marvine: 'characters/man-old_pockets.svg',
        layout: 'right',
        background: 'bg-intro_nice.png'
    },
    {
        marvine: 'characters/woman-juicy_ok.svg',
        layout: 'left',
        background: 'bg-intro_nice.png'
    },
    {
        marvine: 'characters/woman-afro_pointing_hands.svg',
        layout: 'left',
        background: 'bg-intro_nice.png'
    },
    {
        marvine: 'marvin-thinking.svg',
        layout: 'right',
        background: 'office/office-bg.jpg'
    },
    {
        marvine: 'characters/man-wheelchair_neutral.svg',
        person_small: true,
        layout: 'right',
        background: 'office/office-bg.jpg'
    },
    {
        marvine: 'characters/man-old_showing.svg',
        layout: 'right',
        background: 'office/office-bg.jpg'
    },
    {
        marvine: 'characters/woman-juicy_thinking.svg',
        layout: 'right',
        background: 'office/office-bg.jpg'
    }
]