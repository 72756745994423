import React from 'react';
import {useTranslation} from "react-i18next";

export default function AnswerExplanationModal({correct, text, handleClose}) {

    const {t} = useTranslation();

    return (

        <>

            <div className="backdrop info"></div>

            <div className="modal-wrapper">
                <div className="modal modal--l modal__intro">
                    <div className="modal__body h--100 d--flex">
                        <div
                            className={`modal__left ${correct ? 'bg--correct' : 'bg--incorrect'} w--60 sm:w--100 h--100 m:h--a`}>
                            <div className="modal__inner">
                                <div className="mb--1">
                                    <h2 className="mb--3 sm:mb--2">{ correct ? t("quizff_modal_heading_good") : t("quizff_modal_heading_bad")}</h2>
                                    <p className="mb--2 sm:mb--2" dangerouslySetInnerHTML={{__html: (correct ? text : text)}}/>
                                </div>

                                <button
                                    className="btn btn--m btn--white nb btn--shadow mt--3 sm:mt--2"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    {t( 'quizff_modal_btn_gotit' )}
                                </button>
                            </div>
                        </div>
                        <div className="modal__right w--40 sm:d--none h--100">
                            <div className="modal__inner d--flex h--100">
                                <img className={"m--a h--100 w--100"}
                                     src={`/img/${(correct ? 'marvin-ok_smile.svg' : 'marvin-showing_sad.svg')}`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}