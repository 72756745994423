import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {gameModulesLoad} from "../../../Store/actions/gameActions";
import {authTeamLoaded} from "../../../Store/actions/authActions";
import {notificationsLoaded} from "../../../Store/actions/notificationsActions";
import {set} from "immutable";

export default function InputBar({channel, onSubmit, uploading}) {
    const {t} = useTranslation();

    const [message, setMessage] = useState('');
    const [file, setFile]       = useState(null);

    const setSeenMessages = () => {
        console.log("setSeenMessages");
        axios({
            method: 'post',
            url: apiURL + `/chat/channels/${channel}/set-seen`,
        })
            .then((response) => {
                console.log("setSeenMessages - response");

                console.log(response.status)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const valid = (message != null && message.match(/^ *$/) === null || file !== null);
        if (valid) {
            onSubmit(message, setMessage, file, setFile);
        }
    }

    return (
        <form onSubmit={(e) => submitHandler(e)} className="discussion-submit">
            <div className="input-group">
                <input type="text"
                       id="chat-text-input"
                       placeholder={t('chat_input_placeholder')} value={message}
                       onFocus={() => setSeenMessages()}
                       onChange={(e) => setMessage(e.target.value)}
                />


                {
                    uploading ? (
                        <label className="btn btn--text">
                            <span className="spinner size-small"></span>
                        </label>
                    ) : (
                        <>
                            <label htmlFor="chat-upload-file" className="btn btn--text btn--light">
                                <span className="mdi mdi-image"></span>
                            </label>
                            <input id="chat-upload-file" style={{display: 'none'}} type="file"
                                   onChange={(e) => setFile(e.target.files[0])}/>
                        </>
                    )


                }


                {file !== null && (
                    <div onClick={() => setFile(null)} className="discussion-submit__file">
                        <span className={'text--dark text--s'}>{file !== null && (file.name)}</span>
                        <span className="mdi mdi-close"></span>
                    </div>
                )}


                <button type={'submit'} className="btn btn--s btn--secondary">

                    <span className={'hidden--xs'}>{t('send')}</span>
                    <img className={'display--xs'} src="../img/send.svg" alt=""/>
                </button>


            </div>


        </form>
    );
}