import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function OpenTaskEvaluation({time, openTaskEvaluation}) {
    const {t} = useTranslation();

    return (

        <a href="#" className={"d--flex ai--fs"}>
            <img src="img/notifications/notif-level.svg" alt=""/>
            {t('module_ulock_congrats', {
                time,
                module: openTaskEvaluation.module
            })}</a>
    )
}