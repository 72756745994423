import React from 'react';

const SubmitButton = (props) => {
    return (
        <button
            className={"btn btn--secondary btn--l mt--2 btn-container dark btn--shadow"}
            type="submit"
        >
            {props.label}
        </button>
    );
};

export default SubmitButton;
