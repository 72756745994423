import React, {useState} from 'react';
import {useDrop} from "react-dnd";
import Word from "./Word";

export default function WordsTop( {words, onDrop, handleWordClick} ) {
    const [{isOver, canDrop}, drop] = useDrop( {
        accept:  ['word-connected'],
        drop:    onDrop,
        collect: ( monitor ) => ( {
            isOver:  monitor.isOver(),
            canDrop: monitor.canDrop(),
        } ),
    } )

    return (
        <div ref={drop} className="connect-item__top d--flex fw--w ai--fs w--100" style={{minHeight: '60px'}}>
            {
                words.map( ( l, i ) => (
                    <Word
                        key={i}
                        word={l}
                        type={'word'}
                        handleClick={() => {
                            handleWordClick( l, i )
                        }}
                    />
                ) )
            }
        </div>
    )
}