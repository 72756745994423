import React, {useEffect, useState} from 'react';
import * as axios from "axios";
import {apiURL} from "../../../../../../env";
import QuizCloseButton from "../../QuizCloseButton";
import {useInterval} from "../../../../../../Hooks/useInterval";
import {useTranslation} from "react-i18next";

function FillQuestion( props ) {
    const [activeField, setActiveField] = useState( null );
    const [texts, setConnected] = useState( props.texts );
    const [fills, setFills] = useState( props.fills );
    const [selected, setSelected] = useState( props.answers ? props.fills.map( ( f ) => props.answers.options.filter( ( o ) => o.select_id === f.id ).pop().option_id ) : ( new Array( props.fills.length ) ).fill( null ) )

    const [duration, setDuration] = useState( props.time );

    const {t} = useTranslation();

    useEffect( () => {
        setConnected( props.texts );
        setFills( props.fills );
        setSelected( props.answers ? props.fills.map( ( f ) => props.answers.options.filter( ( o ) => o.select_id === f.id ).pop().option_id ) : ( new Array( props.fills.length ) ).fill( null ) )
        setDuration( props.time );
    }, [props.texts, props.fills, props.answers, props.time] )

    useInterval( () => {
        setDuration( duration + 1 );
    }, 1000 );

    let components = [];
    for ( let i = 0; i < texts.length; i++ ) {
        components.push(
            <div className={"fill__item mr--1 mb--2"} key={i * 2}><span>{texts[ i ].text}</span></div>
        );

        if ( fills[ i ] !== undefined ) {

            let className = '';

            const correct = fills[ i ].options.filter( ( e ) => e.correct === true ).pop();
            if ( correct && selected[ i ] !== null ) {
                if ( correct.id === selected[ i ] ) className = 'correct';
                else className = 'incorrect';
            }

            components.push(
                <div
                    key={i * 2 + 1}
                    className={"mr--1 mb--2 fill__item fill__select " + className + " " + ( selected[ i ] == null ? '' : 'selected' ) + ( activeField === ( i * 2 + 1 ) ? ' active' : '' )}
                    onClick={() => {
                        setActiveField( i * 2 + 1 );
                    }}
                >
                    {
                        selected[ i ] === undefined || selected[ i ] === null ?
                            t( 'select_option' )
                            :
                            fills[ i ].options.filter( ( e ) => e.id === selected[ i ] ).pop().text
                    }

                    <span className={"mdi mdi-chevron-down"}></span>
                    <div className="dropdown">
                        {
                            fills[ i ].options.map( ( o ) => (
                                <div
                                    key={o.id}
                                    className={"dropdown__item" + ( selected[ i ] === o.id ? ' active' : '' )}
                                    onClick={( e ) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setActiveField( null );
                                        setSelected( selected.map( ( e, j ) => ( j === i ? o.id : e ) ) );
                                    }}
                                >
                                    {o.text}
                                </div>
                            ) )
                        }
                    </div>
                </div>
            );
        }
    }

    const timeRemaining = Math.max(0, props.maxTime - duration);
    const timeProgress = Math.round( ( timeRemaining / props.maxTime ) * 100 );

    return (
        <div
            className={"d--iflex fd--c ai--fs question-container" + ( props.className ? ( ' ' + props.className ) : '' ) + ( props.background ? ( ' bg-' + props.background ) : ' bg-default' )}>

            <QuizCloseButton handeClick={() => props.handleClose()}/>


            <div className="question-header-container mb--3 sm:mb--1 w--100">

                <div className="d--flex jc--sb mb--3 ai--c sm:fw--w sm:mb--1">
                    <h1 className={"question__quiz"}>{t( 'quiz_time_heading' )}</h1>
                </div>

                <h2 className={"question__heading d--iblock mb--1"}>{props.question}</h2>
                <span className={"question__subheading d--block"}>{props.text}</span>


            </div>

            <div className="d--flex jc--fs fw--w m--2 ml--0 mr--0 fill-item-container ai--fs w--100">
                {components}
            </div>

            <div className="btn-container quiz d--flex jc--fe sm:jc--fs m:jc--fs">
                {
                    props.invalid && (
                        <div className={"invalid--answer"}>
                            <span className="mdi mdi-alert mr--1"></span>
                            {t( 'quiz_invalid_answer' )}</div>
                    )
                }
                <button className={"btn btn--danger btn--m mr--2 btn--shadow"} onClick={( e ) => {
                    props.handlePrev({
                        duration
                    })
                }}>
                    {t( 'back' )}
                </button>
                <button className={"btn btn--success btn--m btn--shadow"} onClick={( e ) => {
                    props.handleNext( {
                        options: selected.map( ( s, i ) => ( {
                            select_id: fills[ i ].id,
                            option_id: s,
                        } ) ),
                        duration
                    } );
                }}>
                    {t( 'next' )}
                </button>
            </div>

            <div className="quiz-slider">
                <div className="slider__top slider__time text--center d--flex">
                    <div className="time__container m--a">{timeRemaining}<br/><span>{t( 'quiz_second_short' )}</span>
                    </div>
                </div>
                <div className="slider__center">

                    <div
                        className={"slider__progress vertical " + ( timeRemaining > 10 ? 'success' : 'danger' )}>
                        <div
                            className="progress__fill"
                            style={{
                                height: `${timeProgress}%`
                            }}/>
                    </div>

                    {
                        timeProgress > 0 && (
                            <div
                                className="slider__points d--flex"
                                style={{
                                    top: `${100 - timeProgress}%`
                                }}
                            >
                                <span className={"mr--1"}>{props.bonus}</span>
                                <img src="img/coin.svg" alt=""/>
                            </div>
                        )
                    }
                </div>
                <div className="slider__bottom slider__time text--center d--flex">
                    <div className="time__container m--a">0<br/><span>{t( 'quiz_second_short' )}</span></div>
                </div>
            </div>
        </div>
    )
}

export default FillQuestion;