import {CREDIT_SPEND, CREDIT_SET, SUPPORTERS_SET, BADGES_COUNT_SET, PROGRESS_SET} from "../actions/creditActions";

const initialState = {
    credit:       100,
    supporters:   10,
    badges_count: 0,
    progress:     0,
};

export const CreditReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case CREDIT_SPEND:
            return Object.assign( {}, state, {
                credit: state.credit - action.value,
            } );

        case CREDIT_SET:
            return Object.assign( {}, state, {
                credit: action.value,
            } );

        case SUPPORTERS_SET:
            return Object.assign( {}, state, {
                supporters: action.value,
            } );

        case BADGES_COUNT_SET:
            return Object.assign( {}, state, {
                badges_count: action.value,
            } );

        case PROGRESS_SET:
            return Object.assign( {}, state, {
                progress: action.value,
            } );

        default:
            return state;
    }
};