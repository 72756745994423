import React from "react";

export default function InfoPanel({text, classNames, style}) {
    return (
        <div className={'info-panel-container'}>
            <div className={"info-panel " + (classNames ? classNames.map((className) => className + " ") : "")}
                 style={style}
            >
                <div>
                    <span className={"info-icon"}>i</span>
                </div>
                <p>
                    {text}
                </p>
            </div>
        </div>);


}