import React, {useState} from 'react';
import * as axios from "axios";
import {backendURL, apiURL} from "../../../env";
import store from "../../../index";
import {authTeamLoaded, authTeamProfilLoaded} from "../../../Store/actions/authActions";
import {connect} from "react-redux";
import {flashCreate, flashOpen} from "../../../Store/actions/flashActions";
import {useTranslation} from "react-i18next";

function General(props) {
    const {t} = useTranslation();

    const [name, setName] = useState(props.auth.team.game_name);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    return (
        <div className="settings-item card">
            <h2 className={"mb--3"}>{t('settings_general')}</h2>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    axios.post(apiURL + '/settings/general', {name})
                        .then((response) => {
                            console.log(response);
                            setError(null);
                            // TODO - notifications: false parameter added 10.09. to fix #709 - 7) by Marcel
                            store.dispatch(authTeamLoaded(response.data.team, false));
                            props.dispatch(flashOpen({
                                status: 'success',
                                text: t('settings_general_flash_success')
                            }));
                        })
                        .catch((error) => {
                            setError(null);
                            if (error.response.status === 422) {
                                setError(error.response.data);
                            } else {
                                props.dispatch(flashOpen({status: 'danger', text: t('settings_flash_fail')}));
                            }
                        })
                }}
            >
                {
                    loading ? (
                        <div style={{display: 'flex'}}>

                            Loading...
                        </div>
                    ) : (
                        <div>
                            <h3 className={"mb--2"}>{t('settings_picture')}</h3>


                            <div className="img--avatar">
                                <img
                                    src={props.auth.team.profile_image ? (backendURL + '/' + props.auth.team.profile_image) : "/profile.png"}
                                    alt="Profile Picture"
                                />
                            </div>
                        </div>
                    )
                }

                <input type="file" id="file" className={"inputfile"} name="file" onChange={(e) => {
                    setLoading(true);
                    const data = new FormData()
                    data.append('file', e.target.files[0])

                    axios.post(apiURL + '/team/picture', data)
                        .then((response) => {
                            console.log(response.data);
                            setLoading(false);
                            props.dispatch(authTeamProfilLoaded(response.data.profile_image));
                        })

                }}/>
                <label className={"btn btn--s btn--primary mt--2"} htmlFor="file">{t('settings_choose_file')}</label>
                <hr className={"mt--2 mb--2"}/>

                {
                    error !== null && (
                        <div className="alert alert--danger">
                            {error.message}
                            <ul>
                                {Object.keys(error.errors).map((input, i) => (
                                    <li key={i}>{error.errors[input]}</li>
                                ))}
                            </ul>
                        </div>
                    )
                }


            </form>
            {/*<form*/}
            {/*    onSubmit={(e) => {*/}
            {/*        e.preventDefault();*/}

            {/*        axios.post(apiURL + '/settings/general', {name})*/}
            {/*            .then((response) => {*/}
            {/*                console.log(response);*/}
            {/*                setError(null);*/}
            {/*                store.dispatch(authTeamLoaded(response.data.team));*/}
            {/*                props.dispatch(flashOpen({*/}
            {/*                    status: 'success',*/}
            {/*                    text: t('settings_general_flash_success')*/}
            {/*                }));*/}
            {/*            })*/}
            {/*            .catch((error) => {*/}
            {/*                setError(null);*/}
            {/*                if (error.response.status === 422) {*/}
            {/*                    setError(error.response.data);*/}
            {/*                } else {*/}
            {/*                    props.dispatch(flashOpen({status: 'danger', text: t('settings_flash_fail')}));*/}
            {/*                }*/}
            {/*            })*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div className="input-container mb--3">*/}

            {/*        <h3 className={"mb--2"}>{t('settings_nickname')}</h3>*/}
            {/*        <input className="input__item m w--100" value={name}*/}
            {/*               onChange={(e) => setName(e.target.value)}></input>*/}
            {/*    </div>*/}

            {/*    {*/}
            {/*        error !== null && (*/}
            {/*            <div className="alert alert--danger">*/}
            {/*                {error.message}*/}
            {/*                <ul>*/}
            {/*                    {Object.keys(error.errors).map((input, i) => (*/}
            {/*                        <li key={i}>{error.errors[input]}</li>*/}
            {/*                    ))}*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    }*/}

            {/*   /!* <p className={"text--s mb--3 mt--2"}>{t('settings_general_desc')}</p>*!/*/}
            {/*    <button type="submit"*/}
            {/*            className="btn btn--m btn--secondary mt--3 w--100">{t('settings_general_btn_submit')}</button>*/}
            {/*</form>*/}
        </div>
    )
}

function mapStateToProps(state) {
    const {auth} = state;

    return {auth}
}

export default connect(
    mapStateToProps
)(General);