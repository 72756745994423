import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_SCENARIO,
    AUTH_TEAM_LOADED,
    AUTH_TEAM_BADGE_OPEN,
    AUTH_TEAM_BADGE_CLOSE,
    AUTH_TEAM_DIPLOMA_OPEN,
    AUTH_TEAM_DIPLOMA_CLOSE,
    AUTH_TEAM_OPEN_TASK_EVALUATION_OPEN,
    AUTH_TEAM_OPEN_TASK_EVALUATION_CLOSE,
    AUTH_TEAM_PROFILE_LOADED,
    AUTH_TEAM_MODULE_UNLOCKED_OPEN,
    AUTH_TEAM_MODULE_UNLOCKED_CLOSE,
    AUTH_TEAM_CANVAS_AND_FILES_OPEN,
    AUTH_TEAM_CANVAS_AND_FILES_CLOSE,
    AUTH_TEAM_OPEN_TASK_STORY_OPEN,
    AUTH_TEAM_OPEN_TASK_STORY_CLOSE,
} from "../actions/authActions";

const initialState = {
    authenticated: localStorage.getItem( 'access_token' ) !== null,
    token:         localStorage.getItem( 'access_token' ),
    provider:      localStorage.getItem( 'guard_provider' ),
    team:          null
};


export const AuthReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case AUTH_LOGIN:
            return Object.assign( {}, state, {
                authenticated: true,
                token:         action.token,
                provider:      action.provider,
                team:          action.team,
                student:       action.student,
            } );

        case AUTH_SCENARIO:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    scenario: action.scenario
                } )
            } );

        case AUTH_LOGOUT:
            return Object.assign( {}, state, {
                authenticated: false,
                token:         null,
                provider:      null,
                team:          null,
                student:       null,
            } );

        case AUTH_TEAM_LOADED:
            if ( state.team === null ) {
                return Object.assign( {}, state, {
                    team: action.team,
                    student: action.student,
                } );
            } else {
                action.team.badges = state.team.badges.concat( action.team.badges );
                action.team.diplomas = state.team.diplomas.concat( action.team.diplomas );
                //TODO - condition added 10.09. to fix #709 - 7) by Marcel
                // if (action.notification){
                // console.log('aaa', action.team.module_unlocked_notifications);
                // console.log('bbb', state.team.module_unlocked_notifications);
                // console.log('ccc', state.team.module_unlocked_notifications.concat( action.team.module_unlocked_notifications ));
                // if()
                    action.team.module_unlocked_notifications = state.team.module_unlocked_notifications.concat( action.team.module_unlocked_notifications );
                // console.log('ddd', action.team.module_unlocked_notifications);
                // }
                action.team.open_task_evaluations = state.team.open_task_evaluations.concat( action.team.open_task_evaluations );

                return Object.assign( {}, state, {
                    team: Object.assign( {}, state.team, action.team )
                } );
            }

        case AUTH_TEAM_PROFILE_LOADED:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {profile_image: action.profile} )
            } );

        case AUTH_TEAM_BADGE_OPEN:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    badges: state.team.badges.concat( action.badge )
                } )
            } );

        case AUTH_TEAM_BADGE_CLOSE:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    badges: state.team.badges.filter( ( badge ) => badge.type !== action.badge.type )
                } )
            } );

        case AUTH_TEAM_DIPLOMA_OPEN:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    diplomas: state.team.diplomas.concat( action.diploma )
                } )
            } );

        case AUTH_TEAM_DIPLOMA_CLOSE:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    diplomas: state.team.diplomas.filter( ( diploma ) => diploma.type !== action.diploma.type )
                } )
            } );

        case AUTH_TEAM_MODULE_UNLOCKED_OPEN:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    module_unlocked_notifications: state.team.module_unlocked_notifications.concat( action.module_unlocked_notification )
                } )
            } );

        case AUTH_TEAM_MODULE_UNLOCKED_CLOSE:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    module_unlocked_notifications: state.team.module_unlocked_notifications.filter( ( notifcation ) => notifcation.type !== action.module_unlocked_notification )
                } )
            } );

        case AUTH_TEAM_OPEN_TASK_EVALUATION_OPEN:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    open_task_evaluations: state.team.open_task_evaluations.concat( action.openTaskEvaluation )
                } )
            } );

        case AUTH_TEAM_OPEN_TASK_EVALUATION_CLOSE:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    open_task_evaluations: state.team.open_task_evaluations.filter( ( evaluation ) => evaluation.module !== evaluation.module )
                } )
            } );

        case AUTH_TEAM_CANVAS_AND_FILES_OPEN:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    canvas_and_files: true
                } )
            } );

        case AUTH_TEAM_CANVAS_AND_FILES_CLOSE:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    canvas_and_files: null
                } )
            } );

        case AUTH_TEAM_OPEN_TASK_STORY_OPEN:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    open_task_story: Object.assign( action.openTask, {level: action.level, index: action.index} )
                } )
            } );

        case AUTH_TEAM_OPEN_TASK_STORY_CLOSE:
            return Object.assign( {}, state, {
                team: Object.assign( {}, state.team, {
                    open_task_story: null
                } )
            } );

        default:
            return state;
    }
};