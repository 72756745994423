import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useTranslation} from "react-i18next";

export default function MarvinTableColumns(props) {
    const bubbleDelayed = useBubbleDelay([props.marvin]);
    const {t} = useTranslation();

    return (
        <div className={"theory-container"}>
            <div className={"h--100 marvin-table-column"}>


                <div className="w--50 h--100 p--rel sm:w--100 sm:o--2 sm:theory__table-fixed">
                    <div className="theory__table d--flex">

                        <div className="w--100 table__top d--flex jc--fe">
                            <img src="img/innoschool-logo.png" alt=""/>
                        </div>


                        <div className={"w--40 sm:w--30 d--flex ai--c"}>
                            <img src={props.table_image} className={"w--100 mh--100 sm:h--100 sm:w--a"} alt=""/>
                        </div>


                        <p className={"text-theory--table w--60 sm:w--70 m--a ml--1 as--c"}
                           dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_table_text`)}}/>



                        <div className="w--100 table__bottom">
                            <p>{t( 'theory_table_disclaimer' )}</p>
                        </div>


                    </div>
                </div>

                <div className="w--50 p--rel sm:w--100 sm:o--1 sm:theory__table-center">
                    <div className="marvin-container sm:w--100">
                        <img src={props.marvin_image} className={"marvin__img"} alt=""/>
                        {
                            bubbleDelayed && (
                                <div className="text-bubble-theory right">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_marvin`)}}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>


            </div>
        </div>
    )
}