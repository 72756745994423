import React from 'react';
import {useTranslation} from "react-i18next";

export default function Person1(props) {
    const {t, i18n} = useTranslation();


    return (
        <>
            <h1 className={"text--center cs--theory"}>{t(`scenarios_${props.scenario}_name`)}</h1>
            <div className="theory-container">

                <img src={"/img/" + props.backgroundImage} className={"img--bg-full"}/>
                <div className="marvin h--100">
                    <div className="w--100 h--100 p--rel sm:o--1 d--flex jc--c sm:theory__table-center">
                        <div className="marvin-container sm:w--100">
                            <img src={"/img/case-studies/characters/" + props.pers_image} alt=""
                                 className="marvin__img"/>
                            <div className="text-bubble-theory m left">
                                <div className="bubble__inner">
                                    <p dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_pers_text`)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}