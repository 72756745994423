import {
    GAME_MODULES_RESET,
    GAME_MODULE_UNLOCK,
    GAME_MODULE_OPEN,
    GAME_MODULE_CLOSE,

    GAME_SECTION_UNLOCK,
    GAME_SECTION_OPEN,
    GAME_SECTION_FINISHED,
    GAME_SECTION_NEXT,
    GAME_SECTION_UNLOCK_NEXT,
    GAME_MODULES_LOAD,
    GAME_LOGOUT,
} from "../actions/gameActions";

const modules = (new Array(7)).fill(null);

const initialState = {
    scenario:      null,
    module:        null,
    section:       null,
    page:          null,
    moduleStatus:  modules.map( ( module, i ) => 'locked' ),
    sectionStatus: modules.map( ( module, i ) => {
        return [];
    } )
};


export const GameReducer = ( state = initialState, action ) => {
    let index;

    switch ( action.type ) {
        case GAME_MODULES_RESET:
            return initialState;

        case GAME_MODULES_LOAD:

            return Object.assign( {}, state, {
                moduleStatus:  state.moduleStatus.map( ( status, i ) => {
                    return action.modules[ i ].status;
                } ),
                sectionStatus: state.moduleStatus.map( ( status, i ) => {
                    return action.modules[ i ].sections;
                } ),
            } );

            return state;
            return Object.assign( {}, state, {
                openedModules:  state.openedModules.map( ( m, i ) => {
                    if ( typeof action.modules[ i ] !== "undefined" ) {
                        return action.modules[ i ];
                    }

                    return m;
                } ),
                openedSections: state.openedSections.map( ( m, i ) => {
                    if ( typeof action.modules[ i ] !== "undefined" ) {
                        let tmp = {};

                        Object.keys( m ).map( ( s, j ) => {
                            tmp[ s ] = true;
                        } );

                        return tmp;
                    }
                    return m;
                } )
            } );

        case GAME_MODULE_OPEN:
            return Object.assign( {}, state, {
                module:  action.module,
                section: action.section ? action.section : Object.keys( state.sectionStatus[ action.module ] )[ 0 ]
            } );

        case GAME_MODULE_CLOSE:
            return Object.assign( {}, state, {
                module:  null,
                section: null
            } );

        case GAME_SECTION_NEXT:
            // return state;


            const sections = Object.keys(state.sectionStatus[state.module]);

            index = sections.indexOf( state.section );


            if ( sections.length > index + 1 && state.sectionStatus[state.module][sections[index + 1]] !== 'locked') {
                return Object.assign( {}, state, {
                    section:          sections[ index + 1 ]
                } );
            }

            return Object.assign( {}, state, {
                module:  null,
                section: null
            } );

            return state;

        case GAME_SECTION_UNLOCK:
            return Object.assign( {}, state, {
                openedSections: setSectionLock( state, action.data.module, action.data.section, true )
            } );

        // case GAME_SECTION_UNLOCK_NEXT:
        //     index = gameModules[ state.module ].sections.indexOf( state.section );
        //
        //     if ( gameModules[ state.module ].sections.length > index + 1 ) {
        //         return Object.assign( {}, state, {
        //             openedSections:   setSectionLock( state, state.module, gameModules[ state.module ].sections[ index + 1 ], true ),
        //             finishedSections: setSectionFinished( state, state.module, state.section, true )
        //         } );
        //     }
        //
        //     return state;

        case GAME_SECTION_OPEN:
            return Object.assign( {}, state, {
                section: action.section
            } );

        case GAME_SECTION_FINISHED:
            return Object.assign( {}, state, {
                finishedSections: setSectionFinished( state, state.module, state.section, true )
            } );

        case GAME_LOGOUT:
            return Object.assign( {}, state, {
                scenario:      null,
                module:        null,
                section:       null,
                page:          null,
                moduleStatus:  modules.map( ( module, i ) => 'locked' ),
                sectionStatus: modules.map( ( module, i ) => {
                    return [];
                } )
            } );

        default:
            return state;
    }
};


const setModuleLock = ( state, module, value ) => {
    return state.openedModules.map( ( m, i ) => {
        return i === module ? value : m;
    } )
};

const setSectionLock = ( state, module, section, value ) => {
    return state.openedSections.map( ( m, i ) => {
        if ( i === module ) {
            let tmp = {};
            tmp[ section ] = value;
            return Object.assign( {}, m, tmp );
        }
        return m;
    } )
};

const setSectionFinished = ( state, module, section, value ) => {
    return state.finishedSections.map( ( m, i ) => {
        if ( i === module ) {
            let tmp = {};
            tmp[ section ] = value;
            return Object.assign( {}, m, tmp );
        }
        return m;
    } )
};

