import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {creditSpend} from "../../../../Store/actions/creditActions";
import InsufficientCredit from "./InsufficientCredit";
import BuyConfirmation from "./BuyConfirmation";
import {useTranslation, Trans} from "react-i18next";


function Category( props ) {
    const [items, setItems] = useState( null );
    const [page, setPage] = useState( 0 );
    const [pagesCount, setPagesCount] = useState( 0 );


    const {t} = useTranslation();


    useEffect( () => {
        const items = props.products
            .filter( ( p ) => ( p.category === props.category.id ) )
            .sort((a, b) => a.price - b.price);
        setItems( items );
        setPagesCount( Math.ceil( items.length / 4 ) );
        setPage( 0 );

    }, [props.category] );


    console.log({items});

    if ( !items ) return null;


    return (
        <div className="market__right">
            <h4 className={"market__category-name mb--3 m:mb--1"}>{t(`office_category_${props.category.id}_name`)}</h4>
            <div className="market-items d--flex fw--w">
                {
                    items.filter( ( p, i ) => ( i >= page * 4 && i < ( page + 1 ) * 4 ) ).map( ( item, i ) => (
                        <div key={i} className={"market__item d--flex" + ( props.unlocked.indexOf( item.id ) === -1 ? ' disabled' : '' )}>

                            <div className="item__inner d--flex">
                                <img src={`img/office/${item.image}`} className={"m--a"} alt=""/>

                                {
                                    props.unlocked.indexOf( item.id ) === -1 && (
                                        <span className={"mdi mdi-lock-outline"}></span>
                                    )
                                }


                                <div className="item__buttons d--flex jc--c">
                                    {
                                        props.purchased.indexOf( item.id ) === -1 ? (
                                            <button
                                                className={"btn btn--secondary"}
                                                onClick={() => {
                                                    if ( props.credit.credit < item.price ) {
                                                        props.openModal( <InsufficientCredit/> );
                                                    } else {
                                                        props.openModal( <BuyConfirmation
                                                            handleBuy={() => {
                                                                props.handleBuy( item );
                                                                // props.handlePurchase( item );
                                                                // props.handleUse( props.category, item );
                                                                // props.dispatch( creditSpend( item.price ) );
                                                            }}
                                                        /> );
                                                    }
                                                }}
                                            >
                                                {t( 'buy_for' )} <strong>{item.price} HERO</strong>
                                            </button>
                                        ) : (
                                            props.used.indexOf( item.id ) === -1 && (
                                                <button
                                                    className={"btn btn--transparent"}
                                                    onClick={() => {
                                                        props.handleUse( item );
                                                    }}
                                                >
                                                    {t( 'use' )}
                                                </button>
                                            )

                                        )
                                    }
                                </div>
                            </div>


                        </div>
                    ) )
                }
            </div>

            {
                pagesCount > 1 && (
                    <div className="slider d--flex jc--sb mt--3 sm:mt--1">
                        <button
                            className="button-clear"
                            onClick={() => page !== 0 && setPage( page - 1 )}
                        >
                            <span className={"mdi mdi-chevron-left" + ( page === 0 ? " disabled" : "" )}></span>
                        </button>
                        <div className="slider__dots d--flex ai--c">
                            {
                                ( new Array( pagesCount ) ).fill( null ).map( ( t, i ) => (
                                    <button
                                        key={i}
                                        className={"button-clear slider__dot" + ( i === page ? " active" : "" )}
                                        onClick={() => page !== i && setPage( i )}
                                    />
                                ) )
                            }
                        </div>
                        <button
                            className="button-clear"
                            onClick={() => page !== pagesCount && setPage( page + 1 )}
                        >
                            <span
                                className={"mdi mdi-chevron-right" + ( page === pagesCount ? " disabled" : "" )}></span>
                        </button>
                    </div>
                )
            }
        </div>
    )
}

function mapStateToProps( state ) {
    const {auth, credit} = state;

    return {
        auth,
        credit
    }
}

export default connect(
    mapStateToProps
)( Category );