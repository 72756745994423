import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import store from "../../../../index";
import {gameModuleOpen, gameModulesLoad, gameSectionNext} from "../../../../Store/actions/gameActions";
import {localeList} from "../../../../i18n";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import {connect} from "react-redux";

function ModuleIntro( props ) {
    const {t, i18n} = useTranslation();
    console.log( 'module intro', props );

    const refVideo = useRef( null );

    const [modal, setModal] = useState( false );

    const videoEnded = e => {
        if ( refVideo.current.exitFullscreen ) {
            refVideo.current.exitFullscreen();
        } else if ( refVideo.current.mozCancelFullScreen ) { /* Firefox */
            refVideo.current.mozCancelFullScreen();
        } else if ( refVideo.current.webkitExitFullscreen ) { /* Chrome, Safari and Opera */
            refVideo.current.webkitExitFullscreen();
        } else if ( refVideo.current.msExitFullscreen ) { /* IE/Edge */
            refVideo.current.msExitFullscreen();
        }


        axios( {
            method: "get",
            url:    apiURL + `/module/${props.game.module}/section/intro/complete`,
        } )
            .then( ( response ) => {
                props.dispatch( gameModulesLoad( response.data.modules ) );
                props.dispatch( gameSectionNext() );
            } )
            .catch( ( error ) => {
                console.error( error );
            } );

    }

    useEffect( () => {
        if ( refVideo.current ) {
            refVideo.current.addEventListener( 'ended', videoEnded );
        }

        return () => {
            if ( refVideo.current ) {
                refVideo.current.removeEventListener( 'ended', videoEnded );
            }
        }
    }, [refVideo] );

    return (
        <>
            <div className="intro-wrapper video d--flex jc--c">
                <div className={"intro__video m--a0 d--flex"}>
                    <video ref={refVideo} autoPlay={true} controls={true}>
                        <source src={`/videos/module_${props.module}_intro.mp4`} type="video/mp4"/>
                        {
                            localeList.map( (locale) => (
                                <track key={locale} label={locale} kind="subtitles" srcLang={locale}
                                       src={`/videos/${locale}/module_${props.module}_intro_${locale}.vtt`} default={i18n.language === locale} />
                            ) )
                        }
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </>
    )
}


function mapStateToProps( state ) {
    const {game} = state;


    return {game}
}

export default connect(
    mapStateToProps
)( ModuleIntro );