import React from 'react';
import {useTranslation} from "react-i18next";

export default function Intro(props) {

    const {t} = useTranslation();

    return (

        <div>
            <div className="theory-container">
                <img src={"/img/game-background_brown.jpg"} className={"img--bg-full cover"}/>

                <div className="marvin h--100">
                    <div className="w--100 h--100 p--rel sm:o--1 d--flex jc--c">
                        <div className="marvin-container">
                            <img src={"/img/marvin-neutral.svg"} alt="" className="marvin__img"/>
                            <div className="text-bubble-theory m left">
                                <div className="bubble__inner">
                                    <p>Now, let´s have a look on one real social enterprise. My friends are going to
                                        tell you more...</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <button className={"btn btn--secondary nb btn--next d--flex"} onClick={(e) => {

                props.handleNext()
            }}>
                <div className={"m--a"}>
                    <span className={"mdi mdi-arrow-right"}></span>
                    <span className={"text"}>{t( 'next' )}</span>
                </div>
            </button>
        </div>
    )
}
