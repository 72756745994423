import React, {useState} from 'react';

import FirstPasswordSuccess from "./FirstPasswordSuccess";
import FirstPasswordError from "./FirstPasswordError";
import FirstPasswordForm from "./FirstPasswordForm";
import FirstPasswordAllreadyExists from "./FirstPasswordAllreadyExists";
import FirstPasswordTeamCode from "./FirstPasswordTeamCode";
import {useTranslation} from "react-i18next";

export default function FirstPassword( props ) {
    const [teamCode, setTeamCode] = useState( null );
    const [passwordExists, setPasswordExists] = useState( null );
    const [passwordSet, setPasswordSet] = useState( null );

    const {t} = useTranslation();

    return (


        <div className={"wrapper__login m--0a d--flex jc--c"}>

            <div className="wrapper__login-bg"></div>

            <div className="w--40 as--c">

                <h1 className={"heading--l mb--3"}>{t( 'auth_setting_password' )}</h1>

                <div className={"login-container"}>
                    {
                        ( !teamCode ) ? (
                            <FirstPasswordTeamCode
                                handleSuccess={( code, exists ) => {
                                    setTeamCode( code );
                                    setPasswordExists( exists );
                                }}
                            />
                        ) : (
                            passwordExists ? (
                                <FirstPasswordAllreadyExists/>
                            ) : (
                                passwordSet !== null ? (
                                    passwordSet ? (
                                        <FirstPasswordSuccess/>
                                    ) : (
                                        <FirstPasswordError/>
                                    )
                                ) : (
                                    <FirstPasswordForm
                                        code={teamCode}
                                        handleSuccess={( success ) => {
                                            setPasswordSet( success );
                                        }}
                                    />
                                )
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}