import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {apiURL} from "../../env";
import Form from "../Form/Form";
import {authLogin} from "../../Store/actions/authActions";
import {useTranslation} from "react-i18next";
import {gameModulesLoad} from "../../Store/actions/gameActions";
import {creditSet, supportersSet} from "../../Store/actions/creditActions";
import LocaleChange from "../LocaleChange";
import {notificationsLoaded} from "../../Store/actions/notificationsActions";


function Login( props ) {
    const {t, i18n} = useTranslation();

    return (


        <div className={"wrapper__login m--0a d--flex m:fw--w jc--sb sm:jc--c"}>

            <div className="wrapper__login-bg"></div>

            <div className="w--30 m:w--100 as--c">
                <h1 className={"heading--xl mb--3 sm:mb--1"}>{t( 'welcome' )}</h1>

                <div className={"login-container w--100"}>

                    <Form
                        locale={i18n.language}
                        submitLabel={t( 'auth_login' )}
                        submitMethod={"post"}
                        submitUrl={apiURL + '/login'}
                        handleSubmitSuccess={( data, response ) => {
                            // props.dispatch( gameModulesLoad( response.data.team.opened_modules ) );
                            const newLocale =  response.data.team.locale === 'gb' ? 'en' : response.data.team.locale;

                            i18n.changeLanguage(newLocale);
                            document.querySelector('html').lang = newLocale;

                            props.dispatch( authLogin( response.data.token, response.data.provider, response.data.team, response.data.student ) );
                            props.dispatch( creditSet( response.data.team.coins ) );
                            props.dispatch( supportersSet( response.data.team.supporters ) );
                            props.dispatch( notificationsLoaded( response.data.team.notifications ) );
                            localStorage.setItem( 'access_token', response.data.token );
                            localStorage.setItem( 'guard_provider', response.data.provider );
                        }}
                        handleSubmitError={( response ) => {
                            console.error( response );
                        }}
                        inputs={[
                            <input className={"input__item w--100"}
                                   placeholder={t( 'auth_team_code' )} name="code" type="text"/>,
                            <input className={"input__item w--100"}
                                   placeholder={t( 'auth_password' )} name="password" type="password"/>
                        ]}
                    />

                    <div className="d--flex fd--c mt--3">
                        <Link className={"btn btn--text btn--0s mb--1"} to="/reset-password">
                            <strong className={"text--underline"}>{t( 'auth_reset_password' )}</strong>
                        </Link>


                        <Link className={"btn btn--text btn--0s"} to="/first-password">
                            <strong className={"text--underline"}>{t( 'auth_no_password_yet' )}</strong>
                        </Link>
                    </div>

                    <LocaleChange/>

                </div>
            </div>

            <div className="w--70 as--c m:d--none">
                <img src={"/img/login/login-city.png"} className={"login__img"}/>
            </div>

            <div className={"disclaimer d--flex"}>

                <div className="disclaimer__wrapper m--a d--flex jc--fs">

                    <div className="disclaimer__copyright d--flex as--c sm:text--center m:mr--0">
                        <p>{t( 'interreg_copyright' )}</p>
                    </div>

                    <div className="disclaimer__logo mr--3 m:mr--0 m:text--center">
                        <img src={"/img/innoschool-logo.png"}/>
                    </div>

                    <div className="disclaimer__text d--flex as--c sm:text--center">
                        <p>{t( 'interreg_disclaimer' )}</p>
                    </div>


                </div>

            </div>


        </div>

    )

}

function mapStateToProps( state ) {
    const {auth} = state;

    return {
        auth
    }
}

export default connect(
    mapStateToProps
)( Login );