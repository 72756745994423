import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useBubbleStepSound} from "../../../../../Hooks/useBubbleStepSound";
import {useTranslation} from "react-i18next";

export default function Bmc( props ) {
    const {t} = useTranslation();

    const bubbleDelayed = useBubbleDelay( [props.marvin] );

    const cells = [
        {
            id:    'partners',
            img:   '/img/bmc/link.svg'
        },
        {
            id:    'activities',
            img:   '/img/bmc/key.svg'
        },
        {
            id:    'resources',
            img:   '/img/bmc/factory.svg'
        },
        {
            id:    'propositions',
            img:   '/img/bmc/gift.svg'
        },
        {
            id:    'relationships',
            img:   '/img/bmc/heart.svg'
        },
        {
            id:    'channels',
            img:   '/img/bmc/delivery-truck.svg'
        },
        {
            id:    'segments',
            img:   '/img/bmc/smile.svg'
        },
        {
            id:    'cost',
            img:   '/img/bmc/discount.svg'

        },
        {
            id:    'streams',
            img:   '/img/bmc/money-bag.svg'
        }
    ];

    console.log(props.active);


    return (

        <div className={"theory-container"}>

            <div className={"marvin-bmc h--100"}>
                <div className="w--100 h--100 p--rel d--flex jc--c sm:o--1">
                    <div className="marvin-container">
                        <img src={props.marvin_image} className={"marvin__img"} alt=""/>
                        {
                            bubbleDelayed && (
                                <div className="text-bubble-theory left">
                                    <div className="bubble__inner">

                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_marvin`)}}/>

                                        {/*<p dangerouslySetInnerHTML={{__html: props.marvin}}/>*/}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="bmc-container">

                {
                    cells.map( ( cell, i ) => (
                        <div key={i} className={"bmc__item d--flex " + cell.id + (cell.id == props.active ? ' active' : '')} >
                            <div className={"bmc__name"}><h2>{t('theory_bmc_' + cell.id)}</h2></div>
                            <div className={"bmc__icon m--a"}>
                                <img src={cell.img} alt=""/>
                            </div>
                        </div>
                    ) )
                }

            </div>

        </div>
    )
}