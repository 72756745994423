import React, {useState} from 'react';
import {localeList} from "../i18n";
import {useTranslation} from "react-i18next";

export default function LocaleChange(props) {
    const {t, i18n} = useTranslation();

    return (
        <div className="d--flex jc--sb login__lang mt--2">
            {
                localeList.map((l, i) => (
                    <a href="#"
                       className="m--a"
                       key={i}
                       onClick={() => {
                           i18n.changeLanguage(l)
                       }}
                    >

                        <span className="f16 flag-item">
                            <span className={"flag " + ((l == "en") ? "gb" : l) }></span>
                        </span>
                    </a>
                ))
            }
        </div>
    )
}