import React from 'react';
import scenarios from "../ScenariosSelect/data";
import {useTranslation} from "react-i18next";
import InfoPanel from "../../../InfoPanel";

export default function SelectScenario( props ) {
    const {t} = useTranslation();
    return (
        <div className="wrapper__content m--0a d--flex fd--c">
            <div className="d--flex jc--sb ai--c">
                <h1 className="mt--3 mb--3 heading--m">{t('case_studies')}</h1>
                <button className={"btn btn--m btn--secondary btn--s"}
                        onClick={() => {
                            props.handleBackToMenu()
                        }}
                >
                    {t('back_to_menu')}
                </button>
            </div>
            {
                !props.selectedScenario && (
                    <InfoPanel text={t('info_case_studies_scenario')}/>

                )
            }
            <div className={"card-container w--100"}>
                {
                    scenarios.map( ( s, i ) => (
                        <button
                            key={i + 1}
                            className={"card scenarios-item"}
                            onClick={(e) => {
                                props.handleSelect(s.code)
                            }}
                        >
                            <div className="scenario__bg d--flex">
                                <p className={"m--a"}>{t(`case_studies_${s.code}_description`)}</p>
                            </div>

                            <div className="scenarios__img">
                                <img src={`/img/scenarios/_s${i + 1}.jpg`} alt=""/>
                            </div>

                            <div className="scenarios__name d--flex ai--c">
                                <h2>{t(`scenarios_${s.code}_name`)}</h2>
                            </div>

                        </button>
                    ) )
                }
            </div>
        </div>
    )
}