import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LinkToLogin from "../LinkToLogin";

export default function TokenInvalid(props) {
    const {t} = useTranslation();

    return (
        <div className={"wrapper__login m--0a d--flex jc--c"}>
            <div className="wrapper__login-bg"></div>
            <div className="w--40 sm:w--100 as--c">
                <h1 className={"heading--l mb--3"}>{t('password-reset-token-invalid')}</h1>
                <div className="d--flex jc--c mt--3">
                    <LinkToLogin/>
                </div>
            </div>
        </div>
    )
}