import React, {useEffect} from 'react';
import ScreensNavigation from "../ScreensNavigation";
import {useTranslation} from "react-i18next";

export default function Marvin(props) {
    const {t} = useTranslation();

    return (
        <div className={"theory-container"}>
            <div className={"marvin h--100"}>
                <div className="w--100 h--100 p--rel sm:o--1 d--flex jc--c">
                    <div className="marvin-container sm:w--100">
                        <img src={props.marvin_image} className={"marvin__img"} alt=""/>
                        <div className="text-bubble-theory m left">
                            <div className="bubble__inner">
                                <p>{t(`quiz_intro_level_${props.level}_marvin_${props.step}`)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <img src="/img/bg-quiz.png" className={"img--bg-full"} alt=""/>

            <ScreensNavigation
                handlePrev={props.handlePrev}
                handleNext={props.handleNext}
            />
        </div>
    )
}