import {NOTIFICATIONS_LOADED} from "../actions/notificationsActions";

const initialNotifications = [];

export const NotificationsReducer = ( state = initialNotifications, action ) => {
    switch ( action.type ) {
        case NOTIFICATIONS_LOADED:
            return action.notifications;

        default:
            return state;
    }
};