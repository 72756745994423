import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {gameModulesLoad} from "../../../Store/actions/gameActions";
import {authTeamLoaded} from "../../../Store/actions/authActions";
import {notificationsLoaded} from "../../../Store/actions/notificationsActions";
import {set} from "immutable";

function MessageFile( {message} ) {
    const [downloading, setDownloading] = useState( false );
    return (
        <a
            href="#"
            onClick={() => {
                if ( downloading ) return;

                setDownloading( true );
                axios( {
                    url:          apiURL + '/chat/file/' + message.file.id,
                    method:       'GET',
                    responseType: 'blob',
                } ).then( ( response ) => {
                    const url = window.URL.createObjectURL( new Blob( [response.data] ) );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    link.setAttribute( 'download', message.file.name ); //or any other extension
                    document.body.appendChild( link );
                    link.click();
                    link.remove();
                } ).finally( () => {
                    setDownloading( false );
                } );
            }}
        >
            {message.file.name}
            {/*{*/}
            {/*    downloading && ( 'downloading...' )*/}
            {/*}*/}
        </a>
    )
}

function MessageImage( {message} ) {
    const [downloading, setDownloading] = useState( false );
    const [loading, setLoading] = useState( false );
    const [base64Image, setBase64Image] = useState( null );

    useEffect( () => {
        setLoading( true );
        axios( {
            url:          apiURL + '/chat/thumb/' + message.file.id,
            method:       'GET',
            responseType: 'blob',
        } ).then( ( response ) => {
            const fileReaderInstance = new FileReader();
            fileReaderInstance.readAsDataURL( response.data );
            fileReaderInstance.onload = () => {
                const base64data = fileReaderInstance.result;
                setBase64Image( base64data );
            }
        } ).finally( () => {
            setLoading( false );
        } );


    }, [] )

    // {apiURL + '/chat/file/' + message.file.id} target="_blank
    return (
        <a href="#" className={'d--flex fd--c'}
           onClick={() => {
               if ( downloading ) return;

               setDownloading( true );

               axios( {
                   url:          apiURL + '/chat/file/' + message.file.id,
                   method:       'GET',
                   responseType: 'blob',
               } ).then( ( response ) => {

                   const url = window.URL.createObjectURL( new Blob( [response.data] ) );
                   const link = document.createElement( 'a' );
                   link.href = url;
                   link.setAttribute( 'download', message.file.name ); //or any other extension
                   document.body.appendChild( link );
                   link.click();
                   link.remove();

                   // const fileReaderInstance = new FileReader();
                   // fileReaderInstance.readAsDataURL( response.data );
                   // fileReaderInstance.onload = () => {
                   //     const base64data = fileReaderInstance.result;
                   //     var image = new Image();
                   //     image.src = base64data;
                   //     var w = window.open("");
                   //     w.document.write(image.outerHTML);
                   //     // window.open(base64data, "_blank");
                   // }

               } ).finally( () => {
                   setDownloading( false );
               } );
           }}
        >
            {/*{loading && ( 'loading...' )}*/}
            {base64Image !== null && (
                <img className={'message__thumb'}
                     src={base64Image}
                     alt={message.file.name}/>
            )}
            {/*{downloading && ( 'downloading...' )}*/}
            <span className={'message__desc'}>{message.file.name}</span>
        </a>
    )
}

export default React.forwardRef( ( {auth, loading, messages, onOlderClick, hasOld}, ref ) => {
    const {t} = useTranslation();

    const sameDay = ( date1, date2 ) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    };

    const timeFormat = ( date ) => {
        const h = date.getHours();
        const m = date.getMinutes();
        return ( h < 10 ? '0' + h : h ) + ':' + ( m < 10 ? '0' + m : m );
    }

    const dateFormat = ( date ) => {
        const h = date.getHours();
        const m = date.getMinutes();
        return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay() + " " + ( h < 10 ? '0' + h : h ) + ':' + ( m < 10 ? '0' + m : m );
    }

    return (
        <div className="discussion-container">
            {
                hasOld && (
                    <button className="btn btn--older" onClick={onOlderClick}>
                        {t( 'chat_old-messages' )}
                    </button>
                )
            }

            {
                messages.length > 0 ? (
                    <label for="chat-text-input">
                    <div className={'discussion-container__inner'} ref={ref}>
                        {
                            messages.map( ( message ) => {
                                return (
                                    <div
                                        className={"discussion__message " + (
                                            auth.student ? ( message.author === auth.student.full_name ? "author" : "" ) : ( message.author === auth.team.name ? "author" : "" ) )}
                                        key={message.id}
                                    >

                                        <div className={"message " + ( ( message.is_teacher ) ? "teacher" : "" )}>
                                            {
                                                message.file && (
                                                    message.file.mime.startsWith( 'image' ) ? (
                                                        <MessageImage message={message}/>
                                                    ) : (
                                                        <MessageFile message={message}/>
                                                    )
                                                )
                                            }
                                            {message.message}
                                        </div>
                                        <div className={"discussion__message__details"}>
                                            <div className="discussion__message__author">
                                                {message.author}
                                            </div>

                                            <div className="discussion__message__timestamp">
                                                {sameDay( new Date( message.created_at ), new Date( Date.now() ) ) ? (
                                                    <span>{timeFormat( new Date( message.created_at ) )} {t( 'chat_cet' )}</span>
                                                ) : (
                                                    <span>{dateFormat( new Date( message.created_at ) )} {t( 'chat_cet' )}</span>
                                                )
                                                }

                                            </div>

                                        </div>


                                    </div>
                                );
                            } )}
                    </div>
                    </label>
                ) : (
                    loading ? (
                        <h3 className={"heading--m mb--3 mt--4 text--center"}>{t( 'loader_loading' )}</h3>
                    ) : (
                        <h3 className={"heading--m mb--3 mt--4 text--center"}>{t( 'chat_no-messages' )}</h3>
                    )

                )
            }
        </div>
    );

} );