import React from 'react';
import {useTranslation} from "react-i18next";
import openTaskData from "./data_open_task_stories";

export default function OpenTaskStory( props ) {

    const {t} = useTranslation();

    const img = `${props.level}_${props.index}.png`;

    const defaultImg = '0.png';

    return (
        <div className="d--iflex fd--c ai--fs question-container">
            <div className="modal-wrapper">
                <div className="modal modal--l modal__outro">

                    <button
                        className={"modal__close d--flex dark"}
                        onClick={() => props.handleClose()}
                    >
                        <span className={"mdi mdi-close m--a"}> </span>
                    </button>

                    <div className={"modal__body d--flex fd--c"}>

                        <div className="modal__inner d--flex">
                            <div className="w--50">
                                <h2 className={"text--center mb--3"}>{props.name}</h2>
                                <p className={"mt--3 mb--2 text--center"}>
                                    <span dangerouslySetInnerHTML={{__html: props.story}}/>
                                </p>

                                <div className="d--flex jc--c">
                                    <button
                                        className={"btn btn--m btn--danger btn--shadow d--block"}
                                        onClick={() => props.handleClose()}
                                    >
                                        {t( 'continue' )}
                                    </button>
                                </div>
                            </div>
                            <img className={"img__right"}
                                 src={`/img/story/${openTaskData.indexOf( img ) > 0 ? img : defaultImg}`}


                                // src={openTaskData[props.id] ? openTaskData[props.id].marvin_image : openTaskData[0].marvin_image}
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="backdrop info"></div>
        </div>
    )
}