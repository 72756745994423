import React, {useState, useEffect} from 'react';
import Intro from "./Intro";

import tasks from './data.json';
import Menu from "./Menu";
import Task from "./Task";
import {connect} from "react-redux";
import * as axios from "axios";
import {apiURL} from "../../../../env";
import Loader from "./Loader";
import store from "../../../../index";
import {flashOpen} from "../../../../Store/actions/flashActions";
import {useTranslation} from "react-i18next";
import ScenarioSelectModal from "./ScenarioSelectModal";
import {authScenario} from "../../../../Store/actions/authActions";
import {gameModuleClose, gameModulesLoad} from "../../../../Store/actions/gameActions";

function Tasks( props ) {
    const {t} = useTranslation();

    const [loading, setLoading] = useState( true );
    const [intro, setIntro] = useState( false );
    const [task, setTask] = useState( null );

    const [basic, setBasic] = useState( null );
    const [advanced, setAdvanced] = useState( null );
    const [solutions, setSolutions] = useState( null );
    const [files, setFiles] = useState( null );
    const [updatable, setUpdatable] = useState( false );
    const [submittedBy, setSubmittedBy] = useState(null);
    const [evaluated, setEvaluated] = useState(false);


    const [scenarioSelectModal, setScenarioSelectModal] = useState( false );

    const [conflict, setConflict] = useState(false);

    useEffect( () => {
        axios( {
            method: 'get',
            url:    apiURL + `/module/${props.game.module}/tasks`,
        } )
            .then( ( response ) => {
                console.log(response.data);
                if (response.data.evaluated) setEvaluated(response.data.evaluated);
                if (response.data.submittedBy) setSubmittedBy(response.data.submittedBy);
                setBasic( response.data.basic );
                setAdvanced( response.data.advanced );
                setUpdatable( response.data.updatable );

                const allTasks = [].concat( response.data.basic, response.data.advanced);

                const solutions = allTasks.reduce( ( a, c ) => {
                    a[ c.id ] = c.answer ? c.answer : '';
                    return a;
                }, {} );

                const files = allTasks.reduce( ( a, c ) => {
                    a[ c.id ] = c.files ? c.files : [];
                    return a;
                }, {} );

                setSolutions( solutions );
                setFiles( files );
                setLoading( false );
                setScenarioSelectModal( props.game.module === 2 && updatable);
            } )
            .catch( ( error ) => {
                console.error( error );
            } );
    }, [] );

    useEffect( () => {
        if(task === null) return;

        console.log(Object.keys(solutions).reduce( ( a, c) => {
            a[c] = solutions[c];
            return a;
        }, {}));

        console.log('otvaram task', task);
        axios.get(`${apiURL}/tasks/${task}/answer`)
            .then((response) => {
                if(response.data.answer){

                    console.log('mam odpoved', task)


                    setBasic((tasks) => tasks.map((t) => t.id === task ? Object.assign({}, t, {answer_updated: response.data.updated}) : t));
                    setAdvanced((tasks) => tasks.map((t) => t.id === task ? Object.assign({}, t, {answer_updated: response.data.updated}) : t));

                    setSolutions(solutions => Object.keys(solutions).reduce( ( a, c) => {
                        if(c == task) a[c] = response.data.answer.text ? response.data.answer.text : ''
                        else a[c] = solutions[c];
                        return a;
                    }, {}));


                    console.log(files);
                    console.log(Object.keys(files).reduce( ( a, c) => {
                        if(c == task) a[c] = response.data.answer.files ? response.data.answer.files : []
                        else a[c] = files[c];
                        return a;
                    }, {}));

                    setFiles(files => Object.keys(files).reduce( ( a, c) => {
                        if(c == task) a[c] = response.data.answer.files ? response.data.answer.files : []
                        else a[c] = files[c];
                        return a;
                    }, {}));
                }
            })
    }, [task])

    if ( loading ) {
        return <Loader/>
    }

    if ( intro ) {
        return <Intro handleClose={() => setIntro( false )}/>
    }

    console.log('renderujem tasky', files);


    if ( scenarioSelectModal ) {
        return <ScenarioSelectModal
            selected={props.auth.team.scenario}
            handleClose={() => setScenarioSelectModal( false )}
            handleSelect={( s ) => {
                setLoading( true );
                setScenarioSelectModal( false );

                axios( {
                    method: 'post',
                    url:    apiURL + '/access/scenario/set',
                    data:   {scenario: s}
                } )
                    .then( ( response ) => {
                        setLoading( false );
                        props.dispatch( authScenario( s ) );
                    } )
                    .catch( ( error ) => {
                        console.error( error );
                    } );
            }}
        />
    }

    if ( task === null ) {
        return <Menu
            basic={basic}
            advanced={advanced}
            solutions={solutions}
            updatable={updatable}
            submittedBy={submittedBy}
            evaluated={evaluated}
            handleOpen={( task ) => setTask( task )}
            handleChangeScenario={() => {
                setScenarioSelectModal( true )
            }}
        />
    }


    let activeAdvanced = false;
    let activeTask = basic.filter( ( t ) => ( t.id === task ) ).pop();
    let activeTaskIndex = basic.map( ( t ) => t.id ).indexOf( task ) + 1;

    if ( !activeTask ) {
        activeAdvanced = true;
        activeTask = advanced.filter( ( t ) => ( t.id === task ) ).pop();
        activeTaskIndex = basic.map( ( t ) => t.id ).indexOf( task ) + 2 + basic.length;
    }

    let editableTask = false;
    if (props.auth.student){
        editableTask = props.game.sectionStatus[ props.game.module ][ 'tasks' ][props.auth.student.id] !== 'completed'
            && props.game.sectionStatus[ props.game.module ][ 'tasks' ][props.auth.student.id] !== 'locked'
            && props.game.sectionStatus[ props.game.module ][ 'tasks' ][props.auth.student.id] !== 'evaluated'
    } else {
        editableTask = props.game.sectionStatus[ props.game.module ][ 'tasks' ] !== 'completed'
                && props.game.sectionStatus[ props.game.module ][ 'tasks' ] !== 'locked'
                && props.game.sectionStatus[ props.game.module ][ 'tasks' ] !== 'evaluated'
    }


    return (
        <Task
            task={activeTask}
            index={activeTaskIndex}
            advanced={activeAdvanced}
            team={props.auth.team}
            level={props.game.module}
            editable={editableTask}
            solution={solutions[ task ]}
            files={files[ task ]}
            setFiles={(f) => {
                setFiles(files => Object.keys(files).reduce( ( a, c) => {
                    if(c == task) a[c] = f ? f : []
                    else a[c] = files[c];
                    return a;
                }, {}));
            }}
            handleClose={() => setTask( null )}
            conflict={conflict}
            handleFile={() => {
                // let tmpFiles = {}
                // tmpFiles[ task ] = files;
                // setFiles( Object.assign( {}, files, tmpFiles ) );
            }}
            handleSave={( solution ) => {
                setConflict(false);

                axios( {
                    method: 'post',
                    url:    apiURL + `/task/${task}/answer`,
                    data:   {solution, updated: activeTask.answer_updated}
                } )
                    .then( ( response ) => {
                        let tmpSolution = {}
                        tmpSolution[ task ] = solution;
                        store.dispatch( flashOpen( {status: 'success', text: t( 'task-flash-success' )} ) );
                        setSolutions( Object.assign( {}, solutions, tmpSolution ) );

                        // update timestamp of last update of task solution
                        setBasic((tasks) => tasks.map((t) => t.id === task ? Object.assign({}, t, {answer_updated: response.data.updated}) : t));
                        setAdvanced((tasks) => tasks.map((t) => t.id === task ? Object.assign({}, t, {answer_updated: response.data.updated}) : t));
                    } )
                    .catch( ( error ) => {
                        if(error.response && error.response.status === 400){
                            props.dispatch( gameModuleClose() );
                        }else if(error.response && error.response.status === 409){
                            setConflict(true);
                        }
                    } );
            }}
            handleUpdate={(updated) => {
                setBasic((tasks) => tasks.map((t) => t.id === task ? Object.assign({}, t, {answer_updated: updated}) : t));
                setAdvanced((tasks) => tasks.map((t) => t.id === task ? Object.assign({}, t, {answer_updated: updated}) : t));
            }}
        />
    )
}

function mapStateToProps( state ) {
    const {game, auth} = state;

    return {game, auth}
}

export default connect(
    mapStateToProps
)( Tasks );