import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LinkToLogin from "../LinkToLogin";

const FirstPasswordAllreadyExists = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className={"alert alert--error"}>{t( 'auth_password_exists' )}</div>
            <div className="d--flex jc--fs mt--3">
                <LinkToLogin/>
            </div>
        </>

    );
};

export default FirstPasswordAllreadyExists;
