import React from 'react';
import {useTranslation} from "react-i18next";

export default function Loading( props ) {

    const {t} = useTranslation();

    return (
        <div className={"d--flex fd--c ai--fs question-container"}>


            <div className="m--a text--center">
                <h1 className={"question__quiz"}>{t( 'quiz_loading' )}</h1>
            </div>

            <img src="/img/bg-quiz.png" className={"img--bg-full"} alt=""/>

        </div>
    )
}