import React, {useState} from 'react';
import {gameModuleClose, gameModuleOpen} from "../../../../Store/actions/gameActions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import ScreensNavigation from "../ScreensNavigation";
import {faq_questions} from "./data/faq";
import {useBubbleDelay} from "../../../../Hooks/useBubbleDelay";

import {useKeysNavigation} from "../../../../Hooks/useKeysNavigation";

function Faq(props) {
    const {t} = useTranslation();

    const [question, setQuestion] = useState(null);
    const [screenIndex, setScreenIndex] = useState(0);

    const bubbleDelayed = useBubbleDelay([question, screenIndex], question !== null);


    const handlePrev = () => {
        if (question === null) return;
        if (screenIndex > 0) {
            setScreenIndex(screenIndex - 1);
        } else {
            setQuestion(null);
        }
    }

    const handleNext = () => {
        if (question === null) return;
        if (screenIndex < (faq_questions[question].length - 1)) {
            setScreenIndex(screenIndex + 1);
        } else {
            setQuestion(null);
        }
    }

    useKeysNavigation(handlePrev, handleNext);

    const questions = [
        'about',
        'office',
        'hero',
        'badges_and_awards',
        'supporters',
        'steps'
    ]


    if (question === null) {
        return (
            <>
                <img src={"img/office/office-modern-noawards.jpg"}
                     className={`img--bg-full`}
                     alt=""/>
                <div className="backdrop info"/>
                <div className="modal-wrapper">
                    <div className="modal modal--l modal__intro blue">
                        <div className="modal__body h--100 d--flex">
                            <div className="modal__left w--60 sm:w--100">
                                <div className="modal__inner">
                                    <div className="text--center d--flex fd--c jc--fs ai--fs">
                                        {
                                            questions.map((q, i) => (
                                                <button
                                                    key={q}
                                                    className={"btn btn--m btn--white left nb mw--100" + (i > 0 ? ' mt--2 sm:mt--1' : '')}
                                                    onClick={() => {
                                                        setQuestion(q);
                                                        setScreenIndex(0);
                                                    }}
                                                >
                                                    {t('gameintro_intro_modal_item_' + (q))}
                                                </button>
                                            ))
                                        }
                                        <div className={"d--flex jc--fs mt--3 sm:mt--1 sm:fw--w w--100"}>
                                            {
                                                props.game.sectionStatus[0]['intro'] !== 'completed'
                                                && props.game.moduleStatus[1] !== 'locked'
                                                && (
                                                    <button
                                                        className={"btn btn--m btn--success btn--shadow w--100"}
                                                        onClick={() => {
                                                            props.dispatch(gameModuleOpen(1));
                                                        }}
                                                    >
                                                        {t('gameintro_intro_modal_btn_gotit')}
                                                    </button>
                                                )
                                            }
                                            <button
                                                className={"btn btn--m btn--text white w--100"}
                                                onClick={() => {
                                                    props.dispatch(gameModuleClose());
                                                }}
                                            >
                                                {t('back_to_menu')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal__right sm:d--none w--40 h--100">
                                <img src="img/intro-modal_bubble2.png" className="intro__bubble"/>
                                <img src="img/marvin-tablet_question.svg" className={"modal__marvin"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {

        const screen = faq_questions[question][screenIndex];

        return (
            <div className="intro-wrapper">
                <ScreensNavigation handlePrev={handlePrev} handleNext={handleNext}/>
                {
                    question !== 'supporters' && screen.marvine && (
                        <div className={"theory-container"}>
                            <div className={"marvin h--100"}>
                                <div className="w--100 h--100 p--rel d--flex jc--c sm:o--1 sm:theory__table-center">
                                    <div className={"marvin-container sm:w--100"}>
                                        {
                                            bubbleDelayed && (
                                                <div className="text-bubble-theory left">
                                                    <div className="bubble__inner">
                                                        <p dangerouslySetInnerHTML={{
                                                            __html: t(`gameintro_intro_modal_item_${question}_text_${screenIndex + 1}`)
                                                        }}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <img src={"img/" + screen.marvine} className={"marvin__img"}
                                             alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {

                    screen.background && (
                        <div className={"supporters--bg"}>
                            <img src="img/bg-intro_blue.jpg" className={"img--bg-full"} alt=""/>

                            <img src={"img/" + screen.background}
                                 className={`img--bg-full ${screen.background === 'bg-supporters.png' ? 'supporters' : ''}`}
                                 alt=""/>
                        </div>
                    )

                }

                {
                    question === 'supporters' && (
                        bubbleDelayed && (
                            <div className="bubble supporters-bubble d--flex">
                                <div className="bubble__inner m--a0">
                                    <p dangerouslySetInnerHTML={{
                                        __html: t(`gameintro_intro_modal_item_${question}_text_${screenIndex + 1}`)
                                    }}/>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {game} = state;

    return {game}
}

export default connect(
    mapStateToProps
)(Faq);