import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";

function ModalBadge(props) {
    const {t} = useTranslation();

    const classes = [
        'socialneeds',
        'ideageneration',
        'visionary',
        'empathy',
        'idea',
        'sustainable'
    ];


    return (
        <div className="modal-wrapper">
            <div className="modal modal--l modal__outro gray">

                <div className={"modal__body modal__module-finish text--center " + classes[ props.type - 1 ]}>

                    <div className="modal__inner">
                        <h2>{t( 'module_finish_congrats' )}</h2>
                        <p className={"subtitle mb--3"}>{t( 'module_finish_subheading' )}</p>
                        <img src={`img/modules/bg/badge-${classes[ props.type - 1 ]}.png`} alt=""/>
                        <p className={"mb--2"}>
                            {t( 'module_finished_' + props.type + '_quote' )}
                        </p>

                        <p dangerouslySetInnerHTML={{__html: t( `module_finished_${props.type}_congratulations` )}}/>

                        <div className="d--flex jc--c mt--3">
                            {
                                props.close ? (
                                    <button
                                        className={"btn btn--m btn--text mr--1"}
                                        onClick={() => props.handleClose()}
                                    >
                                        {t( 'close' )}
                                    </button>
                                ) : (
                                    <button
                                        className={"btn btn--m btn--text mr--1"}
                                        onClick={() => props.handleClose()}
                                    >
                                        {t( 'module_finish_btn_continue' )}
                                    </button>
                                )}
                            {
                                props.handleShare && (
                                    <button
                                        className="btn btn--m btn--success btn--shadow d--flex btn--share"
                                        onClick={() => {
                                            props.handleShare();
                                        }}
                                    >
                                        {t( 'share' )}
                                    </button>
                                )
                            }


                        </div>
                    </div>
                {/*</div>*!/*/}


                {/*<div className="modal__body d--flex h--100">*/}
                {/*    <div className="modal__left w--50">*/}
                {/*        <div className="modal__inner">*/}

                {/*            <h2 className={"text--center mb--3 mt--3"}>{t('quiz_modal_results_heading')}</h2>*/}

                {/*            <div className="d--flex jc--c mb--2">*/}
                {/*                <img src="img/supporters.svg" alt="" className="mr--3 supporters__img"/>*/}
                {/*                <p className="text--supporters as--c">-40</p>*/}
                {/*            </div>*/}

                {/*            <div className="outro__results mb--3 mt--3">*/}

                {/*                <div className={"d--flex outro__result-container jc--sa"}>*/}
                {/*                    <div className={"outro__item"}>*/}
                {/*                        <div className="outro__icon d--flex">*/}
                {/*                            <img src="img/check-outro.svg" className={"m--a"} alt=""/>*/}
                {/*                        </div>*/}

                {/*                        <div className="outro__value text--center mt--2">*/}
                {/*                            <h3>90%</h3>*/}
                {/*                            <p>{t('quiz_modal_results_correct')}</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className={"outro__item"}>*/}
                {/*                        <div className="outro__icon d--flex">*/}
                {/*                            <img src="img/bonus.svg" className={"m--a"} alt=""/>*/}
                {/*                        </div>*/}

                {/*                        <div className="outro__value text--center mt--2">*/}
                {/*                            <h3>10</h3>*/}
                {/*                            <p>{t('bonus')}</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className={"outro__item"}>*/}
                {/*                        <div className="outro__icon d--flex">*/}
                {/*                            <img src="img/coin-outro.svg" className={"m--a"} alt=""/>*/}
                {/*                        </div>*/}

                {/*                        <div className="outro__value text--center mt--2">*/}
                {/*                            <h3>10</h3>*/}
                {/*                            <p>{t('quiz_modal_results_heroes')}</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    /!*<div className={"outro__item"}>*/}
                {/*                        <div className="outro__icon d--flex">*/}
                {/*                            <img src="img/supporters-outro.svg" className={"m--a"} alt=""/>*/}
                {/*                        </div>*/}

                {/*                        <div className="outro__value text--center mt--2">*/}
                {/*                            <h3>20</h3>*/}
                {/*                            <p>{t('quiz_modal_results_supporters')}</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*!/*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <div className="outro__buttons text--center">*/}
                {/*                <button className="btn btn--m btn--danger btn--shadow">{t('continue')}*/}
                {/*                </button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="modal__right w--50 h--100">*/}
                {/*        <img src="img/quiz/M1.png" className="modal__marvin" alt="Quiz outro"/>*/}
                {/*    </div>*/}


                </div>

            </div>
        </div>
    )
}

function ModalOffice(props) {

    const {t} = useTranslation();

    const texts = [
        t('module_finish_office_text1'),
        t('module_finish_office_text2'),
        t('module_finish_office_text2'),
        t('module_finish_office_text2'),
        t('module_finish_office_text2'),
        t('module_finish_office_text2'),
    ];

    const classes = [
        'M1',
        'M2',
        'M3',
        'M4',
        'M5',
        'M6',
    ];

    return (
        <div className="modal-wrapper">
            <div className="modal modal--l modal__office">

                <button
                    className={"modal__close d--flex dark"}
                    onClick={() => props.handleClose()}
                >
                    <span className={"mdi mdi-close m--a"}> </span>
                </button>

                <div className={"modal__body " + classes[props.type - 1]}>

                    <div className="modal__inner d--flex">
                        <div className="w--50">
                            <h2 className={"text--center mb--3"}>{t('module_finish_office_upgrade_text1')}</h2>
                            <p className={"text--center"}>{t('module_finish_congrats')}</p>
                            <p className={"text--center"}>{t('module_finish_office_text' + props.type)}</p>


                            <div className="d--flex jc--c mt--3">
                                <a
                                    href={"/office"}
                                    className={" btn btn--m mr--1 btn--success btn--shadow d--block"}
                                    onClick={() => props.handleClose()}
                                >
                                    {t('go_to_office')}
                                </a>

                                <button
                                    className={"btn btn--m btn--white btn--shadow d--block"}
                                    onClick={() => props.handleClose()}
                                >
                                    {t('back_to_menu')}
                                </button>
                            </div>
                        </div>

                        <img className={"img__right"}
                             src={`img/office/modal/modal-office-img_${classes[props.type - 1]}.png`}
                             alt=""/>
                    </div>


                </div>


            </div>

        </div>
    )
}


export default function Badge(props) {
    const [office, setOffice] = useState(false);

    useEffect(() => {
        setOffice(false);
    }, [props.type]);

    console.log('badge', props);
    console.log(!props.close, parseInt(props.type) < 6);

    return (
        <>
            {
                office ? (
                    <ModalOffice
                        handleClose={() => {
                            props.handleClose();
                        }}
                        handleShare={props.handleShare}
                        type={props.type}
                    />
                ) : (
                    <ModalBadge
                        handleClose={() => {
                            if (!props.close && parseInt(props.type) < 6) {
                                setOffice(true);
                            } else {
                                props.handleClose();
                            }
                        }}
                        handleShare={props.handleShare}
                        close={props.close}
                        type={props.type}
                    />
                )
            }

            <div className="backdrop info"></div>
        </>
    )
}