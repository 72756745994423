import React, {useState, cloneElement, useRef} from "react";
import Category from "./Category";
import {connect} from "react-redux";
import {useTranslation, Trans} from "react-i18next";
import MarketplacesMenu from "./MarketplacesMenu";
import useOutsideClick from "../../../../Hooks/useOutsideClick";
import {categories} from "./categories";

function Marketplace( props ) {
    const [active, setActive] = useState( false );
    const [activeCategory, setActiveCategory] = useState( 0 );
    const [modal, setModal] = useState( null );

    const {t} = useTranslation();

    const ref = useRef( null );

    useOutsideClick( ref, () => {
        if(!modal) {
            setActive( false );
        }
    } );

    console.log( active, modal );

    return (

        <div
            ref={ref}
            className={"market-container d--flex" + ( active ? ' active' : '' )}
            onClick={() => !active && setActive( true )}
        >

            <div className="market__left d--flex">

                <h2 className={"d--flex"}>
                    {t( 'office_marketplace_heading' )}
                    <img src="img/coin.svg" className={"ml--2"} alt=""/>
                </h2>

                <button
                    className={"button-clear close " + ( active ? "right" : "left" )}
                    onClick={() => active && setActive( false )}
                >
                    <span className="mdi mdi-chevron-double-right mdi--right m--a"></span>
                    <span className="mdi mdi-chevron-double-left mdi--left m--a"></span>
                </button>

                <MarketplacesMenu
                    active={activeCategory}
                    handleOpen={( category ) => {
                        setActiveCategory( category )
                    }}
                />

            </div>


            <Category
                credit={props.credit.credit}
                products={props.products}
                category={categories[ activeCategory ]}

                purchased={props.purchased}
                handleBuy={props.handleBuy}
                used={props.used}
                handleUse={props.handleUse}
                unlocked={props.unlocked}
                openModal={( modal ) => {
                    setModal( modal )
                }}
            />

            {
                modal && cloneElement(
                    modal,
                    {
                        handleClose: () => {
                            setModal( null )
                        }
                    },
                )
            }
        </div>
    )
}


function mapStateToProps( state ) {
    const {auth, credit} = state;

    return {
        auth,
        credit
    }
}

export default connect(
    mapStateToProps
)( Marketplace );