export const faq_questions = {
    about:            [
        {
            marvine:    'marvin-standing_smile.svg',
            layout: 'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-showing.svg',
            layout: 'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-pointing.svg',
            layout: 'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-pointing_wink.svg',
            layout: 'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-pockets_smiling.svg',
            layout: 'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-pointing.svg',
            layout: 'left',
            background: 'bg-intro_blue.jpg'
        }
    ],


    office:            [
        {
            marvine:    'marvin-table.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg'
        },
        {
            marvine:    'marvin-standing_smile.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg'
        },
        {
            marvine:    'marvin-pointing_shock.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg'
        },
        {
            marvine:    'marvin-pointing_wink.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg'
        }
    ],
    hero:              [
        {
            marvine:    'marvin-money.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg',
        },
        {
            marvine:    'marvin-money.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg',
        },
        {
            marvine:    'marvin-money.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg',
        },
        {
            marvine:    'marvin-money.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg',
        },
        {
            marvine:    'marvin-money.svg',
            layout: 'left',
            background: 'office/office-modern-noawards.jpg',
        }
    ],
    badges_and_awards: [
        {
            marvine:    'marvin-table.svg',
            background: 'bg-bages.png'
        },
        {
            marvine:    'marvin-pockets.svg',
            background: 'bg-bages.png',
        }
    ],
    supporters:        [
        {
            background: 'bg-supporters.png',
        },
        {
            background: 'bg-supporters.png',
        },
        {
            background: 'bg-supporters.png',
        },
        {
            background: 'bg-supporters.png',
        },
        {
            background: 'bg-supporters.png',
        },
        {
            background: 'bg-supporters.png',
        },
        {
            background: 'bg-supporters.png',
        }
    ],
    steps:             [
        {
            marvine:    'marvin-thinking.svg',
            layout:     'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-pointing_wink.svg',
            layout:     'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-standing_shock.svg',
            layout:     'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-pointing.svg',
            layout:     'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-smiling.svg',
            layout:     'left',
            background: 'bg-intro_blue.jpg'
        },
        {
            marvine:    'marvin-pockets_smiling.svg',
            layout:     'left',
            background: 'bg-intro_blue.jpg'
        }
    ]
};


