import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {authLogout} from "../Store/actions/authActions";
import store from "../index";

export default function Deactivated(props) {

    const {t} = useTranslation();
    const [active, setActive] = useState(false);

    return (
        <div className={"wrapper__dashboard d--flex fd--c ai--c"}>

            <h1 className={"heading--m mb--3"}>{t('deactivated_team')}</h1>

            <a
                className={"btn btn--text btn--s"}
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    localStorage.removeItem('access_token');
                    store.dispatch(authLogout());
                    setActive(false);
                }}
            >
                {t('auth_logout')}
            </a>

        </div>
    )
}