import React from 'react';
import {useTranslation} from "react-i18next";

export default function Persons2(props) {
    const {t, i18n} = useTranslation();

    console.log('ferko', props);

    return (

        <>
            <h1 className={"text--center cs--theory"}>{t( `scenarios_${props.scenario}_name` )}</h1>
            <div className="theory-container">

                <img src={"/img/" + props.backgroundImage} className={"img--bg-full"}/>

                <div className={"h--100 dialog"}>
                    <div className="w--50 h--100 p--rel sm:w--100 sm:o--2 sm:theory__table-right">
                        <div className="dialog-container">


                            <img src={"/img/case-studies/characters/" + props.pers2_image} alt=""
                                 className={"dialog__img"}/>
                            {
                                (
                                    !i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_pers1_text`) ||
                                    props.step > 1 && i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_pers2_text`)
                                ) && (
                                    <div className={"text-bubble-theory m left"}>
                                        <div className="bubble__inner">
                                            <p dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_pers2_text`)}}/>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="w--50 p--rel sm:w--100 sm:o--1 sm:theory__table-left">
                        <div className="marvin-container">

                            <img src={"/img/case-studies/characters/" + props.pers1_image} alt=""
                                 className={"marvin__img"}/>
                            {
                                i18n.exists(`case_studies_${props.scenario}_slides_${props.slide + 1}_pers1_text`) && (
                                    <div className={"text-bubble-theory m right"}>
                                        <div className="bubble__inner">
                                            <p dangerouslySetInnerHTML={{__html: t(`case_studies_${props.scenario}_slides_${props.slide + 1}_pers1_text`)}}/>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}