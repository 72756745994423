import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import * as axios from "axios";
import {apiURL} from "../../../env";

import Loader from "../Loader";
import TeamSecretForm from "./TeamSecretForm";
import TeamCodeForm from "./TeamCodeForm";
import TeacherSuccess from "./TeacherSuccess";
import EmailSuccess from "./EmailSuccess";
import PasswordForm from "./PasswordForm";
import PasswordSuccess from "./PasswordSuccess";
import TokenInvalid from "./TokenInvalid";
import TokenExpired from "./TokenExpired";
import i18n from "../../../i18n";


export default function ResetPassword( props ) {
    const params = useParams();

    const [token, setToken] = useState( params.token );
    const [state, setState] = useState( null );

    useEffect( () => {
        if ( token !== null && token !== undefined ) {
            setState( 'loading' );

            axios.post( apiURL + '/password-reset/validate', {token} )
                .then( ( response ) => {
                    if ( parseInt( response.data ) === 1 ) {
                        setState( 'password_form' )
                    } else {
                        setState( 'token_expired' );
                    }
                } )
                .catch( ( error ) => {
                    setState( 'token_invalid' );
                } )
        } else {
            setState( 'team_code_form' )
        }
    }, [] );

    switch ( state ) {
        case 'loading':
            return (
                <Loader/>
            );

        case 'token_invalid':
            return (
                <TokenInvalid/>
            )

        case 'token_expired':
            return (
                <TokenExpired/>
            )

        case 'team_secret_form':
            return (
                <TeamSecretForm
                    changeState={( state ) => setState( state )}
                    changeToken={( token ) => setToken( token )}
                />
            );

        case 'team_code_form':
            return (
                <TeamCodeForm
                    changeState={( state ) => setState( state )}
                />
            );

        case 'teacher_success':
            return (
                <TeacherSuccess
                    changeState={( state ) => setState( state )}
                />
            );

        case 'email_success':
            return (
                <EmailSuccess/>
            );

        case 'password_form':
            return (
                <PasswordForm
                    token={token}
                    changeState={( state ) => setState( state )}
                />
            )

        case 'password_success':
            return (
                <PasswordSuccess/>
            )


        default:
            return null;
    }
}