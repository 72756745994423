import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function Badge({time, badge}) {
    const {t} = useTranslation();

    return (
        <a href="#" className={"d--flex ai--fs"}>
            <img src="img/notifications/notif-badge.svg" alt=""/>
            {t('notification_badge', {time, module: badge.type})}</a>

    )
}