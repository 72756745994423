import React, {} from "react";


export default function Marketplace( props ) {

    return (
        <div className="market__modal d--flex">
            <div className="market__modal-inner m--a text--center">
                {props.children}

                <button
                    className={"button-clear"}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        props.handleClose()
                    }}
                >
                    <span className={"mdi mdi-window-close"}></span>
                </button>
            </div>
        </div>

    )
}