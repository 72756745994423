import React from 'react';
import SectionFinishedContent from "./SectionFinishedContent";

export default function SectionFinished( props ) {
    return (
        <>
            <div className="modal-wrapper">
                <div className="modal modal--l">

                    <SectionFinishedContent
                        handleBackToMenu={props.handleBackToMenu}
                        handleContinue={props.handleContinue}
                    />


                </div>

            </div>

            <div className="backdrop info"></div>
        </>
    )
}