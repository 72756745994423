import React, { useState, useEffect } from 'react';
import {audioPlayBubbleSound} from "../utils/audio";

export function useKeysNavigation(handlePrev, handleNext) {
    const handleKeyPress = ( e ) => {
        if ( e.key === 'ArrowRight' || e.key === 'Right') {
            handleNext();
        } else if ( e.key === 'ArrowLeft' || e.key === 'Left' ) {
            handlePrev();
        }
    };

    useEffect( () => {
        document.addEventListener( 'keydown', handleKeyPress );

        return ( () => {
            document.removeEventListener( 'keydown', handleKeyPress );
        } )
    } );
}

