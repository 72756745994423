import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useTranslation} from "react-i18next";


export default function Marvin( props ) {
    const {t} = useTranslation();

    const bubbleDelayed = useBubbleDelay([props.marvin]);

    return (
        <div className={"theory-container"}>
            <div className={"marvin h--100"}>
                <div className="w--100 h--100 p--rel d--flex jc--c sm:o--1 sm:theory__table-center">
                    <div className="marvin-container sm:w--100">
                        <img src={props.marvin_image} className={"marvin__img" + (props.person_small ? " s" : "")} alt=""/>
                        {
                            bubbleDelayed && (
                                <div className="text-bubble-theory left">
                                    <div className="bubble__inner">
                                        <p dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_marvin`)}}/>
                                        {/*<p dangerouslySetInnerHTML={{__html: props.marvin}}/>*/}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}