import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import LinkToLogin from "../LinkToLogin";

export default function TeacherSuccess({changeState}) {
    const {t} = useTranslation();
    return (
        <div className={"wrapper__content m--0a d--flex ai--fs fd--c"}>
            <h1 className={"heading--m mb--3"}>{t('auth_reset_success')}</h1>

            <h2 className={'mb--2'}>{t('auth_reset_process_heading')}</h2>
            <p> 1. {t('auth_teacher_code')}<br/>
                2. {t('auth_insert_code')} <button className={'btn btn--text danger'}
                                                  onClick={() => changeState('team_secret_form')}
                ><strong>{t('auth_insert_here')}</strong></button>
            </p>

            <p>{t('auth_able_create_password')}</p>
            <div className={'mt--2'}>
                <LinkToLogin/>
            </div>

            <img src={"/img/bg-app.png"} className={"dashboard__avatar"}/>


            <div className={"disclaimer d--flex"}>

                <div className="disclaimer__wrapper m--a d--flex jc--fs">

                    <div className="disclaimer__copyright d--flex as--c sm:text--center">
                        <p>{t('interreg_copyright')}</p>
                    </div>

                    <div className="disclaimer__logo mr--3 sm:mr--0 sm:text--center">
                        <img src={"/img/innoschool-logo.png"}/>
                    </div>

                    <div className="disclaimer__text d--flex as--c sm:text--center">
                        <p>{t('interreg_disclaimer')}</p>
                    </div>

                </div>

            </div>
        </div>
    )
}