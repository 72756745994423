import React, {useState, useEffect} from "react";
import Marketplace from "./Marketplace/Marketplace";
import * as axios from "axios";
import {apiURL} from "../../../env";
import {creditSet} from "../../../Store/actions/creditActions";
import {connect} from "react-redux";
import store from "../../../index";
import {flashOpen} from "../../../Store/actions/flashActions";
import {useTranslation} from "react-i18next";
import {
    authTeamBadgeClose,
    authTeamBadgeOpen,
    authTeamDiplomaClose,
    authTeamDiplomaOpen
} from "../../../Store/actions/authActions";

import {gameModuleClose} from "../../../Store/actions/gameActions";
import Badge from "../Game/Badge";
import Diploma from "../Game/Diploma";

export default function CanvasModal(props) {

    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);


    const handleCanvasDownload = () => {
        axios
            .request({
                method: "get",
                url: apiURL + '/office/canvas',
                responseType: 'blob',
            })

            .then(({data}) => {
                console.log('data');
                console.log(data);
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link        = document.createElement('a');
                link.href         = downloadUrl;
                link.setAttribute('download', 'canvas.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }

    const handleFileDownload = (fileName, task) => {
        axios
            .request({
                method: "post",
                url: apiURL + '/office/canvas/files/'+ fileName + '/task/' + task,
                responseType: 'blob',
            })

            .then(({data}) => {
                console.log('data');
                console.log(data);
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link        = document.createElement('a');
                link.href         = downloadUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }

    useEffect(() => {
        setLoading(true);
        axios
            .get(apiURL + '/office/canvas/files')
            .then(({data}) => {
                console.log('files');
                console.log(data);
                setFiles(data);
                setLoading(false);
            });
    }, []);

    const fileList = files.length > 0 ?
                files.map((file) => {
                    return <li onClick={() => handleFileDownload(file.name, file.task)}><span class={'mdi mdi-file mr--1'}></span>{file.name}</li>;
                }) : null;

    return (

        <>
            <div className="modal-wrapper">
                <div className="modal modal--m">

                    <button
                        className={"modal__close d--flex dark"}
                        onClick={() => props.handleClose()}
                    >
                        <span className={"mdi mdi-close m--a"}> </span>
                    </button>

                    <div className={"modal__body"}>
                        <div className="modal__inner d--flex">
                            <div className="w--100">
                                <h2 className={"text--center mb--3"}>{t('sbmc_name')}</h2>

                                <ul className={'text--center list-canvas'}>
                                    <li onClick={() => handleCanvasDownload()}><span className={"mdi mdi-file-multiple mr--1"}></span> Canvas</li>
                                    {
                                        loading ? (<li>
                                            Loading...
                                        </li>) : fileList
                                    }
                                </ul>



                                <div className="d--flex jc--fs jc--c">

                                    <button
                                        className={"mt--3 mr--2 btn btn--m btn--danger btn--shadow d--block"}
                                        onClick={() => props.handleClose()}
                                    >
                                        {t('close')}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="backdrop info"></div>
        </>
    );
}