import React, { useState, useEffect } from 'react';
import {audioPlayBubbleSound} from "../utils/audio";

export function useBubbleStepSound(step) {
    useEffect( () => {
        if(step !== 0){
            audioPlayBubbleSound();
        }

    }, [step]);
}

