import i18n from "i18next";
import XHR from "i18next-xhr-backend";

import translationEn from './locales/en.json';
import translationSk from './locales/sk.json';
import translationCz from './locales/cz.json';
import translationHu from './locales/hu.json';
import translationAt from './locales/at.json';
import translationBg from './locales/bg.json';
import translationRo from './locales/ro.json';
import translationRs from './locales/rs.json';
import translationBa from './locales/ba.json';
import {initReactI18next} from "react-i18next";

const localeResources = {
    en: {
        translations: translationEn
    },
    sk: {
        translations: translationSk
    },
    cz: {
        translations: translationCz
    },
    at: {
        translations: translationAt
    }
    ,
    hu: {
        translations: translationHu
    },
    bg: {
        translations: translationBg
    }
   ,
    ro: {
        translations: translationRo
    },

    rs: {
        translations: translationRs
    },

    ba: {
        translations: translationBa
    }
};

export const localeList = Object.keys( localeResources );


i18n
    .use( initReactI18next ) // bind react-i18next to the instance
    .init( {
        // we init with resources
        lng: 'en',

        resources:     localeResources,
        fallbackLng:   "en",
        supportedLngs: Object.keys( localeResources ),
        debug:         false,
        preload:       true,

        // have a common namespace used around the full app
        ns:        ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue:     false, // not needed for react!!
            formatSeparator: ","
        },

        react: {
            wait: true
        }
    } );

export default i18n;