import React from 'react';
import {useBubbleDelay} from "../../../../../Hooks/useBubbleDelay";
import {useBubbleStepSound} from "../../../../../Hooks/useBubbleStepSound";
import {useTranslation} from "react-i18next";

export default function Swot(props) {
    const {t} = useTranslation();

    useBubbleStepSound(props.step);

    return (
        <div className={"theory-container"}>
            <div className={"color--bg-full__white"}></div>

            <div className="wrapper__content h--100">
                <div className={"swot h--100"}>

                    <div className="swot__left">
                        <div className={props.color}>



                            <div className="swot__inner as--c">
                                <h2>{t(`theory_${props.module}_slide_${props.slide}_letter`)}</h2>
                                <p>{t(`theory_${props.module}_slide_${props.slide}_word`)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="swot__right">
                        <ul className={"as--c"} dangerouslySetInnerHTML={{__html: t(`theory_${props.module}_slide_${props.slide}_list`)}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}