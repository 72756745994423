import React, {useState} from 'react';
import Form from "../../Form/Form";
import {useTranslation} from "react-i18next";
import {apiURL} from "../../../env";
import LoginLink from "./TeamCodeForm";

export default function TeamSecretForm({changeState, changeToken}) {
    const {t, i18n} = useTranslation();

    return (
        <div className={"wrapper__login m--0a d--flex jc--c"}>
            <div className="wrapper__login-bg"></div>
            <div className="w--40 sm:w--100 as--c">
                <h1 className={"heading--m text--center mb--3"}>{t('auth_reset_password_heading')}</h1>
                <div className={"login-container"}>
                    <Form
                        locale={i18n.language}
                        submitLabel={t('auth_reset_password_btn')}
                        submitMethod={"post"}
                        submitUrl={apiURL + '/password-reset/secret'}
                        handleSubmitSuccess={(data, response) => {
                            if (response.data.token) {
                                changeToken(response.data.token);
                                changeState('password_form');
                            } else {
                                changeState('token_expired')
                            }
                        }}
                        handleSubmitError={(response) => {
                            // setResetSuccess( false );
                        }}
                        inputs={[
                            <input className={"input__item mb--1 w--100"}
                                   placeholder={t('auth_team_code')} name="code" type="text"/>,
                            <input className={"input__item mb--1 w--100"}
                                   placeholder={t('secret_code')} name="secret" type="text"/>,
                        ]}
                    />

                </div>
            </div>
        </div>
    )
}