import React, {useRef, useState} from 'react';
import {
    authLogout,
    authTeamBadgeOpen,
    authTeamDiplomaOpen, authTeamModuleUnlockedOpen,
    authTeamOpenTaskEvaluationClose, authTeamOpenTaskEvaluationOpen
} from "../Store/actions/authActions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import useOutsideClick from "../Hooks/useOutsideClick";

import Badge from "./Notifications/Badge";
import Diploma from "./Notifications/Diploma";
import OpenTaskEvaluation from "./Notifications/OpenTaskEvaluation";
import QuizFinished from "./Notifications/QuizFinished";
import UnlockedOfficeItem from "./Notifications/UnlockedOfficeItem";
import ModuleUnlockedNotification from "./Notifications/ModuleUnlockedNotification";

function NotificationItem( {notification} ) {
    if ( notification.notifiable_type === 'App\\Models\\User\\Badge' ) {
        return <Badge time={notification.created_at} badge={notification.notifiable}/>
    } else if ( notification.notifiable_type === 'App\\Models\\User\\Diploma' ) {
        return <Diploma time={notification.created_at} diploma={notification.notifiable}/>
    } else if ( notification.notifiable_type === 'App\\Models\\OpenTask\\OpenTaskEvaluation' ) {
        return <OpenTaskEvaluation time={notification.created_at} openTaskEvaluation={notification.notifiable}/>
    } else if ( notification.notifiable_type === 'App\\Models\\User\\ModuleUnlockedNotification' ){
        return <ModuleUnlockedNotification time={notification.created_at} openTaskEvaluation={notification.notifiable}/>
    }

    // else if ( notification.type === 'QuizFinished' ) {
    //     return <QuizFinished time={notification.created_at} quizFinished={notification.quizFinished}/>
    // } else if ( notification.type === 'UnlockOfficeItem' ) {
    //     return <UnlockedOfficeItem time={notification.created_at} officeItem={notification.officeItem}/>
    // }
        }

function NotificationsDropdown( props ) {
    const {t} = useTranslation();
    const dropdownRef = useRef( null );
    const [active, setActive] = useState( false );

    useOutsideClick( dropdownRef, () => {
        setActive( false );
    } )

    return (

        <div ref={dropdownRef} className={"menu__item d--flex dropdown " + ( active ? 'active' : 'inactive' )}>


            <a
                href="#"
                className={"d--flex ai--c"}
                onClick={( e ) => {
                    e.preventDefault();
                    setActive( !active );
                }}
            >
                <img src="img/envelope.svg" alt=""/>
            </a>

            <div className="dropdown-container notifications">
                {
                    props.notifications.map( ( notification, i ) => (
                        <div
                            key={i}
                            className="dropdown__item"
                            onClick={() => {
                                console.log( notification );

                                if ( notification.notifiable_type === 'App\\Models\\User\\Badge' ) {
                                    props.dispatch( authTeamBadgeOpen( Object.assign( notification.notifiable, {close: true} ) ) );
                                } else if ( notification.notifiable_type === 'App\\Models\\User\\Diploma' ) {
                                    props.dispatch( authTeamDiplomaOpen( Object.assign( notification.notifiable, {close: true} ) ) );
                                } else if ( notification.notifiable_type === 'App\\Models\\OpenTask\\OpenTaskEvaluation' ) {
                                    props.dispatch( authTeamOpenTaskEvaluationOpen( Object.assign( notification.notifiable, {close: true} ) ) );
                                } else if ( notification.notifiable_type === 'App\\Models\\User\\ModuleUnlockedNotification' ){
                                    props.dispatch( authTeamModuleUnlockedOpen( Object.assign( notification.notifiable, {close: true} ) ) );
                                }
                            }}
                        >
                            <NotificationItem notification={notification}/>
                        </div>
                    ) )
                }
            </div>
        </div>
    )
}

function mapStateToProps( state ) {
    const {notifications} = state;

    return {
        notifications
    }
}

export default connect(
    mapStateToProps
)( NotificationsDropdown );