import React from 'react';
import {apiURL} from "../../../env";
import {Link} from "react-router-dom";
import Form from "../../Form/Form";
import {useTranslation} from "react-i18next";
import LinkToLogin from "../LinkToLogin";

const FirstPasswordTeamCode = (props) => {
    const {t, i18n} = useTranslation();

    return (
        <>
            <Form
                locale={i18n.language}
                submitLabel={t( 'auth_verify_code' )}
                submitMethod={"post"}
                submitUrl={apiURL + '/set-password/team'}
                handleSubmitSuccess={(data, response) => {
                    props.handleSuccess(data.code, parseInt(response.data.password) === 1);
                }}
                handleSubmitError={(response) => {
                    console.error(response);
                }}
                inputs={[
                    <input className={"input__item mb--1 w--100"}
                           placeholder={t( 'code' )} name="code" type="text"/>
                ]}
            />
            <div className="d--flex jc--c mt--3">

                <LinkToLogin/>

            </div>
        </>
    );
};

export default FirstPasswordTeamCode;
