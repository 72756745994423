import {
    FLASH_CREATE, FLASH_DESTROY
} from "../actions/flashActions";

export const FlashReducer = ( state = [], action ) => {
    switch ( action.type ) {
        case FLASH_CREATE:
            return state.concat( action.flash );

        case FLASH_DESTROY:
            if ( action.id !== undefined )
                return state.filter( ( flash ) => flash.id !== action.flash );
            else
                return state.filter( ( f, i ) => i !== 0 );

        default:
            return state;
    }
};