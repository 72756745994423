import {detectIE} from "./browsers";
import store from "../index";
let bubbleSound;

export const audioLoadBubbleSound = () => {
    const ie_browser = detectIE();

    if(ie_browser === false || ie_browser >= 12){
        bubbleSound = new Audio( '/bubble_sound.wav' );
    }
}

export const audioPlayBubbleSound = () => {
    const ie_browser = detectIE();

    if(store.getState().sound && ie_browser === false || ie_browser >= 12){
        bubbleSound.currentTime = 0;
        bubbleSound.play();
    }
}