import React, {useState} from 'react';

export default function CaseStudiesSources( {sources} ) {
    if ( !sources || sources.length === 0 ) return null;

    return (
        <ul className={"theory__sources"}>
            {
                sources.map( ( source ) => (
                    <li><a href={source.href} target={"_blank"}>{source.label}</a></li>
                ) )
            }
        </ul>
    );
}
