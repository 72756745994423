export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_SCENARIO = 'AUTH_SCENARIO';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_TEAM_LOADED = 'AUTH_TEAM_LOADED';
export const AUTH_TEAM_PROFILE_LOADED = 'AUTH_TEAM_PROFILE_LOADED';
export const AUTH_TEAM_BADGE_OPEN = 'AUTH_TEAM_BADGE_OPEN';
export const AUTH_TEAM_BADGE_CLOSE = 'AUTH_TEAM_BADGE_CLOSE';
export const AUTH_TEAM_DIPLOMA_OPEN = 'AUTH_TEAM_DIPLOMA_OPEN';
export const AUTH_TEAM_DIPLOMA_CLOSE = 'AUTH_TEAM_DIPLOMA_CLOSE';
export const AUTH_TEAM_MODULE_UNLOCKED_OPEN = 'AUTH_TEAM_MODULE_UNLOCKED_OPEN';
export const AUTH_TEAM_MODULE_UNLOCKED_CLOSE = 'AUTH_TEAM_MODULE_UNLOCKED_CLOSE';
export const AUTH_TEAM_OPEN_TASK_EVALUATION_OPEN = 'AUTH_TEAM_OPEN_TASK_EVALUATION_OPEN';
export const AUTH_TEAM_OPEN_TASK_EVALUATION_CLOSE = 'AUTH_TEAM_OPEN_TASK_EVALUATION_CLOSE';
export const AUTH_TEAM_CANVAS_AND_FILES_OPEN = 'AUTH_TEAM_CANVAS_AND_FILES_OPEN';
export const AUTH_TEAM_CANVAS_AND_FILES_CLOSE = 'AUTH_TEAM_CANVAS_AND_FILES_CLOSE';
export const AUTH_TEAM_OPEN_TASK_STORY_OPEN = 'AUTH_TEAM_OPEN_TASK_STORY_OPEN';
export const AUTH_TEAM_OPEN_TASK_STORY_CLOSE = 'AUTH_TEAM_OPEN_TASK_STORY_CLOSE';

export const authLogin = ( token, provider, team, student ) => ( {
    type: AUTH_LOGIN,
    token,
    provider,
    team,
    student
} );

export const authScenario = ( scenario ) => ( {
    type: AUTH_SCENARIO,
    scenario
} );

export const authLogout = () => ( {
    type: AUTH_LOGOUT
} );

export const authTeamLoaded = ( team, student ) => ( {
    type: AUTH_TEAM_LOADED,
    team,
    student
} );

export const authTeamProfilLoaded = (profile) => ({
    type: AUTH_TEAM_PROFILE_LOADED,
    profile
})

export const authTeamBadgeOpen = ( badge ) => ( {
    type: AUTH_TEAM_BADGE_OPEN,
    badge
} );

export const authTeamBadgeClose = ( badge ) => ( {
    type: AUTH_TEAM_BADGE_CLOSE,
    badge
} );


export const authTeamDiplomaOpen = ( diploma ) => ( {
    type: AUTH_TEAM_DIPLOMA_OPEN,
    diploma
} );

export const authTeamDiplomaClose = ( diploma ) => ( {
    type: AUTH_TEAM_DIPLOMA_CLOSE,
    diploma
} );

export const authTeamModuleUnlockedOpen = ( module_unlocked_notification ) => ( {
    type: AUTH_TEAM_MODULE_UNLOCKED_OPEN,
    module_unlocked_notification
} );

export const authTeamModuleUnlockedClose = ( module_unlocked_notification ) => ( {
    type: AUTH_TEAM_MODULE_UNLOCKED_CLOSE,
    module_unlocked_notification
} );

export const authTeamOpenTaskEvaluationOpen = ( openTaskEvaluation ) => ( {
    type: AUTH_TEAM_OPEN_TASK_EVALUATION_OPEN,
    openTaskEvaluation
} );

export const authTeamOpenTaskEvaluationClose = ( openTaskEvaluation ) => ( {
    type: AUTH_TEAM_OPEN_TASK_EVALUATION_CLOSE,
    openTaskEvaluation
} );

export const authTeamCanvasAndFilesOpen = (  ) => ( {
    type: AUTH_TEAM_CANVAS_AND_FILES_OPEN,
} );

export const authTeamCanvasAndFilesClose = (  ) => ( {
    type: AUTH_TEAM_CANVAS_AND_FILES_CLOSE,
} );

export const authTeamOpenTaskStoryOpen = ( openTask, level, index ) => ( {
    type: AUTH_TEAM_OPEN_TASK_STORY_OPEN,
    openTask,
    level,
    index
} );

export const authTeamOpenTaskStoryClose = ( openTask ) => ( {
    type: AUTH_TEAM_OPEN_TASK_STORY_CLOSE,
    openTask
} );