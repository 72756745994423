import Lamp from "./CategoryIcons/Lamp";
import Furniture from "./CategoryIcons/Furniture";
import Chair from "./CategoryIcons/Chair";
import Web from "./CategoryIcons/Web";
import Frame from "./CategoryIcons/Frame";
import Plant from "./CategoryIcons/Plant";
import Fun from "./CategoryIcons/Fun";
import React from "react";

export const categories = [
    {
        id: 'lamp',
        image: <Lamp/>
    },
    {
        id: 'table',
        image: <Furniture/>,
    },
    {
        id: 'chair',
        image: <Chair/>,
    },
    {
        id: 'computer',
        image: <Web/>
    },
    {
        id: 'wall',
        image: <Frame/>
    },
    {
        id: 'flower',
        image: <Plant/>
    },
    {
        id: 'fun',
        image: <Fun/>
    }
];