import {UNSEEN_MESSAGES_COUNT_LOADED} from "../actions/chatActions";

const initialState = {
    unseen: null
};

export const ChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNSEEN_MESSAGES_COUNT_LOADED:
            return Object.assign({}, state, {
                unseen: action.unseenMessagesCount
            });
        default:
            return state;
    }
};