import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LinkToLogin from "../LinkToLogin";

const FirstPasswordSuccess = () => {

    const {t} = useTranslation();

    return (
        <>

            <div className={"alert alert--success"}>{t( 'auth_password_set' )}</div>
            <div className="d--flex jc--c mt--3">
                <LinkToLogin/>
            </div>
        </>
    );
};

export default FirstPasswordSuccess;
