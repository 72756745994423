export const FLASH_CREATE = 'FLASH_CREATE';
export const FLASH_DESTROY = 'FLASH_DESTROY';

export const flashCreate = ( flash ) => ( {
    type:  FLASH_CREATE,
    flash: Object.assign( {}, flash, {id: Math.random().toString( 36 ).substring( 2 ) + Date.now().toString( 36 )} )
} )

export const flashDestroy = ( id ) => ( {
    type: FLASH_DESTROY,
    id
} );

export const flashOpen = ( flash, delay) => {
    if(delay === undefined) delay = 2500;
    // Thunk middleware knows how to handle functions.
    // It passes the dispatch method as an argument to the function,
    // thus making it able to dispatch actions itself.

    return function ( dispatch ) {
        dispatch( flashCreate( flash ) )

        return setTimeout(() => {
            dispatch( flashDestroy( ) )
        }, 5000);
    }
}