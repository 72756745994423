import React from 'react';
import General from "./General";
import Password from "./Password";
import Email from "./Email";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

function Settings(props){

    const {t} = useTranslation();
    //TODO - what about team email?
    return (
        <div className={"wrapper__content m--0a d--flex fd--c"}>
            <h1 className={"mt--3 heading--m mb--3"}>{t( 'settings_heading' )}</h1>

            <div className="card-container d--flex fw--w">
                <General/>
                <Password/>
                {props.auth.student ? <Email/> : null}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {auth} = state;

    return {auth}
}

export default connect(
    mapStateToProps
)(Settings);