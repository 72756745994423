import React, {useEffect} from 'react';

import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {apiURL} from "../../env";
import Form from "../Form/Form";
import {authLogin, authLogout, authTeamLoaded} from "../../Store/actions/authActions";
import {useTranslation} from "react-i18next";
import * as axios from "axios";
import {badgesCountSet, creditSet, progressSet, supportersSet} from "../../Store/actions/creditActions";
import Loader from "../Screens/Game/Tasks/Loader";
import {gameLogout} from "../../Store/actions/gameActions";


function TeamLoading( props ) {
    const {t, i18n} = useTranslation();

    useEffect( () => {
        axios( {
            method: "get",
            url:    apiURL + '/team',
        } )
            .then( ( response ) => {
                // props.dispatch(gameModulesLoad(response.data.team.module));
                const newLocale = response.data.team.locale === 'gb' ? 'en' : response.data.team.locale;

                i18n.changeLanguage( newLocale );
                document.querySelector( 'html' ).lang = newLocale;

                props.dispatch( authTeamLoaded( response.data.team, response.data.student ) );
                props.dispatch( creditSet( response.data.team.coins ) );
                props.dispatch( supportersSet( response.data.team.supporters ) );
                props.dispatch( badgesCountSet( response.data.team.badges_count ) );
                props.dispatch( progressSet( response.data.team.progress ) );
                // props.dispatch(notificationsLoaded(response.data.team.notifications));

            } )
            .catch( ( error ) => {
                if ( error.response ) {
                    if ( error.response.status == 401 ) {
                        localStorage.removeItem( 'access_token' );
                        props.dispatch( authLogout() );
                        props.dispatch( gameLogout() );
                    }
                }
            } );
    }, [] )


    return (
        <Loader/>
    )

}

function mapStateToProps( state ) {
    const {auth} = state;

    return {
        auth
    }
}

export default connect(
    mapStateToProps
)( TeamLoading );