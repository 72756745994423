import React from 'react';

export default function icon ( props ) {
    return (
        <svg enable-background="new 0 0 512 512" height="512px" id="Paint_x5F_roller" version="1.1" viewBox="0 0 512 512"
             width="512px" xmlns="http://www.w3.org/2000/svg">
            <g id="Paint_x5F_roller_1_">
                <g>
                    <path d="M423.951,203.519V80.546h-20.342V63.34c0-14.05-11.426-25.48-25.471-25.48H113.53c-14.05,0-25.481,11.431-25.481,25.48    v5.616v27.955v12.845c0,14.05,11.431,25.48,25.481,25.48h38.815c2.222,10.533,11.586,18.464,22.771,18.464    c11.185,0,20.549-7.931,22.771-18.464h180.251c14.045,0,25.471-11.431,25.471-25.48V92.546h8.342v100.919l-163.215,29.05v35.246    h-28.104v182.275c0,18.805,15.299,34.104,34.104,34.104s34.104-15.299,34.104-34.104V257.762h-28.104v-25.193L423.951,203.519z     M391.609,84.9l-38.336,38.336h-39.17l45.898-45.898l-8.484-8.485l-54.385,54.384h-39.291l6.443-6.443l-8.486-8.485    l-14.928,14.928h-38.806l73.378-73.377h38.805l-49.949,49.95l8.484,8.485l58.436-58.436h39.291l-9.75,9.75l8.485,8.485    l16.116-16.115c3.75,2.396,6.248,6.587,6.248,11.36V84.9z M198.39,71.394l21.528-21.534h38.555l-60.083,60.082V71.394z     M198.39,54.42v-4.561h4.56L198.39,54.42z M100.175,111.494c0.882,0.165,1.789,0.261,2.718,0.261    c8.185,0,14.843-6.659,14.843-14.844v-2.268c0-2.426,1.974-4.4,4.4-4.4s4.4,1.974,4.4,4.4v9.53    c0,10.285,8.368,18.653,18.653,18.653c0.801,0,1.566,0.149,2.277,0.41H113.53C106.687,123.236,101.032,118.107,100.175,111.494z     M175.116,141.701c-6.216,0-11.273-5.057-11.273-11.274v-0.947c0-10.286-8.368-18.653-18.653-18.653    c-3.669,0-6.653-2.984-6.653-6.653v-9.53c0-9.043-7.357-16.4-16.4-16.4s-16.4,7.357-16.4,16.4v2.268    c0,1.568-1.275,2.844-2.843,2.844c-1.568,0-2.844-1.275-2.844-2.844V68.956V63.34c0-7.434,6.048-13.48,13.481-13.48h72.86v80.567    C186.39,136.644,181.333,141.701,175.116,141.701z M378.139,123.236h-7.894l21.364-21.365v7.884    C391.609,117.189,385.566,123.236,378.139,123.236z M254.736,462.141c-12.188,0-22.104-9.915-22.104-22.104V288.079h44.207    v151.958C276.84,452.226,266.924,462.141,254.736,462.141z M276.84,276.079h-44.207v-6.317h44.207V276.079z"/>
                    <rect height="15.152" width="12" x="248.736" y="300.039"/>
                    <rect height="114.121" width="12" x="248.736" y="325.135"/>
                </g>
            </g>
        </svg>

    )
}
